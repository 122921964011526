import React, { useState, useCallback, useEffect, useRef } from "react";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Controls,
  MiniMap,
  Background,
  MarkerType,
  Panel,
} from "reactflow";
import { useStore } from "reactflow";
import "reactflow/dist/style.css";
import "./Css/Dashboard.css";
import Sidebar from "./Sidebar";
import DragDropFile from "./Nodes/DragDropFile";
import SidebarEditNodes from "./SidebarEditNodes";
import TextUpdaterNode from "./Nodes/TextUpdaterNode";
import GroupNode from "./Nodes/GroupNode";
import CardNode from "./Nodes/CardNode";
import CarouselNode from "./Nodes/CarouselNode";
import ButtonNode from "./Nodes/ButtonNode";
import StartNode from "./Nodes/StartNode";
import ConditionalNode from "./Nodes/ConditionalNode";
import { AudioNode } from "./Nodes/AudioNode";
import { getStorageAccessToken } from "./LocalStorage/mainDb";
import { useNavigate } from "react-router-dom";
import useNetworkingHook from "./hooks/useNetworkingHook";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  Checkbox,
  FormLabel,
  Input,
  Text,
  position,
  useToast,
} from "@chakra-ui/react";
import Select from "react-dropdown-select";
import { NodeBar } from "./NodeBar";
import NavBar from "./NavBar";
import "./Css/Flow.css";
import { useDispatch, useSelector } from "react-redux";
import { setTopicId } from "./redux/slice";
import axios from "axios";

const flowKey = "example-flow";
const getNodeId = () => `randomnode_${+new Date()}`;
const initialNodes = [];

const initialEdges = [
  {
    id: "e1-2",
    source: "1",
    target: "2",
  },
];
const nodeTypes = {
  start: StartNode,
  text: TextUpdaterNode,
  image: DragDropFile,
  group: GroupNode,
  card: CardNode,
  carousel: CarouselNode,
  conditional: ConditionalNode,
  button: ButtonNode,
  audio: AudioNode,
};
const nodeSize = {
  height: 63,
  width: 150,
};

const Flow = () => {
  const dispatch = useDispatch();
  const { topicId } = useSelector((state) => state.slice);
  const reactFlowWrapper = useRef(null);
  const proOptions = { hideAttribution: true };
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [rfInstance, setRfInstance] = useState(null);
  const { setViewport } = useReactFlow();
  const zoom = useStore((s) => s.transform[2]);
  const [initialViewSet, setInitialViewSet] = useState(false);
  const [ruleModal, setRuleModal] = useState(false);
  const [ruleMethodError, setRuleMethorError] = useState(false);
  const [connectionData, setConnectionData] = useState({});
  const [selectedRuleMethod, setSelectedRuleMethod] = useState({});
  const [selectedRuleMethodGroupId, setSelectedRuleMethodGroupId] = useState(
    {}
  );
  const [backendPath, setBackendPath] = useState("");
  const fileInputRef = useRef(null);
  const toast = useToast();
  const [ruleActionGroupById, setRuleActionGroupById] = useState([]);
  const [filteredRuleActionArguments, setFilteredRuleActionArguments] =
    useState([]);
  const [inputValues, setInputValues] = useState({});

  const navigate = useNavigate();
  const {
    rulesActionGroupList,
    updateNode,
    addBlock,
    updateBlock,
    addNode: addBackendNode,
    addPath,
    findPath,
    findConditionalPath,
    updatePath,
    deletePath,
    deleteConditionalPath,
    deleteNode: deleteBackEndNode,
    deleteBlock,
    getNodeTypeList,
    getBrainRulesActionGroup,
    fetchIntents,
    getAgents,
    getBrainProtocol,
    conditionalPathAdd,
    getPathTopics,
    getBlockTopics,
    getConditionalPathTopics,
    getNodeTopics,
    getActionsList,
    parseLogicEquation,
    nodeImport,
    agents,
    intents,
    blockTopicsList,
    nodeTopicsList,
    pathTopicsList,
    conditionalPathTopicsList,
    actionList,
  } = useNetworkingHook();

  useEffect(() => {
    if (ruleModal) return;
    setSelectedRuleMethod({});
    setConnectionData({});
    setSelectedRuleMethodGroupId({});
    setRuleActionGroupById([]);
    setFilteredRuleActionArguments([]);
    setInputValues({});
  }, [ruleModal]);

  useEffect(() => {
    if (getStorageAccessToken() == "") {
      navigate("/login");
      return;
    }

    // getPrevData();
    getBrainRulesActionGroup();
    fetchIntents();
    getAgents();
    getBrainProtocol();
  }, []);

  useEffect(() => {
    if (!selectedRuleMethod?.id) return;
    getRuleActionGroupById();
  }, [selectedRuleMethod]);

  useEffect(() => {
    if (!selectedRuleMethodGroupId?.id) return;
    getRuleActionArguments();
  }, [selectedRuleMethodGroupId]);

  useEffect(() => {
    if (!topicId) return;
    setEdges([]);
    setNodes([]);
    setSelectedNode();
    getPathTopics();
    getBlockTopics();
    getConditionalPathTopics();
    getNodeTypeList();
  }, [topicId]);

  useEffect(() => {
    console.log("blockTopicsList", blockTopicsList.length);
    if (!blockTopicsList || blockTopicsList.length === 0) return;

    setNodes((prevNodes) => {
      const parentNodes = prevNodes.filter((node) => node.type === "group");
      const newNodes = [];

      blockTopicsList.forEach((block, index) => {
        if (!!nodes.find((nd) => nd.id === block.id)) return;

        const xPosition = index * 250;
        const newParent = {
          id: block.id.toString(),
          data: {
            label: block.name,
            context: block.context,
          },
          type: "group",
          position: {
            x: block.position.x ?? xPosition,
            y: block.position.y ?? 0,
          },
          style: {
            height: 165,
          },
        };

        newNodes.push(newParent);
      });

      return prevNodes.concat(newNodes);
    });

    getNodeTopics();
  }, [blockTopicsList]);

  useEffect(() => {
    if (!nodeTopicsList || nodeTopicsList.length === 0) return;

    console.log("nodeTopicsList", nodeTopicsList);

    nodeTopicsList.forEach(async (node) => {
      if (!!nodes.find((nd) => nd.id == node.id)) return;

      const type = node.nodeType.nodeTypeName.toLowerCase();
      const children = nodes.filter((nd) => nd.parentNode == node.block.id);
      var height = children.reduce((sum, n) => sum + (n?.height ?? 0), 100);

      if (type === "conditional") {
        let parent = nodes.find((nd) => nd.id == node.block.id.toString());
        parent.data.hasConditionalNode = true;
        setNodes((nds) => nds.map((nd) => (nd.id == parent.id ? parent : nd)));
      }

      const newNode = {
        id: node.id.toString(),
        data: {
          label: node.nodeName,
          value:
            type === "conditional"
              ? parseLogicEquation(node.nodeType.expression)
              : node.nodeValue,
          order_node: node.orderNode,
          intent: node.nodeType?.intent ?? null,
          audioName: node.nodeType?.audioName,
          instructions: node.nodeType?.instructions,
          url: node.nodeType?.url,
          type: node.nodeType?.type,
          isLastNode: node.nodeType?.lastNode,
        },
        type,
        parentNode: node.block.id.toString(),
        extent: "parent",
        position: {
          x: node.coordX ? parseInt(node.coordX) : 0,
          y: node.coordY ? parseInt(node.coordY) : height || 100,
        },
        style:
          type === "image" || type === "card" || type === "carousel"
            ? {}
            : nodeSize,
        ...nodeSize,
      };

      setNodes((nds) => nds.concat(newNode));
    });

    getActionsList();
  }, [nodeTopicsList]);

  useEffect(() => {
    actionList.forEach((action) => {
      let foundNode = nodes.find((nd) => nd.id == action.previousNode.id);
      if (!foundNode) return;
      foundNode.data = {
        ...foundNode.data,
        action: {
          id: action.id,
          action: action.actionType.type,
          target: action.node,
        },
      };
      setNodes((nds) =>
        nds.map((nd) => {
          if (nd.id == foundNode.id) return foundNode;
          return nd;
        })
      );
    });
  }, [actionList]);

  useEffect(() => {
    if (!pathTopicsList || pathTopicsList.length === 0) return;
    console.log("Path Canvas List:", pathTopicsList);
    pathTopicsList.map((path) => {
      const connectionData = {
        id: path.id.toString(),
        source: path.previousNode?.id.toString(),
        target: path.nextNode?.id.toString(),
      };
      onConnect(connectionData);
    });
  }, [pathTopicsList]);

  useEffect(() => {
    if (!conditionalPathTopicsList || conditionalPathTopicsList.length === 0)
      return;
    console.log("Conditional Path Canvas List:", conditionalPathTopicsList);
    conditionalPathTopicsList.map((path) => {
      console.log("pathhh", path);

      const connectionDataCondition = {
        id: path.id.toString(),
        source: path.previousNode?.id.toString(),
        target: path.nextNode?.id.toString() ?? path.nextBlock?.id.toString(),
      };
      onConnect(connectionDataCondition);
    });
  }, [conditionalPathTopicsList]);

  const getPrevData = () => {
    const flowString = localStorage.getItem(flowKey);
    const flow = JSON.parse(flowString);
    if (!flow || !flow.edges || !flow.nodes) return;
    setEdges(flow.edges);
    setNodes(flow.nodes);
    console.log(JSON.parse(flowString));
  };

  const [selectedNode, setSelectedNode] = React.useState();

  useEffect(() => {
    const reAlignedNodes = nodes.map((node) => {
      if (node.type !== "group") return node;

      const children = nodes.filter((nd) => nd.parentNode === node.id);
      if (children.length === 0) return node;

      children.sort((a, b) => b.width - a.width);

      if (node.width !== children[0].width + 2) {
        node.style.width = children[0].width + 2;
      }

      const newHeight = children.reduce((s, n) => s + n.height, 0);

      if (node.height !== newHeight + 20) {
        node.style.height = newHeight + 120;
      }

      if (
        node.width !== children[0].width + 20 ||
        node.height !== newHeight + 20
      ) {
        onNodesChange([
          {
            id: node.id,
            type: "dimensions",
            dimensions: {
              height: node.style.height,
              width: node.style.width,
            },
          },
        ]);
      }

      return node;
    });

    onSave();
  }, [nodes]);

  const handleDrawerClose = async (hasUpdate) => {
    if (selectedNode && hasUpdate) {
      if (selectedNode.type === "group") {
        for (const node of selectedNode.children || []) {
          await updateNode(nodes.find((nd) => nd.id === node.id));
        }
        await updateBlock(nodes.find((nd) => nd.id === selectedNode.id));
      } else {
        await updateNode(nodes.find((nd) => nd.id === selectedNode.id));
      }
      onNodesChange([{ id: selectedNode.id, selected: false, type: "select" }]);
    }
    setSelectedNode();
  };

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "smoothstep",
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
            },
            style: {
              strokeWidth: 2,
            },
          },
          eds
        )
      );
    },
    [setEdges]
  );

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localStorage.setItem(flowKey, JSON.stringify(flow));
    }
  }, [rfInstance]);

  const onLoad = useCallback(
    (rfInstance) => {
      setRfInstance(rfInstance);

      if (!initialViewSet) {
        rfInstance.fitView();
        setInitialViewSet(true);
      }
    },
    [initialViewSet]
  );

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(flowKey));

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
  }, [setNodes, setViewport]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = async (event) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const position = rfInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const existingParent = nodes.find(
      (node) =>
        node.position.x < position.x &&
        node.position.x + node.width > position.x &&
        node.position.y < position.y &&
        node.position.y + node.height > position.y
    );

    let parentNode;

    if (existingParent) {
      parentNode = existingParent;
    } else {
      const block = await addBlock(position);
      parentNode = {
        id: block.id,
        data: { label: block.name, context: block.context },
        type: "group",
        position,
        style: { height: 165 },
      };
      setNodes((nds) => nds.concat(parentNode));
    }

    addNode(type, parentNode.id);
  };

  async function addNode(type, parentId) {
    const newNode = await addBackendNode(type, parentId, nodes);

    setNodes((nds) => nds.concat(newNode));

    if (!!selectedNode) {
      setSelectedNode({
        ...selectedNode,
        children: nodes
          .concat(newNode)
          .filter((nd) => nd.parentNode == selectedNode.id),
      });
    }
  }

  async function deleteNode(id) {
    let nodesToDelete = nodes.filter((node) => node.id == id);
    if (nodesToDelete.length == 0) return;

    if (nodesToDelete[0].type == "group") {
      nodesToDelete.push(...nodes.filter((node) => node.parentNode == id));
    } else {
      const parent = nodes.find(
        (node) => nodesToDelete[0].parentNode == node.id
      );
      const children = nodes.filter((node) => node.parentNode == parent.id);

      if (children.length == 1) {
        nodesToDelete.push(parent);
      }
    }

    const changes = nodesToDelete.map((node) => ({
      id: node.id,
      type: "remove",
    }));

    // Use Promise.all to wait for all deleteBackEndNode promises to complete
    await Promise.all(
      nodesToDelete.map((node) => {
        if (node.type == "group") deleteBlock(node.id);
        else deleteBackEndNode(node.id);
      })
    );

    // Now that all nodes are deleted, you can proceed with other actions
    onNodesChange(changes);
    setSelectedNode();
  }

  const handleFileUpload = (files) => {
    const file = files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result;
        handleJSONContent(fileContent);
      };

      reader.readAsText(file);
    }
  };

  const handleJSONContent = (jsonContent) => {
    try {
      const jsonData = JSON.parse(jsonContent);
      const newNodes = createNodeFromJSON(jsonData);
      setNodes((prevNodes) => [...prevNodes, ...newNodes]);
    } catch (error) {
      console.error("Error parsing JSON content:", error);
    }
  };

  const createNodeFromJSON = (jsonData) => {
    let parentNode = jsonData;

    const childrenList = jsonData.children;

    delete parentNode.children;
    return [parentNode, ...childrenList];
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      nodeImport(file);
    }
  };
  async function getRuleActionGroupById() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/ruleAction/group/" + selectedRuleMethod?.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("getRuleActionGroupById", response.data);
        const parsetData = response.data.map((rule) => ({
          id: rule.id,
          name: rule.name,
        }));
        setRuleActionGroupById(parsetData);
        console.log("getRuleActionGroupById:", response.data);
        if (!!backendPath?.ruleAction) {
          setSelectedRuleMethodGroupId(
            parsetData.find((rule) => rule.id == backendPath.ruleAction.id)
          );
        }
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getRuleActionGroupById error",
          response.data,
          response.status
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
      }
    }
  }

  async function getRuleActionArguments() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/ruleActionArguments/ruleAction/" +
          selectedRuleMethodGroupId?.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const filteredData = response.data.filter((rule) => rule.userInput);

        setFilteredRuleActionArguments(filteredData);
        console.log("getRuleActionGroupById", filteredData);
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getRuleActionGroupById error",
          response.data,
          response.status
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
      }
    }
  }
  const handleCheckboxChange = (e, id) => {
    const { checked } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleInputChange = (e, ruleName) => {
    const { value } = e.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [ruleName]: value,
    }));
  };

  return (
    <div style={{ height: "100vh" }}>
      <div className="dndflow">
        <Sidebar
          nrOfBlocks={nodes?.filter((nd) => nd.type == "group").length}
        />
        {/* <ReactFlowProvider > */}
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <NavBar />
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={(props) => {
              console.log("onNodesChange", props);
              const selectedNodeId = props.find(
                (n) => n.type == "select" && n.selected
              );

              const dragNode = props.find(
                (n) => n.type == "position" && n.dragging
              );

              if (!!selectedNode && dragNode) {
                setSelectedNode();
              }

              if (!!selectedNodeId) {
                console.log("SELECTED NODE", selectedNodeId);
                let selected = nodes.find((nd) => nd.id == selectedNodeId.id);

                if (selected.type == "group") {
                  selected.children = nodes.filter(
                    (nd) => nd.parentNode == selectedNodeId.id
                  );
                }

                // Check if the selected node has children
                if (selected.children && selected.children.length > 0) {
                  setSelectedNode(selected);
                } else {
                  // If it doesn't have children, get its parent node
                  let parentNode = nodes.find(
                    (nd) => nd.id == selected.parentNode
                  );

                  // Check if the parent node has children
                  if (parentNode && parentNode.type == "group") {
                    parentNode.children = nodes.filter(
                      (nd) => nd.parentNode == parentNode.id
                    );
                    if (parentNode.children && parentNode.children.length > 0) {
                      // Sort the children based on Y-axis position
                      // parentNode.children =
                      parentNode.children
                        .sort((a, b) => a.position.y - b.position.y)
                        .map((node, index) => {
                          node.data.order_node = index + 1;
                        });

                      setSelectedNode(parentNode);
                    } else {
                      console.log("Selected node has no children or parent.");
                    }
                  }
                }
              }

              const selectedRemoveNode = props.find((n) => n.type === "remove");
              if (selectedRemoveNode) {
                const nodeIdToRemove = selectedRemoveNode.id;
                deleteNode(nodeIdToRemove);
                return;
              }

              const endDragNode = props.find(
                (n) => n.type == "position" && !n.dragging
              );
              const blockNode = nodes.find(
                (nd) => nd.id === selectedNodeId?.id
              );
              if (endDragNode && !selectedNode) {
                console.log("endDragNode", endDragNode);
                const repositionedNode = nodes.find(
                  (nd) => nd.id == endDragNode.id
                );
                if (repositionedNode.type != "group")
                  updateNode(repositionedNode);
                else updateBlock(repositionedNode);
              }

              onNodesChange(props);
            }}
            onEdgesChange={(props) => {
              console.log("onEdgesChange", props, edges);

              props.map(async (edgeChange) => {
                if (edgeChange.type == "select" && edgeChange.selected) {
                  const selectedEdge = edges.find(
                    (edge) => edge.id == edgeChange.id
                  );
                  console.log("selectedEdge", selectedEdge);
                  const isConditionalPath =
                    nodes.find((node) => node.id == selectedEdge.target)
                      ?.type == "group";
                  console.log("isConditionalPath", isConditionalPath);
                  const path = isConditionalPath
                    ? await findConditionalPath(
                        selectedEdge.source,
                        selectedEdge.target
                      )
                    : await findPath(selectedEdge.source, selectedEdge.target);
                  if (!path) return;
                  setBackendPath(path);
                  setSelectedRuleMethod({
                    ...path.ruleAction.ruleActionGroup,
                    groupName: path.ruleAction.name,
                  });
                  // setSelectedRuleMethodGroupId({
                  //   ...path.ruleAction,
                  //   name: path.ruleAction.ruleActionGroup?.groupName,
                  // });
                  const ruleActionArgs = JSON.parse(path.ruleActionArgs);
                  setInputValues((prevInputValues) => ({
                    ...prevInputValues,
                    ...ruleActionArgs,
                  }));
                  console.log("pathh", path);
                  console.log("backendPath", backendPath);
                  console.log(
                    "JSON.parse(path.ruleActionArgs)",
                    path.ruleAction.id,
                    JSON.parse(path.ruleActionArgs)
                  );
                  if (!isConditionalPath) setRuleModal(true);
                }

                if (edgeChange.type == "remove") {
                  const selectedEdge = edges.find(
                    (edge) => edge.id == edgeChange.id
                  );
                  console.log("selectedEdge", selectedEdge);
                  const isConditionalPath =
                    nodes.find((node) => node.id == selectedEdge.target)
                      ?.type == "group";

                  const path = isConditionalPath
                    ? await findConditionalPath(
                        selectedEdge.source,
                        selectedEdge.target
                      )
                    : await findPath(selectedEdge.source, selectedEdge.target);
                  if (!path) return;
                  if (isConditionalPath) {
                    deleteConditionalPath(path.id);
                  } else {
                    deletePath(path.id);
                  }
                }
              });

              onEdgesChange(props);
            }}
            proOptions={proOptions}
            onConnect={async (props) => {
              const { source, target } = props;
              console.log("onconnect Props", props);
              const targetNode = nodes.find((node) => node.id == target);
              const sourceNode = nodes.find((node) => node.id == source);
              if (sourceNode.type === "text" && targetNode.type === "text") {
                toast({
                  title: "Connection not allowed",
                  description: "Text nodes cannot be connected.",
                  status: "warning",
                  duration: 5000,
                  isClosable: true,
                });
                return;
              }
              if (targetNode.type == "group") {
                console.log(targetNode);
                await conditionalPathAdd(source, target);
                onConnect(props);
              } else {
                setConnectionData(props);
                setRuleModal(true);
              }
            }}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            onInit={onLoad}
            attributionPosition="top-right"
          >
            <Background />
            <Panel position="top-right">
              <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => handleFileUpload(e.target.files)}
                style={{ display: "none" }}
              />
              <Button
                size={"xs"}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                Upload JSON File
              </Button>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
              />
            </Panel>
            <Panel position="top-left">
              <NodeBar />
            </Panel>
            <Controls />
            <MiniMap pannable />
            <Background
              variant="dots"
              gap={12}
              size={1}
              color={{
                backgroundColor:
                  "radial-gradient(rgb(218, 226, 226) 1.42857px, rgb(244, 246, 246) 1.42857px)",
              }}
            />
          </ReactFlow>
        </div>
        <SidebarEditNodes
          selectedNode={selectedNode}
          setSelectedNode={(newNode) => {
            setNodes((nds) =>
              nds.map((node) => {
                if (node.id != newNode.id) return node;
                return newNode;
              })
            );
          }}
          handleDrawerClose={handleDrawerClose}
          addNewNode={addNode}
          deleteNode={deleteNode}
          agents={agents}
          intents={intents}
        />
        <Modal
          isOpen={ruleModal} // Combine both modals into one
          onClose={() => {
            setSelectedRuleMethod("");
            setSelectedRuleMethodGroupId("");
            setBackendPath("");
            setConnectionData({});
            setRuleModal(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{"RuleMethod and ProtocolNr"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Select
                placeholder="Select RuleMethod"
                options={rulesActionGroupList}
                labelField="groupName"
                valueField="id"
                values={!!selectedRuleMethod?.id ? [selectedRuleMethod] : []}
                onChange={(selectedOption) =>
                  setSelectedRuleMethod(selectedOption[0])
                }
                searchable={true}
              />
              {!!selectedRuleMethod ? (
                <Select
                  placeholder="Select RuleMethod Group"
                  options={ruleActionGroupById}
                  labelField="name"
                  valueField="id"
                  values={
                    !!selectedRuleMethodGroupId?.id
                      ? [selectedRuleMethodGroupId]
                      : []
                  }
                  onChange={(selectedOption) => {
                    setSelectedRuleMethodGroupId(selectedOption[0]);
                  }}
                  style={{ marginTop: "15px" }}
                  searchable={true}
                />
              ) : (
                ""
              )}
              {selectedRuleMethodGroupId &&
                filteredRuleActionArguments.map((rule) => (
                  <div key={rule.id}>
                    <FormLabel>{rule.name} </FormLabel>
                    {rule.type === "Boolean" ? (
                      <Checkbox
                        defaultChecked={inputValues[rule.name]} // If rule.id is present in inputValues, checkbox will be checked
                        onChange={(e) => handleCheckboxChange(e, rule.name)}
                      >
                        {rule.name}
                      </Checkbox>
                    ) : (
                      <Input
                        placeholder={`Enter ${rule.name}`}
                        value={inputValues[rule.name] || ""}
                        onChange={(e) => handleInputChange(e, rule.name)}
                      />
                    )}
                  </div>
                ))}
            </ModalBody>

            <ModalFooter>
              {!!backendPath && (
                <Button
                  colorScheme="red"
                  onClick={async () => {
                    console.log("backendPath", backendPath.id);
                    const pathIdToDelete = backendPath.id;
                    await deletePath(pathIdToDelete);

                    const sourceNodeId = backendPath.previousNode.id;
                    const targetNodeId = backendPath.nextNode.id;

                    console.log("sourceNodeId:", sourceNodeId);
                    console.log("targetNodeId:", targetNodeId);

                    // Log the contents of the edges array for debugging
                    console.log("edges:", edges);

                    // Find the edge to remove based on source and target nodes
                    const edgeToRemove = edges.find(
                      (edge) =>
                        edge.source == sourceNodeId &&
                        edge.target == targetNodeId
                    );

                    if (edgeToRemove) {
                      onEdgesChange([
                        {
                          id: edgeToRemove.id,
                          type: "remove",
                        },
                      ]);
                    } else {
                      console.log("Edge not found");
                    }
                    setRuleModal(false);
                  }}
                  style={{ margin: "0 10px" }}
                >
                  Delete
                </Button>
              )}
              <Button
                colorScheme="blue"
                mr={3}
                onClick={async () => {
                  const { source, target } = connectionData;
                  if (!!backendPath) {
                    // If in "Update" mode, update the path
                    await updatePath({
                      ...backendPath,
                      ruleMethod: selectedRuleMethod?.id,
                      ruleActionDTO: { id: selectedRuleMethodGroupId?.id },
                      ruleActionArgs: JSON.stringify(inputValues),
                    });
                  } else {
                    const isConditional =
                      nodes.find((node) => node.id == source)?.type ==
                      "conditional";
                    // If in "Add" mode, add a new path
                    await addPath(
                      source,
                      target,
                      selectedRuleMethod?.id,
                      isConditional,
                      { id: selectedRuleMethodGroupId?.id },
                      JSON.stringify(inputValues) // Assuming inputValues contain rule action arguments
                    );
                  }
                  setSelectedRuleMethod();
                  setConnectionData({});
                  setSelectedRuleMethodGroupId();
                  setRuleActionGroupById([]);
                  setFilteredRuleActionArguments([]);
                  setRuleModal(false);
                  onConnect(connectionData);
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <Flow />
  </ReactFlowProvider>
);

import React from "react";
import { Flex, Spacer, VStack, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { SettingsIcon } from "@chakra-ui/icons";
import ExternalVarIcon from "./images/CustomIcons/ExternalVarIcon";

const NavBar = () => {
  const navigate = useNavigate();

  const settingsPage = () => {
    navigate("/settings");
  };
  const externalVariable = () => {
    navigate("/external-facts");
  };
  return (
    <Flex p={4} bg="#fcfcfc" borderBottom={"1px solid #eee"}>
      <VStack spacing={4} align="left">
        <div style={{ display: "flex" }}>
          <Tooltip hasArrow label="Settings" aria-label="Settings">
            <SettingsIcon
              color="#62778ad9"
              onClick={settingsPage}
              mx="2px"
              cursor={"pointer"}
            />
          </Tooltip>
          <Tooltip
            hasArrow
            label="External Variable"
            aria-label="External Variable"
          >
            <ExternalVarIcon ml="15px" onClick={externalVariable} />
          </Tooltip>
        </div>
      </VStack>
      <Spacer />
      {/* Add any additional components or content on the right side */}
    </Flex>
  );
};

export default NavBar;

import React from "react";

export const GoToNode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17 16"
      width="16"
      height="16"
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M6.563 2.362C6.563.83 8.023-.265 9.475.056l.012.003 3.734.888a2.357 2.357 0 0 1 1.842 2.303v9.498c0 1.102-.76 2.062-1.842 2.304l-3.746.89c-1.452.324-2.912-.772-2.912-2.302V2.362zm2.583-.842c-.557-.12-1.083.314-1.083.842v11.277c0 .526.526.96 1.083.84l3.746-.89a.857.857 0 0 0 .671-.84V3.25a.857.857 0 0 0-.671-.84l-.013-.003-3.733-.888zM1.063 4.36c0-1.362 1.18-2.36 2.5-2.36h.75a.75.75 0 0 1 0 1.5h-.75c-.613 0-1 .442-1 .86v7.28c0 .418.387.86 1 .86h.75a.75.75 0 0 1 0 1.5h-.75c-1.32 0-2.5-.998-2.5-2.36V4.36zm9.5 2.834a.75.75 0 1 0-1.5 0v1.611a.75.75 0 0 0 1.5 0v-1.61z"
      ></path>
    </svg>
  );
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Editable,
  EditablePreview,
  EditableInput,
  useToast,
} from "@chakra-ui/react";
import TextUpdater from "../components/SidebarComponents/TextUpdater";
import ImageComponent from "../components/SidebarComponents/ImageComponent";
import CardComponent from "../components/SidebarComponents/CardComponent";
import ButtonComponent from "../components/SidebarComponents/ButtonComponent";
import ConditionalComponent from "../components/SidebarComponents/ConditionalComponent";
import CarouselComponent from "./SidebarComponents/CarouselComponent";
import StartComponent from "../components/SidebarComponents/StartComponent";
import AudioComponent from "./SidebarComponents/AudioComponent";

function SidebarEditNodes({
  selectedNode,
  setSelectedNode,
  handleDrawerClose,
  addNewNode,
  deleteNode,
  agents,
  intents,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hasUpdate, setHasUpdate] = useState(false);
  const [imageMap, setImageMap] = useState({});
  const [linkMap, setLinkMap] = useState({});
  const [articleLink, setArticleLink] = useState("");
  const [textMap, setTextMap] = useState({});
  const [audioName, setAudioName] = useState({});
  const [instructions, setInstructions] = useState({});
  const [url, setUrl] = useState({});
  const [time, setTime] = useState({});
  const [descMap, setDescMap] = useState({});
  const [triggerIntentMap, setTriggerIntentMap] = useState({});
  const [totalConditionMap, setTotalConditionMap] = useState({});
  const [factOriginalId, setFactOriginalId] = useState([]);
  const [label, setLabel] = useState("");
  const [action, setAction] = useState({});
  const [isLastNode, setIsLastNode] = useState(false);
  const toast = useToast();
  const firstField = useRef();
  const hasAddNewButton = useMemo(
    () =>
      (selectedNode?.type == "group" &&
        !!selectedNode.children?.find((node) => node.type == "button")) ||
      selectedNode?.type == "button",
    [selectedNode]
  );
  const hasAddNewCarousel = useMemo(
    () =>
      (selectedNode?.type == "group" &&
        !!selectedNode.children?.find((node) => node.type == "carousel")) ||
      selectedNode?.type == "carousel",
    [selectedNode]
  );
  const hasAddNewCondition = useMemo(
    () =>
      (selectedNode?.type == "group" &&
        !!selectedNode.children?.find((node) => node.type == "conditional")) ||
      selectedNode?.type == "conditional",
    [selectedNode]
  );

  useEffect(() => {
    if (!!selectedNode && !isOpen) onOpen();
    else if (!selectedNode && isOpen) closeDrawer();
    setDefaultValue(selectedNode);
    console.log("selectedNode", selectedNode);
  }, [selectedNode]);

  const setDefaultValue = (node) => {
    if (!node) return;
    if (node.type == "image") {
      if (typeof node.data.value == "string") {
        setLinkMap((prev) => {
          return {
            ...prev,
            [node.id]: node.data.value,
          };
        });
      } else
        setImageMap((prev) => {
          return {
            ...prev,
            [node.id]: node.data.value,
          };
        });
    }
    if (node.type == "text") {
      setTextMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.value,
        };
      });
      setIsLastNode(node.data.isLastNode);
    }
    if (node.type == "start") {
      setTriggerIntentMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.intent,
        };
      });
    }
    if (node.type == "card") {
      setLinkMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.imageLink,
        };
      });
      setDescMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.description,
        };
      });
      setArticleLink(node.data.articleLink);

      setTextMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.title,
        };
      });
    }
    if (node.type == "carousel") {
      setLinkMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.imageLink,
        };
      });
      setDescMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.description,
        };
      });
      setArticleLink(node.data.articleLink);

      setTextMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.title,
        };
      });
    }

    if (node.type == "button") {
      setTextMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.value,
        };
      });
      setTriggerIntentMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.intent,
        };
      });
      setAction((prev) => {
        return {
          ...prev,
          [node.id]: node.data.action,
        };
      });
    }

    if (node.type == "conditional") {
      setTextMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.label,
        };
      });
      setTotalConditionMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.value,
        };
      });
      setFactOriginalId((prev) => {
        return {
          ...prev,
          [node.id]: node.data.id ?? [],
        };
      });
    }

    if (node.type == "audio") {
      setTextMap((prev) => {
        return {
          ...prev,
          [node.id]: node.data.type ?? "",
        };
      });
      setAudioName((prev) => {
        return {
          ...prev,
          [node.id]: node.data.audioName ?? "",
        };
      });
      setInstructions((prev) => {
        return {
          ...prev,
          [node.id]: node.data.instructions ?? "",
        };
      });
      setUrl((prev) => {
        return {
          ...prev,
          [node.id]: node.data.url ?? "",
        };
      });
      setTime((prev) => {
        return {
          ...prev,
          [node.id]: node.data.time ?? "",
        };
      });
    }

    if (node.type == "group") {
      setLabel(node.data.label);
      node.children?.map((cnode) => {
        setDefaultValue(cnode);
      });
    }

    setTimeout(() => {
      setHasUpdate(false);
    }, 100);
  };

  useEffect(
    () => submit(selectedNode),
    [
      linkMap,
      imageMap,
      textMap,
      audioName,
      instructions,
      url,
      descMap,
      time,
      triggerIntentMap,
      totalConditionMap,
      label,
      articleLink,
      action,
      isLastNode,
    ]
  );
  const exportData = () => {
    // let jsonNode = {};

    const children = selectedNode.children.map((node) => getNodeJSONData(node));

    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify({
        ...selectedNode,
        children: children,
      })
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";

    link.click();
  };

  function getNodeJSONData(node) {
    if (!node) {
      console.error("No node to export.");
      return;
    }

    if (node?.type === "image") {
      return {
        ...node,
        data: {
          ...node.data,
          value: imageMap[node.id] ?? linkMap[node.id],
          label: label,
        },
      };
    }
    if (node?.type === "text") {
      return {
        ...node,
        data: {
          ...node.data,
          value: textMap[node.id],
          label: label,
          isLastNode: isLastNode,
        },
      };
    }
    if (node?.type === "start") {
      return {
        ...node,
        data: {
          ...node.data,
          intent: triggerIntentMap[node.id],
          label: label,
        },
      };
    }
    if (node?.type === "card") {
      console.log("articleLink", typeof articleLink, articleLink);
      return {
        ...node,
        data: {
          ...node.data,
          imageLink: linkMap[node.id],
          title: textMap[node.id],
          description: descMap[node.id],
          articleLink: articleLink,
          label: label,
        },
      };
    }
    if (node?.type === "carousel") {
      return {
        ...node,
        data: {
          ...node.data,
          imageLink: imageMap[node.id] ?? linkMap[node.id],
          title: textMap[node.id],
          description: descMap[node.id],
          articleLink: descMap[node.id],
          label: label,
        },
      };
    }
    if (node?.type == "button") {
      return {
        ...node,
        data: {
          ...node.data,
          value: textMap[node.id],
          intent: triggerIntentMap[node.id],
          label: label,
          action: action[node.id],
        },
      };
    }
    if (node?.type === "conditional") {
      return {
        ...node,
        data: {
          ...node.data,
          label: textMap[node.id],
          value: totalConditionMap[node.id],
          originalId: factOriginalId[node.id],
          // label: label,
        },
      };
    }
    if (node?.type === "audio") {
      return {
        ...node,
        data: {
          ...node.data,
          audioName: audioName[node.id],
          instructions: instructions[node.id],
          url: url[node.id],
          type: textMap[node.id],
          time: time[node.id],
          // label: label,
        },
      };
    }

    // let nodeData;

    // if (node.type === "text") {
    //   nodeData = {
    //     value: node.data.value,
    //   };
    // } else if (node.type === "image") {
    //   nodeData = {
    //     type: node.type,
    //     value: node.data.value,
    //     label: label,
    //   };
    // } else if (node.type === "conditional") {
    //   nodeData = {
    //     ...node,
    //     data: {
    //       ...node.data,
    //       label: textMap[node.id],
    //       value: totalConditionMap[node.id],
    //       originalId: factOriginalId[node.id],
    //       // label: label,
    //     },
    //   };
    // } else if (node.type === "group") {
    //   // Handle the "group" type here
    //   nodeData = {
    //     type: node.type,
    //     label: node.data.label,
    //     value: node.data.value,
    //   };
    // } else {
    //   console.error(`Unsupported node type: ${node.type}`);
    //   return;
    // }
    // return nodeData;
  }

  function submit(node, orderNode) {
    setHasUpdate(true);
    if (node?.type === "image") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          value: imageMap[node.id] ?? linkMap[node.id],
          label: label,
          order_node: orderNode,
        },
      });
    }
    if (node?.type === "text") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          value: textMap[node.id],
          label: label,
          isLastNode: isLastNode,
          order_node: orderNode,
        },
      });
    }
    if (node?.type === "start") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          intent: triggerIntentMap[node.id],
          label: label,
          order_node: orderNode,
        },
      });
    }
    if (node?.type === "card") {
      console.log("articleLink", typeof articleLink, articleLink);
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          imageLink: linkMap[node.id],
          title: textMap[node.id],
          description: descMap[node.id],
          articleLink: articleLink,
          label: label,
          order_node: orderNode,
        },
      });
    }
    if (node?.type === "carousel") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          imageLink: imageMap[node.id] ?? linkMap[node.id],
          title: textMap[node.id],
          description: descMap[node.id],
          articleLink: descMap[node.id],
          label: label,
          order_node: orderNode,
        },
      });
    }
    if (node?.type == "button") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          value: textMap[node.id],
          intent: triggerIntentMap[node.id],
          label: label,
          action: action[node.id],
          order_node: orderNode,
        },
      });
    }
    if (node?.type === "conditional") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          label: textMap[node.id],
          value: totalConditionMap[node.id],
          originalId: factOriginalId[node.id],
          order_node: orderNode,
          // label: label,
        },
      });
    }
    if (node?.type === "audio") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          audioName: audioName[node.id],
          instructions: instructions[node.id],
          url: url[node.id],
          type: textMap[node.id],
          time: time[node.id],
          order_node: orderNode,
        },
      });
    }
    if (node?.type === "group") {
      setSelectedNode({
        ...node,
        data: {
          ...node.data,
          label: label,
        },
      });
      node.children?.map((cnode, index) => {
        submit(cnode, index + 1);
      });
    }

    // closeDrawer();
  }

  function renderNode(node) {
    switch (node.type) {
      case "start":
        return (
          <StartComponent
            node={node}
            triggerIntent={triggerIntentMap[node.id]}
            setTriggerIntent={(intent) =>
              setTriggerIntentMap((prev) => {
                return {
                  ...prev,
                  [node.id]: intent,
                };
              })
            }
            agents={agents}
            intents={intents}
            deleteNode={deleteNode}
          />
        );
      case "text":
        return (
          <TextUpdater
            node={node}
            value={textMap[node.id]}
            setValue={(text) =>
              setTextMap((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            isLastNode={isLastNode}
            setIsLastNode={(checked) => {
              setIsLastNode(checked);
            }}
            deleteNode={deleteNode}
          />
        );

      case "image":
        return (
          <ImageComponent
            image={imageMap[node.id]}
            setImage={(image) =>
              setImageMap((prev) => {
                return {
                  ...prev,
                  [node.id]: image,
                };
              })
            }
            link={linkMap[node.id]}
            setLink={(link) =>
              setLinkMap((prev) => {
                return {
                  ...prev,
                  [node.id]: link,
                };
              })
            }
            deleteNode={deleteNode}
          />
        );

      case "card":
        return (
          <CardComponent
            image={imageMap[node.id]}
            setImage={(image) =>
              setImageMap((prev) => {
                return {
                  ...prev,
                  [node.id]: image,
                };
              })
            }
            link={linkMap[node.id]}
            setLink={(link) =>
              setLinkMap((prev) => {
                return {
                  ...prev,
                  [node.id]: link,
                };
              })
            }
            articleLink={articleLink}
            setArticleLink={setArticleLink}
            title={textMap[node.id]}
            setTitle={(text) =>
              setTextMap((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            description={descMap[node.id]}
            setDescription={(description) =>
              setDescMap((prev) => {
                return {
                  ...prev,
                  [node.id]: description,
                };
              })
            }
            deleteNode={deleteNode}
          />
        );

      case "carousel":
        return (
          <CarouselComponent
            image={imageMap[node.id]}
            setImage={(image) =>
              setImageMap((prev) => {
                return {
                  ...prev,
                  [node.id]: image,
                };
              })
            }
            link={linkMap[node.id]}
            setLink={(link) =>
              setLinkMap((prev) => {
                return {
                  ...prev,
                  [node.id]: link,
                };
              })
            }
            articleLink={articleLink}
            setArticleLink={setArticleLink}
            title={textMap[node.id]}
            setTitle={(text) =>
              setTextMap((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            description={descMap[node.id]}
            setDescription={(description) =>
              setDescMap((prev) => {
                return {
                  ...prev,
                  [node.id]: description,
                };
              })
            }
            deleteNode={deleteNode}
          />
        );
      case "button":
        return (
          <ButtonComponent
            text={textMap[node.id]}
            node={node}
            deleteNode={deleteNode}
            setText={(text) =>
              setTextMap((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            triggerIntent={triggerIntentMap[node.id]}
            setTriggerIntent={(intent) =>
              setTriggerIntentMap((prev) => {
                return {
                  ...prev,
                  [node.id]: intent,
                };
              })
            }
            agents={agents}
            intents={intents}
            action={action[node.id]}
            setAction={(action) => {
              setAction((prev) => {
                return {
                  ...prev,
                  [node.id]: action,
                };
              });
            }}
          />
        );
      case "conditional":
        return (
          <ConditionalComponent
            text={textMap[node.id]}
            setText={(text) =>
              setTextMap((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            totalCondition={totalConditionMap[node.id]}
            setTotalCondition={(condition) =>
              setTotalConditionMap((prev) => {
                return {
                  ...prev,
                  [node.id]: condition,
                };
              })
            }
            onSelectFact={(fact) =>
              setFactOriginalId((prev) => {
                return {
                  ...prev,
                  [node.id]: prev[node.id].concat(fact).filter((t) => t),
                };
              })
            }
            node={node}
            deleteNode={deleteNode}
          />
        );
      case "audio":
        return (
          <AudioComponent
            node={node}
            text={textMap[node.id]}
            setText={(text) =>
              setTextMap((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            audioName={audioName[node.id]}
            setAudioName={(text) => {
              setAudioName((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              });
            }}
            instructions={instructions[node.id]}
            setInstructions={(text) =>
              setInstructions((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            url={url[node.id]}
            setUrl={(text) =>
              setUrl((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            time={time[node.id]}
            setTime={(text) =>
              setTime((prev) => {
                return {
                  ...prev,
                  [node.id]: text,
                };
              })
            }
            deleteNode={deleteNode}
          />
        );
      default:
        return <div>Default {node.type} Node</div>;
    }
  }

  function closeDrawer() {
    handleDrawerClose(hasUpdate);
    setImageMap({});
    setDescMap({});
    setLinkMap({});
    setTextMap({});
    setArticleLink("");
    setTriggerIntentMap({});
    setTotalConditionMap({});
    onClose();
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={closeDrawer}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {!!selectedNode ? (
              selectedNode.type == "group" ? (
                <Editable
                  style={{ width: "50%" }}
                  value={label}
                  onChange={setLabel}
                >
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              ) : (
                selectedNode.type
              )
            ) : (
              ""
            )}
          </DrawerHeader>

          <DrawerBody>
            {!!selectedNode &&
              (selectedNode?.type == "group" ? (
                <div>
                  {selectedNode.children
                    ?.sort((a, b) => a.data.order_node - b.data.order_node)
                    .map((node) => renderNode(node))}
                </div>
              ) : (
                renderNode(selectedNode)
              ))}
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            {hasAddNewCarousel && (
              <Button
                onClick={(e) =>
                  addNewNode(
                    "carousel",
                    selectedNode.type == "group"
                      ? selectedNode.id
                      : selectedNode.parentNode
                  )
                }
                style={{ marginRight: " 10px" }}
                colorScheme="blue"
              >
                Add Card
              </Button>
            )}
            {hasAddNewButton && (
              <Button
                onClick={(e) =>
                  addNewNode(
                    "button",
                    selectedNode.type == "group"
                      ? selectedNode.id
                      : selectedNode.parentNode
                  )
                }
                style={{ marginRight: " 10px" }}
                colorScheme="blue"
              >
                Add New Button
              </Button>
            )}

            <Button onClick={(e) => closeDrawer()} colorScheme="gray" mr={5}>
              Close
            </Button>
            <Button
              colorScheme="red"
              mr={3}
              onClick={async () => {
                if (selectedNode) {
                  await deleteNode(selectedNode.id);

                  toast({
                    position: "top-right",
                    title: "Node deleted.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              }}
            >
              Delete
            </Button>

            <Button onClick={exportData} mr={5}>
              Export Node
            </Button>
            {hasAddNewCondition && (
              <Button
                onClick={(e) =>
                  addNewNode(
                    "conditional",
                    selectedNode.type == "group"
                      ? selectedNode.id
                      : selectedNode.parentNode
                  )
                }
                style={{ marginLeft: "12px" }}
                colorScheme="blue"
              >
                New Condition
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default SidebarEditNodes;

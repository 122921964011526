import React from "react";

export const GoToComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 72"
      width="16"
      height="16"
    >
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M41.958 9.194c-3.291-3.264-8.665-3.251-11.911-.005l-8.058 8.057 13.994 13.995L49.994 17.23l-8.032-8.033-.004-.003zm12.81 12.809L40.755 36.014l13.997 13.997 8.058-8.058c3.246-3.245 3.26-8.62-.004-11.91l-.004-.005-8.036-8.035zM31.21 36.014 17.216 22.019l-8.019 8.02-.004.003c-3.263 3.291-3.25 8.665-.004 11.911l8.041 8.041 13.98-13.98zm-9.207 18.753 13.98-13.98L49.98 54.784l-8.027 8.027c-3.245 3.246-8.62 3.26-11.91-.004l-.005-.004-8.035-8.036zm-6.888 2.658 10.159 10.16.008.008c5.914 5.871 15.552 5.884 21.444-.009l20.858-20.858c5.893-5.892 5.88-15.53.009-21.444L46.726 4.416l-.008-.009c-5.914-5.871-15.552-5.884-21.444.009L14.907 14.782a3.36 3.36 0 0 0-.156.156L4.416 25.274l-.009.008c-5.871 5.914-5.884 15.552.009 21.444l10.156 10.157a3.402 3.402 0 0 0 .543.542z"
      ></path>
    </svg>
  );
};

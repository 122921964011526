import React, { useEffect, useState } from "react";

import axios from "axios";
import {
  getDbEditIntentParams,
  getStorageAccessToken,
  setStorageAccessToken,
} from "../LocalStorage/mainDb";
import { type } from "@testing-library/user-event/dist/type";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setTopicId, setUserName } from "../redux/slice";
import { useNavigate } from "react-router-dom";

const nodeSize = {
  height: 63,
  width: 150,
};
const getNodeId = () => `randomnode_${+new Date()}`;

export default function useNetworkingHook() {
  const toast = useToast();

  const { selectedAgent, canvasId, topicId, userRole, userName } = useSelector(
    (state) => state.slice
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [nodeTypeList, setNodeTypeList] = useState([]);
  const [rulesActionGroupList, setRulesActionGroupList] = useState([]);
  const [variableList, setVariableList] = useState([]);
  const [protocolList, setProtocolList] = useState([]);
  const [intents, setIntents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [factList, setFactList] = useState([]);
  const [canvasFlowList, setCanvasFlowList] = useState([]);
  const [blockTopicsList, setBlockTopicsList] = useState([]);
  const [blockTopicsTotalList, setBlockTopicsTotalList] = useState([]);
  const [nodeTopicsList, setNodeTopicsList] = useState([]);
  const [pathTopicsList, setPathTopicsList] = useState([]);
  const [conditionalPathTopicsList, setConditionalPathTopicsList] = useState(
    []
  );
  const [actionTypeList, setActionTypeList] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [topicsCanvasList, setTopicsCanvasList] = useState([]);
  const [intentListAgent, setIntentListAgent] = useState([]);
  const [userList, setUserList] = useState([]);
  const [notificatonOptionsList, setNotificationOptionsList] = useState([]);
  const [redirectOptionsList, setRedirectOptionsList] = useState([]);
  const [notifyOptionsList, setNotifyOptionsList] = useState([]);
  const [notificationRuleDetailsList, setNotificationRuleDetailsList] =
    useState([]);
  const [entitiesList, setEntitiesList] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [blockFromAgent, setBlockFromAgent] = useState([]);
  const [contentFromEntities, setContentFromEntities] = useState([]);
  const [intentFromAgent, setIntentFromAgent] = useState([]);
  const [patientGuideLinesList, setPatientGuideLinesList] = useState([]);

  function getErrorMessage(statusCode) {
    console.log("err msg", statusCode);
    switch (statusCode) {
      case 400:
        return "Bad Request";
      case 401:
        return "Unauthorized";
      case 404:
        return "Not Found";
      case 409:
        return "Conflict / Exists";
      case 500:
        return "General Error / Something Went Wrong";
      case typeof statusCode == "string":
        return statusCode;
      default:
        return "An error occurred";
    }
  }

  function showToastNotification(status) {
    // Use Chakra UI's toast component to display the notification
    toast({
      title: "Error",
      description: getErrorMessage(status),
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }

  const getNodeTypeList = async () => {
    setLoading(true);
    const request = await fetch("https://console.zana.ai/node/type", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getStorageAccessToken(),
        "Content-Type": "application/json",
      },
    });
    setLoading(false);
    console.log("/node/type", request.status);
    if (request.status === 401) sendToLogin();
    if (!request.ok) {
      console.log("not ok");
      return;
    }
    const response = await request.json();
    // console.log("/node/type", response);
    setNodeTypeList(response);
  };

  async function updateNode(node) {
    try {
      if (node.type === "group") return;

      const nodeResponse = await axios.get(
        "https://console.zana.ai/node/" + node.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("node/" + node.id, nodeResponse.status);
      if (nodeResponse.status === 401) sendToLogin();
      if (![200, 201].includes(nodeResponse.status)) {
        // handle error
        console.log("node/" + node.id + "error", nodeResponse.data);
        showToastNotification(nodeResponse.status);

        return;
      }
      let nodeObj = nodeResponse.data;
      const requestBody = {
        id: nodeObj.id,
        nodeValue: typeof node.data.value == "string" ? node.data.value : "",
        nodeName: node.data.label,
        order_node: node.data.order_node,
        nodeTypeDTO: { id: nodeObj.nodeType.id },
        agentDTO: { id: selectedAgent.id },
        blockDTO: { id: nodeObj.block.id },
        intentsDTO: nodeObj.intents,
        alias: nodeObj.alias,
        disableTextAndVoice: nodeObj.disableTextAndVoice,
        coordX: node.position.x,
        coordY: node.position.y,
        lastNode: node.data.isLastNode,
      };
      updateNodeType(node, nodeObj.nodeType);
      console.log("updatenode requestBody", requestBody);
      const response = await axios.put(
        "https://console.zana.ai/node/update",
        requestBody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("node/update", response.status);

      if (response.status !== 200) {
        //handle error
        const errorObject = response.data;

        console.log("updatenode", errorObject);

        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function updateNodeType(node, nodeType) {
    try {
      console.log("updateNodeType node", node, "nodeType", nodeType);

      const endpointType = [
        "button",
        "card",
        "carousel",
        "image",
        "text",
        "conditional",
        "start",
        "audio",
      ].includes(node.type)
        ? node.type + "/"
        : "";

      const nodeResponse = await axios.get(
        "https://console.zana.ai/node/" + endpointType + nodeType.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("node/" + endpointType + nodeType.id, nodeResponse.status);
      if (nodeResponse.status === 401) sendToLogin();
      if (![200, 201].includes(nodeResponse.status)) {
        // handle error
        console.log(
          "node/" + endpointType + nodeType.id + "error",
          nodeResponse.data
        );
        showToastNotification(nodeResponse.status);

        return;
      }

      let nodeObj = nodeResponse.data;

      console.log("Bakcend NodeObj", nodeObj);

      const requestBody = createNodeUpdateBody(node, nodeObj);

      console.log("updatenode endpointType requestBody ", requestBody);
      const response = await axios.put(
        "https://console.zana.ai/node/" + endpointType + "update",
        requestBody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("node/update", response.status);
      if (![200, 201].includes(response.status)) {
        //handle error
        const errorObject = response.data;

        console.log("node/update", errorObject);
        showToastNotification(nodeResponse.status);

        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  function createNodeUpdateBody(localNode, backendNode) {
    console.log("localNode", localNode);
    switch (localNode.type) {
      case "button":
        return {
          id: backendNode.id,
          name: localNode.data.intent.label,
          label: localNode.data.label,
          intentDTO: { id: localNode.data.intent.id },
        };
      case "card":
        // data: {
        //   imageLink
        //   title
        //   description
        //   articleLink
        // }
        return {
          id: backendNode.id,
          path: "",
          link: localNode.data.imageLink,
          title: localNode.data.title,
          description: localNode.data.description,
        };

      case "carousel":
        //  body : {
        //   id: 0,
        //   carouselName: "string",
        //   cardNodesDTO: [
        //     {
        //       id: 0,
        //       path: "string",
        //       link: "string",
        //       title: "string",
        //       description: "string",
        //     },
        //   ],
        // };
        return {
          id: backendNode.id,
          carouselName: localNode.data.value,
          cardNodesDTO: [
            {
              id: 0,
            },
          ],
        };
      case "conditional":
        return {
          id: backendNode.id,
          expression: convertToExpression(localNode.data.value),
          factsDTO: localNode.data.originalId?.map((id) => ({ id: id })),
        };

      case "image":
        return {
          id: backendNode.id,
          path: "string",
          link: localNode.data.value,
        };

      case "text":
        return {
          id: backendNode.id,
          text: localNode.data.value,
        };
      case "start":
        return {
          id: backendNode.id,
          label: localNode.data.label,
          intentDTO: { id: localNode.data.intent.id },
        };

      case "audio":
        return {
          id: backendNode.id,
          audioName: localNode.data.audioName,
          instructions: localNode.data.instructions,
          url: localNode.data.url,
          type: localNode.data.type,
          time: localNode.data.time,
        };

      default:
        return {
          id: backendNode.id,
          nodeValue: localNode.data.value,
          nodeName: localNode.data.label,
          order_node: localNode.data.order_node,
          nodeTypeDTO: { id: backendNode.nodeType.id },
          agentDTO: { id: backendNode.agent.id },
          blockDTO: { id: backendNode.block.id },
          intentsDTO: backendNode.intents,
          intentDTO: backendNode.intents,
          alias: backendNode.alias,
          disableTextAndVoice: backendNode.disableTextAndVoice,
        };
    }
  }

  function convertToExpression(node) {
    console.log("convertToExpression", node);

    if (node === null) {
      return "";
    }

    const { operator, left, right } = node;

    if (operator === "AND" || operator === "OR") {
      const leftExpression = convertToExpression(left);
      const rightExpression = convertToExpression(right);
      return `(${leftExpression} ${
        operator === "AND" ? "&&" : "||"
      } ${rightExpression})`;
    } else {
      const variable = node.arguments.variable;
      const value = node.arguments.value;

      if (operator === "Contains") {
        return `${variable}.contains("${value}")`;
      } else if (operator === "Does not contain") {
        return `!${variable}.includes("${value}")`;
      } else if (operator === "Starts with") {
        return `${variable}.startsWith("${value}")`;
      } else if (operator === "Ends with") {
        return `${variable}.endsWith("${value}")`;
      } else {
        return `${variable} ${convertStringToOperator(operator)} "${value}"`;
      }
    }
  }

  function convertStringToOperator(operatorName) {
    switch (operatorName) {
      case "Is":
        return "==";
      case "Is not":
        return "!=";
      case "Is greater than":
        return ">";
      case "Is greater than or equal":
        return ">=";
      case "Is less than":
        return "<";
      case "Is less than or equal":
        return "<=";
      case "Has any value":
        return "!== null && !== undefined";
      case "Is empty":
        return "== null || === undefined";
    }
  }

  function convertToNode(expression) {
    const regex =
      /(.+?)\s*(==|!=|>|>=|<|<=|!==\s*null\s*&&\s*!==\s*undefined|==\s*null\s*||\s*===\s*undefined)\s*["'](.+)["']/;
    const leafMatch = expression.match(regex);

    if (leafMatch) {
      const [_, variable, operator, value] = leafMatch;
      return {
        operator: convertOperatorToString(operator),
        arguments: {
          variable: variable.trim(),
          value: value.replace(/"/g, "").trim(), // remove quotes from value
        },
      };
    }
  }

  function parseLogicEquation(equation) {
    const operators = [
      { operator: "&&", name: "AND" },
      { operator: "||", name: "OR" },
    ];

    // Remove outer parentheses
    if (equation.startsWith("(") && equation.endsWith(")")) {
      equation = equation.slice(1, -1);
    }

    for (let op of operators) {
      let depth = 0;
      for (let i = 0; i < equation.length; i++) {
        if (equation[i] === "(") depth++;
        else if (equation[i] === ")") depth--;

        if (
          depth === 0 &&
          equation.substring(i, i + op.operator.length) === op.operator
        ) {
          return {
            operator: op.name,
            left: parseLogicEquation(equation.substring(0, i).trim()),
            right: parseLogicEquation(
              equation.substring(i + op.operator.length).trim()
            ),
          };
        }
      }
    }

    // If no operators found, convert to node
    return convertToNode(equation);
  }

  function convertOperatorToString(operator) {
    switch (operator) {
      case "==":
        return "Is";
      case "!=":
        return "Is not";
      case ">":
        return "Is greater than";
      case ">=":
        return "Is greater than or equal";
      case "<":
        return "Is less than";
      case "<=":
        return "Is less than or equal";
      case "!== null && !== undefined":
        return "Has any value";
      case "== null || === undefined":
        return "Is empty";
      default:
        return null;
    }
  }

  async function addPath(
    previousNode,
    nextNode,
    ruleMethod,
    isConditional,
    ruleActionDTO,
    ruleActionArgs
  ) {
    try {
      const jsonbody = {
        name: "rule.start" + `unique_${+new Date()}`,
        description: "first rule",
        previousNodeDTO: {
          id: previousNode,
        },
        nextNodeDTO: {
          id: nextNode,
        },
        conditionalNodeDTO: !isConditional
          ? null
          : {
              id: previousNode,
            },
        agentDTO: {
          id: selectedAgent.id,
        },
        ruleMethod,
        ruleActionDTO,
        ruleActionArgs,
      };
      console.log("jsonbody addPath", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/paths/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        //handle error
        const errorObject = response.data;

        console.log("addPath", errorObject);
        showToastNotification(response.status);
        return null;
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function findPath(previousNodeId, nextNodeId) {
    try {
      const jsonbody = {
        previousNodeId: previousNodeId,
        nextNodeId: nextNodeId,
      };
      console.log("jsonbody findPath", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/paths/findPathByNodes",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        //handle error
        const errorObject = response.data;

        console.log("findPath", errorObject);
        showToastNotification(response.status);
        return null;
      }

      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function updatePath(path) {
    try {
      console.log(" path ", path);

      const requestBody = {
        id: path.id,
        name: path.name,
        description: path.description,
        previousNodeDTO: {
          id: path.previousNode.id,
          // nodeName: path.previousNode.nodeName,
          // nodeValue: path.previousNode.nodeValue,
          // alias: path.previousNode.alias,
          // nodeTypeDTO: {
          //   id: path.previousNode.nodeType.id,
          //   nodeTypeName: path.previousNode.nodeType.nodeTypeName,
          // },
          // intentsDTO: path.previousNode.intents,
          // order_node: path.previousNode.orderNode,
          // disableTextAndVoice: path.previousNode.disableTextAndVoice,
          // blockDTO: {
          //   id: path.previousNode.block.id,
          //   context: path.previousNode.block.context,
          //   blockTypeDTO: path.previousNode.block.blockType,
          //   topicsDTO: path.previousNode.block.topics,
          // },
          // agentDTO: {
          //   ...path.previousNode.agent,
          //   languagesDTO: path.previousNode.agent.languages
          //     ? path.previousNode.agent.languages.map((language) => ({
          //         id: language.id,
          //         code: language.code,
          //         name: language.name,
          //         agentDTOList: language.agentDTOList
          //           ? language.agentDTOList.map((agentDTO) => ({
          //               id: agentDTO.id,
          //             }))
          //           : [],
          //       }))
          //     : [],
          // },
        },
        nextNodeDTO: {
          id: path.nextNode.id,
          // nodeName: path.nextNode.nodeName,
          // nodeValue: path.nextNode.nodeValue,
          // alias: path.nextNode.alias,
          // nodeTypeDTO: {
          //   id: path.nextNode.nodeType.id,
          //   nodeTypeName: path.nextNode.nodeType.nodeTypeName,
          // },
          // intentsDTO: path.nextNode.intents,
          // order_node: path.nextNode.orderNode,
          // disableTextAndVoice: path.nextNode.disableTextAndVoice,
          // blockDTO: {
          //   id: path.nextNode.block.id,
          //   context: path.nextNode.block.context,
          //   blockTypeDTO: path.nextNode.block.blockType,
          //   topicsDTO: path.nextNode.block.topics,
          // },
          // agentDTO: {
          //   id: path.nextNode.agent.id,
          //   name: path.nextNode.agent.name,
          //   ip: path.nextNode.agent.ip,
          //   domainName: path.nextNode.agent.domainName,
          //   languagesDTO: path.nextNode.agent.languages
          //     ? path.nextNode.agent.languages.map((language) => ({
          //         id: language.id,
          //         code: language.code,
          //         name: language.name,
          //         agentDTOList: language.agentDTOList
          //           ? language.agentDTOList.map((agentDTO) => ({
          //               id: agentDTO.id,
          //             }))
          //           : [],
          //       }))
          //     : [],
          // },
        },
        agentDTO: {
          id: path.agent.id,
          name: path.agent.name,
          ip: path.agent.ip,
          domainName: path.agent.domainName,
          languagesDTO: path.agent.languages
            ? path.agent.languages.map((language) => ({
                id: language.id,
                code: language.code,
                name: language.name,
                agentDTOList: language.agentDTOList
                  ? language.agentDTOList.map((agentDTO) => ({
                      id: agentDTO.id,
                    }))
                  : [],
              }))
            : [],
        },
        ruleMethod: path.ruleMethod,
        ruleActionDTO: path.ruleActionDTO,
        ruleActionArgs: path.ruleActionArgs,
      };

      console.log("updatePath requestBody", requestBody);
      const response = await axios.put(
        "https://console.zana.ai/paths/update",
        requestBody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("paths/update", response.data);
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("paths/update", response.data);
        showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function conditionalPathAdd(source, target) {
    try {
      const reqBody = {
        name: "conditionalPath" + Math.random(),
        description: "conditionalPath description" + Math.random(),
        previousNodeDTO: { id: source },
        nextBlockDTO: { id: target },
      };
      console.log("reqbodypathhhhh", reqBody);
      const response = await axios.post(
        "https://console.zana.ai/conditionalPath/add",

        reqBody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("conditionalPath/add", response.status);
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("conditionalPath/add error", response.data);
        showToastNotification(response.status);
        return;
      }
      const responseData = response.data;
      console.log("conditionalPath/add", responseData);

      return responseData;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function findConditionalPath(previousNodeId, nextNodeId) {
    try {
      const jsonbody = {
        previousNodeId: previousNodeId,
        nextNodeId: nextNodeId,
      };
      console.log("jsonbody findConditionalPath", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/conditionalPath/findPathByNodes",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("conditionalPath/findPathByNodes", response.data);
        showToastNotification(response.status);
        return null;
      }

      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
        return null;
      }
    }
  }
  async function addBlock(position) {
    try {
      const { v4: uuidv4 } = require("uuid");
      console.log("creating block");
      const formattedUUID = uuidv4().split("-")[0];
      const jsonBody = {
        context: "block context " + new Date().toTimeString(),
        name: "New Block " + formattedUUID,
        blockTypeDTO: {
          id: 1,
        },
        topicsDTO: {
          id: topicId,
        },
        coordX: position.x,
        coordY: position.y,
      };
      console.log("jsonBody", jsonBody);
      const response = await axios.post(
        "https://console.zana.ai/block/add",
        jsonBody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("/block/add", response.status);
      if (![200, 201].includes(response.status)) {
        //handle error
        console.log("/block/add error", response.status);
        showToastNotification(response.status);
        return;
      }

      const responseData = response.data;
      console.log("/block/add", response.data);

      return responseData;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function updateBlock(block) {
    try {
      const beBlock = blockTopicsList.find((blk) => block.id == blk.id);
      const jsonbody = {
        id: block.id,
        context: block.data.context,
        name: block.data.label,
        blockTypeDTO: {
          id: beBlock?.blockTypeDTO?.id ?? 1,
        },
        topicsDTO: {
          id: topicId,
        },
        coordX: block.position.x,
        coordY: block.position.y,
      };
      console.log("updateBlock block", jsonbody);
      const response = await axios.put(
        "https://console.zana.ai/block/update",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("/block/update", response.status);
      if (![200, 201].includes(response.status)) {
        //handle error
        console.log("/block/update error", response.status);
        showToastNotification(response.status);
        return;
      }

      const responseData = response.data;
      console.log("/block/update", response.data);

      return responseData?.id?.toString() ?? getNodeId();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function addNode(type, parentId, nodes) {
    try {
      await setTimeout(() => {}, 100);
      console.log("type, parenid", type, parentId);
      const children = nodes.filter((nd) => nd.parentNode == parentId);
      var height = children.reduce((sum, n) => sum + (n?.height ?? 0), 100);
      console.log(
        "height",
        height,
        nodes.filter((nd) => nd.parentNode == parentId).length
      );

      let newNode = {
        id: getNodeId(),
        position: {
          x: 10,
          y: height,
        },
        parentNode: parentId,
        extent: "parent",
        data: { label: `${type} node`, value: "", order_node: 1 },
        type: type,

        style:
          type == "image" || type == "card" || type == "carousel"
            ? {}
            : nodeSize,
        ...nodeSize,
      };

      const endpointType = [
        "button",
        "card",
        "carousel",
        "image",
        "text",
        "conditional",
        "start",
        "audio",
      ].includes(type)
        ? type + "/"
        : "";

      const body = getNodeBody(newNode);
      console.log("I am creating this or ti", body);
      const response = await axios.post(
        "https://console.zana.ai/node/" + endpointType + "add",
        body,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("newNode.parentId", newNode.parentNode);
      console.log("node/" + endpointType + "add", response.status);

      if (![200, 201].includes(response.status)) {
        //handle error
        console.log("error node add", response.data);

        showToastNotification(response.data);
        return;
      }
      const nodeAddJSONBody = {
        nodeName: `${newNode.type} node` + `randomnode_${+new Date()}`,
        nodeValue: "",
        order_node: 1,
        alias: "1",
        disableTextAndVoice: true,
        nodeTypeDTO: { id: response.data.id },
        agentDTO: {
          id: selectedAgent.id,
        },
        blockDTO: {
          id: newNode.parentNode,
        },
        intentsDTO: [], //duhet id qe vendoseng nga useri kur shtohet nodi
      };
      console.log("nodeAddJSONBody", nodeAddJSONBody);
      const responseJustNodeAdd = await axios.post(
        `https://console.zana.ai/node/add`,
        nodeAddJSONBody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("node/add", responseJustNodeAdd.status);

      const responseData = responseJustNodeAdd.data;

      const objectTamam =
        typeof responseData == "string"
          ? JSON.parse(responseData?.replace('languages"', 'languages": []'))
          : responseData;
      console.log(
        "backend node",
        objectTamam,
        objectTamam["id"],
        objectTamam.id?.toString()
      );

      newNode.id = objectTamam.id?.toString() ?? getNodeId();
      if (!!objectTamam.orderNode)
        newNode.data.order_node = objectTamam.orderNode;

      console.log("this node is local", newNode);
      // actionsAdd(newNode);
      return newNode;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  function getNodeBody(node) {
    switch (node.type) {
      case "button":
        return {
          name: node.data.value,
          label: node.data.label,
          intentDTO: { id: 1 },
        };
      case "card":
        return {
          path: "string",
          link: "string",
          title: "string",
          description: "string",
        };

      case "carousel":
        return {
          id: 1,
          carouselName: "string",
          cardNodesDTO: [
            // {
            //   id: 1,
            //   path: "string",
            //   link: "string",
            //   title: "string",
            //   description: "string",
            // },
          ],
        };

      case "image":
        return {
          id: 1,
          path: "string",
          link: "string",
        };

      case "text":
        return {
          text: node.data.value,
        };
      case "conditional":
        return {
          expression: "",
        };
      case "start":
        return {
          label: node.data.label,
          intentDTO: { id: 1 },
        };
      case "audio":
        return {
          audioName: "" + `randomnode_${+new Date()}`,
          instructions: "",
          url: "",
          type: "",
        };
      default:
        return {
          nodeName: `${node.type} node` + `randomnode_${+new Date()}`,
          nodeValue: "",
          order_node: 1,
          alias: "1",
          disableTextAndVoice: true,
          nodeTypeDTO: {
            id: (
              nodeTypeList.find((nodeType) =>
                nodeType.nodeTypeName.includes(node.type.toUpperCase())
              ) ?? nodeTypeList[0]
            ).id,
          },
          agentDTO: {
            id: 1,
          },
          blockDTO: {
            id: node.parentNode,
          },
          intentsDTO: [], //duhet id qe vendoseng nga useri kur shtohet nodi
        };
    }
  }

  async function deleteTypeOfNode(node, nodeType) {
    try {
      const endpointType = [
        "button",
        "card",
        "carousel",
        "image",
        "text",
        "conditional",
        "start",
        "audio",
      ].includes(node.type)
        ? node.type + "/"
        : "";

      const response = await axios.delete(
        "https://console.zana.ai/node/" +
          endpointType +
          "delete/" +
          nodeType.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("node/" + endpointType + "delete/", response.status);

      if (![200, 201].includes(response.status)) {
        //handle error
        console.log("error node delete", response.data);

        showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        // showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function deleteNode(id) {
    try {
      const nodeResponse = await axios.get(
        "https://console.zana.ai/node/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("get node/" + id, nodeResponse.status);

      if (![200, 201].includes(nodeResponse.status)) {
        //handle error
        console.log("node/" + id + " error", nodeResponse.data);
        // showToastNotification(nodeResponse.status);
        return;
      }
      if (nodeResponse.status === 401) sendToLogin();

      const nodeObj = nodeResponse.data;

      const response = await axios.delete(
        "https://console.zana.ai/node/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("DELETE node/" + id, response.status);

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteNodeId error", response.status, response.data);
        deleteTypeOfNode(nodeObj, nodeObj.nodeType);
        // showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        // showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function deleteBlock(id) {
    try {
      const response = await axios.post(
        "https://console.zana.ai/block/delete/" + id,
        undefined,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteBlock error", response.status, response.data);
        // showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        // showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        // showToastNotification();
      }
    }
  }

  async function deletePath(id) {
    try {
      const response = await axios.post(
        "https://console.zana.ai/paths/delete/" + id,
        undefined,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        console.error("deletePathId error", response.status, response.data);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function deleteConditionalPath(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/conditionalPath/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      // Log the response status and data
      console.log("Response:", response.status, response.data);

      if (response.status === 200 || response.status === 201) {
        // Handle success (resource deleted successfully)
        console.log("conditionalPath deleted successfully");
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "conditionalPath deleted error",
          response.status,
          response.data
        );
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getBrainRulesActionGroup() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/ruleActionGroup/agent/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const parsetData = response.data.map((rule) => ({
          id: rule.id,
          groupName: rule.groupName,
        }));
        setRulesActionGroupList(parsetData);
        console.log("rulesActionGroupList", parsetData);
        return response.data;
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getBrainRulesActionGroup error",
          response.status,
          response.data
        );
        showToastNotification(response.status);
        return null;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getBrainFacts() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/facts/agent/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const parsedData = response.data;
        const externalFacts = parsedData[0].externalFacts; // Assuming external facts are in the second object
        const facts = parsedData[1].facts; // Assuming facts are in the first object

        const externalFactList = externalFacts.map((externalFact) => ({
          id: externalFact.id,
          name: externalFact.name,
          type: externalFact.type,
          originalId: externalFact.originalId,
        }));

        const factList = facts.map((fact) => ({
          id: [],
          name: fact.name,
          type: fact.type,
        }));

        // Combine regular facts and external facts with a separator
        const combinedList = [
          ...factList,
          { name: "----", type: "" },
          ...externalFactList,
        ];

        // console.log("response getBrain", combinedList);
        setVariableList(combinedList);
        return combinedList;
      }
      if (![200, 201].includes(response.status)) {
        console.error("getBrain facts error", response.status, response.data);
        showToastNotification(response.status);
        return null;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function addFacts(variableName, variableType) {
    try {
      const response = await axios.post(
        "https://console.zana.ai/facts/add",
        {
          variableName: variableName,
          variableType: variableType,
          agentDTO: { id: 1 },
        },
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("/facts/add", response.status);
      getBrainFactsExternal();

      if (![200, 201].includes(response.status)) {
        console.error("facts add error", response.status, response.data);
        showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getBrainProtocol() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/protocol/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const parsedData = response.data;
        // console.log("Brain Protocol:", parsedData);

        // Map the data to include both id and name
        const protocolNameIDList = parsedData.map((protocol) => ({
          id: protocol.id,
          name: protocol.name,
        }));
        // console.log("protocolList:", protocolNameIDList);
        setProtocolList(protocolNameIDList);
        return protocolList;
      }
      if (![200, 201].includes(response.status)) {
        console.error("getBrainProtocol error", response.status);
        showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  function getAgents() {
    axios
      .get(
        userRole === "admin"
          ? "https://console.zana.ai/agent"
          : "https://console.zana.ai/user/" + userName + "/agents",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        if (resp.status !== 200) {
          console.log("error");
          return;
        }
        if (resp.status === 401) sendToLogin();
        console.log("Get Agent List", resp.data);
        setAgents(resp.data);
      });
  }

  async function fetchIntents() {
    const resp = await axios.get("https://console.zana.ai/intent", {
      headers: {
        Authorization: "Bearer " + getStorageAccessToken(),
        "Content-Type": "application/json",
      },
    });

    if (resp.status !== 200) {
      console.log("error");
      return;
    }
    if (resp.status === 401) sendToLogin();
    // console.log("intent list", resp.data);
    setIntents(resp.data);
  }

  function fetchUtterances() {
    axios
      .get("https://console.zana.ai/intent/utterances", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (![200, 201].includes(resp.status)) {
          console.error("intent/utterances error", resp.status, resp.data);
          showToastNotification(resp.status);
          return;
        }
        // console.log("utterances ", resp.data);
        // Map the fetched utterances to their respective intents
        const updatedIntents = intents.map((intent) => {
          const utterancesForIntent = resp.data.filter(
            (utterance) => utterance.intent.id === intent.id
          );
          return {
            ...intent,
            utterances: utterancesForIntent.map(
              (utterance) => utterance.utterances
            ),
          };
        });
        setIntents(updatedIntents);
        console.log("updated intents", updatedIntents);
      })
      .catch((error) => {
        if (error.status === 401) sendToLogin();
        if (error.response) {
          showToastNotification(error.response.status);
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
          showToastNotification();
          return;
        }
      });
  }

  async function getBrainFactsExternal() {
    try {
      const response = await axios.get("https://console.zana.ai/facts", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        const parsedData = response.data;
        const externalFacts = parsedData[0].externalFacts;
        const externalFactList = externalFacts.map((externalFact) => ({
          id: externalFact.id,
          variableName: externalFact.name,
          variableType: externalFact.type,
          originalId: externalFact.originalId,
        }));

        // console.log("response getBrain", externalFactList);
        setFactList(externalFactList);
        // console.log("externalFactList", externalFactList);
        return externalFactList;
      }
      if (![200, 201].includes(response.status)) {
        console.error("etBrainFactsExternal error", response.status);
        showToastNotification(response.status);
        return null;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
        return null;
      }
    }
  }

  async function deleteFacts(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/facts/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      // Log the response status and data
      console.log("Response:", response.status, response.data);

      if (response.status === 200 || response.status === 201) {
        // Handle success (resource deleted successfully)
        console.log("Fact deleted successfully");
        toast({
          position: "top-right",
          title: "Fact deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        getBrainFactsExternal();
      }
      if (![200, 201].includes(response.status)) {
        console.error("facts/delete/ error", response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      // Handle network or other errors
      toast({
        position: "top-right",
        title:
          "You cannot delete the variable. It is used in the following flows first topic. Remove the variable from the flow to delete it.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("An error occurred:", error);
    }
  }

  async function deleteFactsList(ids) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/facts/deleteList/",
        ids,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      // Log the response status and data
      console.log("Response:", response.status, response.data);

      if (response.status === 200 || response.status === 201) {
        // Handle success (resource deleted successfully)
        console.log("Fact List deleted successfully");
        getBrainFactsExternal();
      }
      if (![200, 201].includes(response.status)) {
        console.error("facts List error", response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function addCanvas(flowName) {
    try {
      const response = await axios.post(
        "https://console.zana.ai/canvas/add",
        {
          flowName: flowName,
          agentDTO: {
            id: selectedAgent.id,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("/canvas/add", response.status);
      toast({
        position: "top-right",
        title: "Flow Created",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      console.log("canvas/add", response.data);

      if (![200, 201].includes(response.status)) {
        console.error("addCanvas error", response.data);
        showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function updateCanvas(flowName) {
    try {
      const response = await axios.put(
        "https://console.zana.ai/canvas/update",
        {
          id: canvasId,
          flowName: flowName,
          agentDTO: {
            id: selectedAgent.id,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("updateCanvas", response.status);
      toast({
        position: "top-right",
        title: "Name Changed",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      console.log("updateCanvas", response.data);

      if (![200, 201].includes(response.status)) {
        console.error("addCanvas error", response.data);
        showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function deleteCanvas(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/canvas/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("canvas/delete", response.status);

      if (![200, 201].includes(response.status)) {
        console.error("canvas/delete error", response.data, response.status);
        showToastNotification(response.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getCanvasFlow(id) {
    try {
      setCanvasFlowList([]);
      const response = await axios.get(
        "https://console.zana.ai/canvas/agent/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const parsedData = response.data;
        console.log("getCanvasFlow:", parsedData);
        setCanvasFlowList(parsedData);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getCanvasFlow error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getPathTopics(topic = topicId) {
    try {
      if (!topic) return;
      setPathTopicsList([]);
      const response = await axios.get(
        "https://console.zana.ai/paths/topics/" + topic,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("paths/topics", response.status);

      if (response.status === 200) {
        const parsedData = response.data;
        const prevAndNext = parsedData.map((path) => ({
          id: path.id,
          previousNode:
            path.conditionalNode == null
              ? path.previousNode
              : path.conditionalNode,
          nextNode: path.nextNode,
        }));
        console.log("prevandnext", prevAndNext);
        console.log("getPathTopic:", parsedData);
        setPathTopicsList(prevAndNext);
        return response.data;
      }
      if (![200, 201].includes(response.status)) {
        console.error("getPathTopics error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getTopicsCanvas(canvas = canvasId) {
    try {
      const response = await axios.get(
        "https://console.zana.ai/topics/canvas/" + canvas,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const topics = response.data;

        if (!topicId || !topics.find((topic) => topic.id == topicId)) {
          dispatch(setTopicId(response.data[0].id));
        }

        setTopicsCanvasList(topics);
        console.log("getTopicsCanvas:", response.data);
        return topics;
      }
      if (![200, 201].includes(response.status)) {
        console.error("getTopicsCanvas error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getBlockTopics(topic = topicId) {
    try {
      if (!topic) return;
      setBlockTopicsTotalList([]);
      setBlockTopicsList([]);
      const response = await axios.get(
        "https://console.zana.ai/block/topics/" + topic,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const parsedData = response.data;
        const blockDataId = parsedData.map((item) => ({
          id: item.id,
          name: item.name,
          context: item.context,
          position: {
            x: item.coordX ?? 0,
            y: item.coordY ?? 0,
          },
        }));
        console.log("lista  block", blockDataId);
        console.log("getBlockCanvas:", response.data);
        setBlockTopicsTotalList(response.data);
        setBlockTopicsList(blockDataId);
        return response.data;
      }
      if (![200, 201].includes(response.status)) {
        console.error("getBlockTopics error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getConditionalPathTopics(topic = topicId) {
    try {
      const response = await axios.get(
        "https://console.zana.ai/conditionalPath/topics/" + topic,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const parsedData = response.data;

        setConditionalPathTopicsList(parsedData);
        console.log("getConditionalPathTopics:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getConditionalPathTopics error",
          response.data,
          response.status
        );
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getNodeTopics(topic = topicId) {
    try {
      if (!topic) return;
      setNodeTopicsList([]);
      const response = await axios.get(
        "https://console.zana.ai/node/topics/" + topic,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("getNodeTopics:", response.status);

      if (response.status === 200) {
        const nodeTopics = response.data;
        console.log("getNodeTopics:", response.data);
        setNodeTopicsList(nodeTopics);
        return nodeTopics;
      }
      if (![200, 201].includes(response.status)) {
        console.error("getNodeTopics error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function addTopics(name, protocolNo) {
    try {
      const jsonbody = {
        name: name,
        canvasFlowDTO: {
          id: canvasId,
        },
        protocolNo,
      };
      const response = await axios.post(
        "https://console.zana.ai/topics/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        getTopicsCanvas();
        console.log("topics/add:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("addTopics error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function updateTopics(name, protocolNo) {
    try {
      const jsonbody = {
        id: topicId,
        name: name,
        protocolNo,
        canvasFlowDTO: {
          id: canvasId,
        },
      };
      console.log("updateTopic", jsonbody);
      const response = await axios.put(
        "https://console.zana.ai/topics/update",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        getTopicsCanvas();
        console.log("updateTopics:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("updateTopics error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function deleteTopics() {
    try {
      if (!topicId) return;
      const response = await axios.delete(
        "https://console.zana.ai/topics/delete/" + topicId,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("deleteTopics/delete/", response.status);
      toast({
        position: "top-right",
        title: "Topic deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      getTopicsCanvas();
      if (![200, 201].includes(response.status)) {
        console.error("deleteTopics error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function getActionsType() {
    try {
      console.log("getActionsType start");
      const response = await axios.get(
        "https://console.zana.ai/actionType/list/",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const actionsType = response.data;
        const actionsTypeData = actionsType.map((actions) => ({
          id: actions.id,
          type: actions.type,
        }));
        console.log("actionsTypeData", actionsTypeData);
        setActionTypeList(actionsTypeData);
        console.log("getActionsType:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getActionsType error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function deleteAction(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/actions/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("actions/delete/", response.status);

      if (![200, 201].includes(response.status)) {
        console.error("deleteAction error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function getActionsList() {
    try {
      const response = await axios.get("https://console.zana.ai/actions/list", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setActionList(response.data);
        console.log("getActionsList:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("deleteAction error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function addActions(
    previousNode,
    node,
    selectedAction,
    ruleMethod,
    ruleActionId,
    ruleActionArgs
  ) {
    console.log("addaction", node);
    try {
      const jsonbody = {
        type: selectedAction.type + new Date().toTimeString(),
        blockDTO: {
          id: node.block?.id ?? node.id,
          // id: node.type == "group" ? node.id : node.parentNode,
        },
        nodeDTO: {
          id: node.type == "group" ? null : node.id,
        },
        actionTypeDTO: {
          id: selectedAction.id,
        },
        previousNodeDTO: {
          id: previousNode.id,
        },
        ruleMethod: ruleMethod.id,
        ruleActionDTO: {
          id: ruleActionId,
        },
        ruleActionArgs: JSON.stringify(ruleActionArgs),
      };
      console.log("json addActions", jsonbody);
      console.log("node addActions", node);

      const response = await axios.post(
        "https://console.zana.ai/actions/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("actions/add error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/actions/add", response.data);
        return response.data.id;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }
  async function nodeImport(file) {
    try {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        "https://console.zana.ai/node/import/" + topicId,
        formData,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/node/import/ error", response.data);
        showToastNotification(response.status);

        return;
      }

      getBlockTopics();
      console.log("/node/import/", response.data);
      return response.data.id;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }
  async function canvasImport(file) {
    try {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        "https://console.zana.ai/canvas/import/",
        formData,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/canvas/import/ error", response.data);
        showToastNotification(response.status);

        return;
      }

      getBlockTopics();
      console.log("/canvas/import/", response.data);
      return response.data.id;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getIntentsAgent() {
    //TODO: use this for listing intents of agents instead of filtering them from selectedAgentID
    try {
      const response = await axios.get(
        "https://console.zana.ai/intent/agent/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const parsedData = response.data;
        console.log("getIntentsAgent:", parsedData);
        setIntentListAgent(parsedData);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getIntentsAgent error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function exportIntentList() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/intent/export/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("exportIntentList", response.data);
        return response.data;
      }
      if (![200, 201].includes(response.status)) {
        console.error("exportIntentList error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function intentImport(file, agentId) {
    try {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        "https://console.zana.ai/intent/import/" + agentId,
        formData,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/intent/import/ error", response.data);
        showToastNotification(response.status);

        return;
      }
      console.log("/intent/import/", response.data);
      return response.data.id;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }

  async function intentPredict(utterance) {
    try {
      const jsonbody = {
        utterance: utterance,
        agentId: selectedAgent.id,
      };
      const response = await axios.post(
        "https://console.zana.ai/intent/predict",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("actions/add error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("intentPredict", response.data);
        return response.data.intent.name;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }
  async function saveIntent({
    id,
    intent,
    utterances,
    label,
    agentId,
    oldUtterances = [],
  }) {
    try {
      console.log("utterances mainDB", utterances);
      console.log("oldUtterances mainDB", oldUtterances);
      const isNew = !id;
      const response = await axios.post(
        isNew
          ? "https://console.zana.ai/intent/add"
          : "https://console.zana.ai/intent/update",
        {
          id: id,
          name: intent,
          label: label,
          intentCategoryDTO: {
            id: 1,
          },
          agentDTO: {
            id: agentId,
          },
        },
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log(
        "intent",
        !id ? "Create" : "Update",
        response.status,
        response
      );
      if ([200].includes(response.status)) {
        toast({
          position: "top-right",
          title: "Intent Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      if (response.status === 401) sendToLogin();
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("saveIntent error", response.data);
        showToastNotification(response.status);

        return;
      }

      if (!id) {
        const resData = response.data;
        console.log("Saved this intent", resData);
        id = resData.id;
      }
      console.log(
        "Request response",
        isNew
          ? utterances
          : utterances.map((utt) => !oldUtterances.includes(utt))
      );
      console.log("is new", isNew);
      console.log("UTTERANces", utterances);
      console.log(
        "lista",
        utterances.map((utt) => !oldUtterances.includes(utt))
      );

      const respUttterance = await axios.post(
        "https://console.zana.ai/intent/utterances/import",
        {
          intent: {
            id: id,
          },
          language: {
            id: 1,
          },
          utterances: utterances,
          // utterances: isNew
          //   ? utterances
          //   : utterances.map((utt) => !oldUtterances.includes(utt)),
          label: "utterances",
        },
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Request uterances", respUttterance.data);
      fetchUtterances();
      if (![200, 201].includes(respUttterance.status)) {
        // handle error
        console.log("respUttterance error", respUttterance.status);
        showToastNotification(respUttterance.status);
        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }

  async function deleteIntent(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/intent/delete/" + id,
        undefined,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteIntent error", response.status);
        showToastNotification(response.status);

        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }

  async function deleteUtterances(utteranceId, utterance) {
    try {
      const response = await axios.delete(
        `https://console.zana.ai/intent/utterances/delete/${utteranceId}/${utterance}`,
        undefined,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteUtterances error", response.status);
        showToastNotification(response.status);

        return;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }

  async function createUser(
    username,
    email,
    password,
    companyProfile,
    enabled
  ) {
    try {
      const jsonbody = {
        username,
        email,
        password,
        accountLocked: false,
        accountExpired: false,
        passwordExpired: false,
        companyProfile,
        enabled,
        agentIds: [selectedAgent.id],
      };
      console.log("createUser", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/user/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/user/add error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        getUserList();
        console.log("/user/add", response.data);
        toast({
          position: "top-right",
          title: "User Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }
  async function updateUser({
    id,
    username,
    email,
    password,
    accountLocked,
    accountExpired,
    passwordExpired,
    companyProfile,
    enabled,
    agentList,
  }) {
    try {
      const jsonbody = {
        user: {
          id,
          username,
          email,
          password,
          accountLocked,
          accountExpired,
          passwordExpired,
          companyProfile,
          enabled,
          agentList,
        },
        userRoleFilter: { role: { name: "user" } },
      };
      console.log("updateuser", jsonbody);
      const response = await axios.put(
        "https://console.zana.ai/user/update",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/user/update error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        getUserList();
        console.log("/user/update", response.data);
        toast({
          position: "top-right",
          title: "User Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function getUserList() {
    try {
      const response = await axios.get("https://console.zana.ai/user", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setUserList(response.data);
        console.log("getUserList:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getUserList error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function deleteUser(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/user/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteUser error", response.status);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        getUserList();
        toast({
          position: "top-right",
          title: "User Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function notificationRuleAdd({
    name,
    description,
    notificationOptions,
    condition,
    message,
    redirectOptions,
    redirectUrl,
    content,
    notifyOptions,
  }) {
    try {
      const jsonbody = {
        name,
        description,
        notificationOptions,
        condition: convertToExpression(condition),
        message,
        redirectOptions,
        redirectUrl,
        contentDTO: !!content ? { id: content } : null,
        notifyOptions,
        agentDTO: {
          id: selectedAgent.id,
        },
      };
      console.log("notificationRuleAdd", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/notificationRuleDetails/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/notificationRuleDetails/add error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/notificationRuleDetails/add", response.data);
        toast({
          position: "top-right",
          title: "Notification Rule Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function notificationRuleUpdate(notificationRule) {
    console.log("notificationRuleUpdate(notificationRule)", notificationRule);
    const {
      id,
      name,
      description,
      notificationOptions,
      condition,
      message,
      redirectOptions,
      redirectUrl,
      content,
      notifyOptions,
    } = notificationRule;
    try {
      const jsonbody = {
        id,
        name,
        description,
        notificationOptions,
        condition: convertToExpression(condition),
        message,
        redirectOptions,
        redirectUrl,
        contentDTO: !!content ? { id: content.id } : null,
        notifyOptions,
        agentDTO: {
          id: selectedAgent.id,
        },
      };
      console.log("notificationRuleUpdate", jsonbody);
      const response = await axios.put(
        "https://console.zana.ai/notificationRuleDetails/update",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/notificationRuleDetails/update error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/notificationRuleDetails/update", response.data);
        toast({
          position: "top-right",
          title: "Notification Rule Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getNotificatonOptions() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/notificationRuleDetails/notificationOptions",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setNotificationOptionsList(response.data);
        console.log("getNotificatonOptions:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getNotificatonOptions error",
          response.data,
          response.status
        );
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getRedirectOptions() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/notificationRuleDetails/redirectOptions",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setRedirectOptionsList(response.data);
        console.log("getRedirectOptions:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getRedirectOptions error",
          response.data,
          response.status
        );
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getNotifyOptions() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/notificationRuleDetails/notifyOptions",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setNotifyOptionsList(response.data);
        console.log("getNotifyOptions:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getNotifyOptions error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function getNotificationRuleDetails() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/notificationRuleDetails/agent/" +
          selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setNotificationRuleDetailsList(response.data);
        console.log("getNotificationRuleDetails:", response.data);
      }

      if (![200, 201].includes(response.status)) {
        console.error(
          "getNotificationRuleDetails error",
          response.data,
          response.status
        );
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function deleteNotificationRule(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/notificationRuleDetails/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteNotificationRule error", response.status);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        getNotificationRuleDetails();
        toast({
          position: "top-right",
          title: "Notification Rule Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }

  async function getEntitiesList() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/entity/agent/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setEntitiesList(response.data);
        console.log("getEntitiesList:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getEntitiesList error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function deleteEntity(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/entity/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteEntity error", response.status);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        getEntitiesList();
        toast({
          position: "top-right",
          title: "Entity  Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }

  async function entityAdd({
    name,
    note,
    url,
    type,
    description,
    source,
    synonyms,
    parentId,
  }) {
    try {
      const jsonbody = {
        name,
        note,
        agentDTO: {
          id: selectedAgent.id,
        },
        url,
        type,
        description,
        source,
        synonyms,
        parentDTO: !!parentId ? { id: parentId } : null,
      };
      console.log("entityAdd", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/entity/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/entity/add error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/entity/add", response.data);
        getEntitiesList();
        toast({
          position: "top-right",
          title: "Entity Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }
  async function entityUpdate({
    id,
    name,
    note,
    url,
    type,
    description,
    source,
    synonyms,
    parentId,
  }) {
    try {
      const jsonbody = {
        id,
        name,
        note,
        agentDTO: {
          id: selectedAgent.id,
        },
        url,
        type,
        description,
        source,
        synonyms,
        parentDTO: !!parentId ? { id: parentId } : null,
      };
      console.log("entityUpdate", jsonbody);
      const response = await axios.put(
        "https://console.zana.ai/entity/update",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/entity/update error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/entity/update", response.data);
        getEntitiesList();
        toast({
          position: "top-right",
          title: "Entity Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }
  async function entityImport(file, agentId) {
    try {
      console.log("file", file);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        "https://console.zana.ai/entity/import/" + agentId,
        formData,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/intent/import/ error", response.data);
        showToastNotification(response.status);

        return;
      }
      console.log("/intent/import/", response.data);
      return response.data.id;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification(500);
      }
    }
  }

  async function getContentList() {
    try {
      const response = await axios.get("https://console.zana.ai/content", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setContentList(response.data);
        console.log("getContentList:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getContentList error", response.data, response.status);
        showToastNotification(response.status);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function entityContentAdd({
    title,
    summary,
    content,
    image,
    url,
    intent,
    source,
    entityId,
  }) {
    try {
      const jsonbody = {
        title,
        summary,
        plainSummary: summary?.replace(/<[^>]*>/g, ""),
        content,
        plainContent: content?.replace(/<[^>]*>/g, ""),
        image,
        url,
        intent,
        source,
        agentDTO: {
          id: selectedAgent.id,
        },
        entitiesDTO: {
          id: entityId,
        },
      };
      console.log("entityContentAdd", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/content/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/content/adderror", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/content/add", response.data);
        getContentList();
        toast({
          position: "top-right",
          title: "Content Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response.status === 401) sendToLogin();
      console.error("An error occurred:", error);
      showToastNotification(500);
    }
  }

  async function entityContentUpdate({
    id,
    title,
    summary,
    content,
    image,
    url,
    intent,
    source,
    entityId,
  }) {
    try {
      const jsonbody = {
        id,
        title,
        summary,
        plainSummary: summary?.replace(/<[^>]*>/g, ""),
        content,
        plainContent: content?.replace(/<[^>]*>/g, ""),
        image,
        url,
        intent,
        source,
        agentDTO: {
          id: selectedAgent.id,
        },
        entitiesDTO: {
          id: entityId,
        },
      };
      console.log("entityContentUpdate", jsonbody);
      const response = await axios.put(
        "https://console.zana.ai/content/update",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("content/update error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("content/update", response.data);
        getContentList();
        toast({
          position: "top-right",
          title: "Content Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response.status === 401) sendToLogin();
      console.error("An error occurred:", error);
      showToastNotification(500);
    }
  }
  async function getContentFromEntities(id) {
    try {
      const response = await axios.get(
        "https://console.zana.ai/content/entities/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setContentFromEntities(response.data);
        console.log("getContentFromEntities:", response.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function deleteEntityContent(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/content/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteEntity error", response.status);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        getContentList();
        toast({
          position: "top-right",
          title: "Content Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function getBlockFromAgent() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/block/agent/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setBlockFromAgent(response.data);
        console.log("getBlockFromAgent:", response.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function getIntentFromAgent() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/intent/agent/" + selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setIntentFromAgent(response.data);
        console.log("getIntentFromAgent:", response.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function logout() {
    try {
      const response = await axios.post(
        "https://console.zana.ai/keycloak/logout",

        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/keycloak/logout error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/keycloak/logout", response.data);
        sendToLogin();
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showToastNotification(500);
    }
  }

  function sendToLogin() {
    dispatch(setUserName(""));
    setStorageAccessToken("");
    navigate("/login");
  }

  async function getPatientGuideLines() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/patientGuideLinesOutline/agent/" +
          selectedAgent.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        if (Array.isArray(response.data)) {
          setPatientGuideLinesList(response.data);
        } else {
          setPatientGuideLinesList([response.data]);
        }
        console.log("getPatientGuideLines:", response.data);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }
  async function patientGuideLineAdd({
    article,
    module,
    moduleNr,
    articleNr,
    relatedTitle,
    relatedEntity,
  }) {
    try {
      const jsonbody = {
        titleDTO: {
          id: !!article ? article : null,
        },
        entityDTO: {
          id: module,
        },
        module: moduleNr,
        submodule: articleNr,
        active: true,
        relatedTitleDTO: {
          id: !!relatedTitle ? relatedTitle : null,
        },
        relatedEntityDTO: {
          id: !!relatedEntity ? relatedEntity : null,
        },
        agentDTO: {
          id: selectedAgent.id,
        },
      };
      console.log("patientGuideLineAdd", jsonbody);
      const response = await axios.post(
        "https://console.zana.ai/patientGuideLinesOutline/add",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("/patientGuideLinesOutline/add error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("/patientGuideLinesOutline/add", response.data);
        getPatientGuideLines();
        toast({
          position: "top-right",
          title: "Content Created",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response.status === 401) sendToLogin();
      console.error("An error occurred:", error);
      showToastNotification(500);
    }
  }

  async function deletePatientGuideline(id) {
    try {
      const response = await axios.delete(
        "https://console.zana.ai/patientGuideLinesOutline/delete/" + id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("deleteEntity error", response.status);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        getPatientGuideLines();
        toast({
          position: "top-right",
          title: "Patient Guideline Deleted",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) sendToLogin();
        console.error(" error", error.response.data, error.response.status);
        showToastNotification(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
        showToastNotification();
      }
    }
  }

  async function patientGuideLineUpdate({
    id,
    article,
    module,
    moduleNr,
    articleNr,
    relatedTitle,
    relatedEntity,
  }) {
    try {
      const jsonbody = {
        id,
        titleDTO: {
          id: !!article ? article : null,
        },
        entityDTO: {
          id: module,
        },
        module: moduleNr,
        submodule: articleNr,
        active: true,
        relatedTitleDTO: {
          id: !!relatedTitle ? relatedTitle : null,
        },
        relatedEntityDTO: {
          id: !!relatedEntity ? relatedEntity : null,
        },
        agentDTO: {
          id: selectedAgent.id,
        },
      };
      console.log("patientGuidelineContentUpdate", jsonbody);
      const response = await axios.put(
        "https://console.zana.ai/patientGuideLinesOutline/update",
        jsonbody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (![200, 201].includes(response.status)) {
        // handle error
        console.log("content/update error", response.data);
        showToastNotification(response.status);

        return;
      }
      if (response.status === 200) {
        console.log("patientGuideLinesOutline/update", response.data);
        getPatientGuideLines();
        toast({
          position: "top-right",
          title: "Content Updated",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response.status === 401) sendToLogin();
      console.error("An error occurred:", error);
      showToastNotification(500);
    }
  }

  useEffect(() => {
    if (
      !intents ||
      intents.length === 0 ||
      !!intents.find((intent) => intent.utterances)
    )
      return;

    fetchUtterances();
  }, [intents]);

  return {
    loading,
    nodeTypeList,
    updateNode,
    addBlock,
    updateBlock,
    addNode,
    findPath,
    addPath,
    updatePath,
    deletePath,
    deleteNode,
    deleteBlock,
    getBrainProtocol,
    addFacts,
    getBrainFactsExternal,
    deleteFacts,
    deleteFactsList,
    getNodeTypeList,
    getBrainRulesActionGroup,
    fetchIntents,
    getAgents,
    getBrainFacts,
    conditionalPathAdd,
    findConditionalPath,
    deleteConditionalPath,
    convertToExpression,
    addCanvas,
    deleteCanvas,
    getCanvasFlow,
    getPathTopics,
    getBlockTopics,
    getConditionalPathTopics,
    getNodeTopics,
    convertToNode,
    parseLogicEquation,
    addTopics,
    updateTopics,
    deleteTopics,
    getActionsType,
    deleteAction,
    getActionsList,
    addActions,
    getTopicsCanvas,
    intentPredict,
    saveIntent,
    deleteIntent,
    deleteUtterances,
    fetchUtterances,
    nodeImport,
    canvasImport,
    createUser,
    updateCanvas,
    intentImport,
    getIntentsAgent,
    exportIntentList,
    getUserList,
    updateUser,
    deleteUser,
    notificationRuleAdd,
    getNotificatonOptions,
    getRedirectOptions,
    getNotifyOptions,
    getNotificationRuleDetails,
    notificationRuleUpdate,
    deleteNotificationRule,
    getEntitiesList,
    entityAdd,
    entityUpdate,
    deleteEntity,
    entityImport,
    getContentList,
    entityContentAdd,
    getContentFromEntities,
    entityContentUpdate,
    deleteEntityContent,
    getBlockFromAgent,
    getIntentFromAgent,
    logout,
    sendToLogin,
    getPatientGuideLines,
    patientGuideLineAdd,
    deletePatientGuideline,
    patientGuideLineUpdate,
    contentList,
    blockTopicsList,
    blockTopicsTotalList,
    nodeTopicsList,
    canvasFlowList,
    pathTopicsList,
    conditionalPathTopicsList,
    rulesActionGroupList,
    variableList,
    protocolList,
    agents,
    intents,
    factList,
    actionList,
    actionTypeList,
    topicsCanvasList,
    intentListAgent,
    userList,
    notificatonOptionsList,
    redirectOptionsList,
    notifyOptionsList,
    notificationRuleDetailsList,
    entitiesList,
    contentFromEntities,
    blockFromAgent,
    intentFromAgent,
    patientGuideLinesList,
  };
}

import { createIcon } from "@chakra-ui/icons";

const ExternalVarIcon = createIcon({
  displayName: "ExternalVarIcon",
  viewBox: "0 0 24 24",

  path: (
    <>
      <path
        d="M8.5 18.97H8C4 18.97 2 17.97 2 12.97V7.96997C2 3.96997 4 1.96997 8 1.96997H16C20 1.96997 22 3.96997 22 7.96997V12.97C22 16.97 20 18.97 16 18.97H15.5C15.19 18.97 14.89 19.12 14.7 19.37L13.2 21.37C12.54 22.25 11.46 22.25 10.8 21.37L9.29999 19.37C9.13999 19.15 8.78 18.97 8.5 18.97Z"
        stroke="#62778ad9"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        cursor="pointer"
      />
      <path
        d="M8 8.69995L6 10.7L8 12.7"
        stroke="#62778ad9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        cursor="pointer"
      />
      <path
        d="M16 8.69995L18 10.7L16 12.7"
        stroke="#62778ad9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        cursor="pointer"
      />
      <path
        d="M13 8.37012L11 13.0302"
        stroke="#62778ad9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        cursor="pointer"
      />
    </>
  ),
});

export default ExternalVarIcon;

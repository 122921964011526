import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Card,
  CardHeader,
  Heading,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import EntityForm from "./EntityForm";
import MenuDotsIcon from "./images/CustomIcons/MenuDotsIcon";
import useNetworkingHook from "./hooks/useNetworkingHook";
import PatientGuidelineForm from "./PatientGuidelineForm";
export const PatientGuideline = () => {
  const {
    getPatientGuideLines,
    getEntitiesList,
    patientGuideLineUpdate,
    patientGuideLineAdd,
    deletePatientGuideline,
    patientGuideLinesList,
    entitiesList,
  } = useNetworkingHook();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGuideline, setSelectedGuideline] = useState(null);

  useEffect(() => {
    getPatientGuideLines();
    getEntitiesList();
  }, []);

  const handleEdit = (guideline) => {
    setSelectedGuideline(guideline);
    onOpen();
  };

  const handleSubmit = async (data) => {
    if (selectedGuideline) {
      await patientGuideLineUpdate(data);
    } else {
      await patientGuideLineAdd(data);
    }
    setSelectedGuideline(null);
    onClose();
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          colorScheme="blue"
          onClick={() => {
            setSelectedGuideline(null);
            onOpen();
          }}
          mb={10}
        >
          Add Patient Guideline
        </Button>
      </div>
      <Card>
        <CardHeader>
          <Heading size="md"> Patient Guidelines List </Heading>
        </CardHeader>
        <CardBody>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Module</Th>
                <Th>Article</Th>
                <Th>Module Nr</Th>
                <Th>Article Nr</Th>
                <Th>Related Module</Th>
                <Th>Related Article</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {patientGuideLinesList.map((guideline, index) => (
                <Tr key={index}>
                  <Td>{guideline.entity.name}</Td>
                  <Td>{guideline.title.title}</Td>
                  <Td>{guideline.module}</Td>
                  <Td>{guideline.submodule}</Td>
                  <Td>{guideline.relatedTitle.title}</Td>
                  <Td>{guideline.relatedEntity.name}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MenuDotsIcon />}
                        variant="none"
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={() => handleEdit(guideline)}
                        >
                          Edit
                        </MenuItem>

                        <MenuItem
                          icon={<DeleteIcon color="red" />}
                          onClick={() => deletePatientGuideline(guideline.id)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <PatientGuidelineForm
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        initialData={selectedGuideline || {}}
        entitiesList={entitiesList}
      />
    </div>
  );
};

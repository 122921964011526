import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  CloseButton,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import ConditionNodeType from "../../models/ConditionNodeType";
import OperatorSelector from "./ButtonRadio";
import useNetworkingHook from "../hooks/useNetworkingHook";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  CopyIcon,
  DeleteIcon,
  ExternalLinkIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export default function ConditionalComponent({
  text,
  setText,
  deleteNode,
  node,
  totalCondition,
  setTotalCondition,
  onSelectFact,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { convertToExpression } = useNetworkingHook();
  const prevTotalCondition = useRef(totalCondition);
  const toast = useToast();

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    toast({
      position: "top-right",
      title: "Copied.",
      status: "success",
      duration: 800,
      isClosable: true,
    });
    // Reset the "Copied" state after a brief delay
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  function addNewCondition(
    operator,
    selectedVariable,
    activeCondition,
    activeVar2,
    targetId
  ) {
    if (!selectedVariable || !activeVar2 || !activeCondition) {
      return;
    }

    const newCondition = new ConditionNodeType(activeCondition, {
      variable: selectedVariable,
      value: activeVar2,
    });

    const newRoot = addConditionRecursive(
      operator,
      targetId,
      newCondition,
      totalCondition
    );

    setTotalCondition(newRoot);
  }

  function addConditionRecursive(
    operator,
    targetId,
    newCondition,
    currentNode
  ) {
    console.log("addConditionRecursive currentNode", currentNode);

    if (!totalCondition?.operator) {
      console.log("Empty start Condition");
      return newCondition;
    }

    if (!targetId) {
      console.log("no target Id");
      const andCondition = new ConditionNodeType(operator);
      andCondition.left = totalCondition;
      andCondition.right = newCondition;

      console.log("AND condition", andCondition);
      return andCondition;
    }

    console.log("targetId", targetId);

    if (currentNode.id == targetId) {
      console.log("Found target node");
      const newSubTree = new ConditionNodeType(operator);
      newSubTree.left = currentNode;
      newSubTree.right = newCondition;
      return newSubTree;
    }

    if (!!currentNode.left) {
      console.log("left");
      currentNode.left = addConditionRecursive(
        operator,
        targetId,
        newCondition,
        currentNode.left
      );
    }

    if (!!currentNode.right) {
      console.log("right");
      currentNode.right = addConditionRecursive(
        operator,
        targetId,
        newCondition,
        currentNode.right
      );
    }

    return currentNode;
  }

  /* Remove Condition */
  function removeCondition(targetId) {
    if (!targetId) return;
    const newRoot = removeConditionRecursive(
      targetId,

      totalCondition
    );

    setTotalCondition(newRoot);
  }

  function removeConditionRecursive(
    targetId,
    currentNode // gjithmon lere bosh kete
  ) {
    console.log("removeConditionRecursive currentNode", currentNode);

    console.log("targetId", targetId);

    if (currentNode.left?.id == targetId) {
      currentNode = currentNode.right;
    }

    if (currentNode.right?.id == targetId) {
      currentNode = currentNode.left;
    }

    let left, right;

    if (!!currentNode.left) {
      console.log("left");

      left = removeConditionRecursive(targetId, currentNode.left);
    }

    if (!!currentNode.right) {
      console.log("right");

      right = removeConditionRecursive(targetId, currentNode.right);
    }

    return {
      ...currentNode,
      left,
      right,
    };
  }

  return (
    <div>
      <div style={{ marginBottom: "30px" }}>
        <Box>
          <CloseButton
            onClick={(e) => deleteNode(node.id)}
            style={{
              position: "absolute",
              justifyContent: "space-around",
              marginTop: "10px",
              right: "35px",
            }}
          />
          <Accordion allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <FormLabel htmlFor="username">Condition</FormLabel>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Input
                    size="md"
                    value={text ?? "New Condition"}
                    onChange={(e) => setText(e.target.value)}
                  />
                  <CopyToClipboard text={text} onCopy={handleCopy}>
                    <CopyIcon ml={5} cursor={"pointer"}>
                      {isCopied ? "Copied!" : "Copy to Clipboard"}
                    </CopyIcon>
                  </CopyToClipboard>
                </div>
                <RenderCondition
                  condition={totalCondition}
                  onChange={(newCondition) => setTotalCondition(newCondition)}
                  onDelete={removeCondition}
                  addNewCondition={addNewCondition}
                  onSelectFact={onSelectFact}
                />
                <div style={{ marginTop: "15px" }}>
                  <Popover
                    size="xl"
                    onClose={onClose}
                    onOpen={onOpen}
                    isOpen={isOpen}
                  >
                    <PopoverTrigger>
                      <Button>Add conditional group</Button>
                    </PopoverTrigger>
                    <PopoverComponent
                      showLogicOperator={true}
                      onChange={addNewCondition}
                      onClose={onClose}
                      onSelectFact={onSelectFact}
                    />
                  </Popover>
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </div>
    </div>
  );
}

function PopoverComponent({
  showLogicOperator,
  targetId,
  intialValues,
  onChange,
  onSelectFact,
  onClose,
}) {
  const navigate = useNavigate();

  const [activeCondition, setActiveCondition] = useState(
    intialValues?.operator ?? ""
  );
  const [selectedVariable, setSelectedVariable] = useState(
    intialValues?.arguments?.variable ?? ""
  );
  const [activeVar2, setActiveVar2] = useState(
    intialValues?.arguments?.value ?? ""
  );
  const [operator, setOperator] = useState("AND");
  const { variableList, getBrainFacts } = useNetworkingHook();

  useEffect(() => {
    getBrainFacts();
  }, []);

  return (
    <PopoverContent>
      <PopoverArrow />
      <PopoverHeader>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            rightIcon={<ExternalLinkIcon mx="2px" />}
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              navigate("/external-facts");
            }}
          >
            Add or delete external variables
          </Button>
        </div>
      </PopoverHeader>
      <div style={{ maxHeight: "50%" }}>
        {" "}
        {showLogicOperator && (
          <OperatorSelector active={operator} setActive={setOperator} />
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Select
          placeholder="Select Variable"
          value={selectedVariable}
          onChange={(e) => {
            setSelectedVariable(e.target.value);
            onSelectFact(
              variableList.find((variable) => variable.name == e.target.value)
                ?.id
            );
          }}
        >
          {variableList.map((key, index) => (
            <option key={index} value={key.name} disabled={key.type === ""}>
              {key.name === "----"
                ? "----ExternalFacts----"
                : `${key.name} (${key.type})`}
            </option>
          ))}
        </Select>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Select
          placeholder="Condition"
          value={activeCondition}
          onChange={(e) => setActiveCondition(e.target.value)}
        >
          <option>Is</option>
          <option>Is not</option>
          <option>Is greater than</option>
          <option>Is greater than or equal</option>
          <option>Is less than</option>
          <option>Is less than or equal</option>
          <option>Contains</option>
          <option>Does not contain</option>
          <option>Starts with</option>
          <option>Ends with</option>
          <option>Has any value</option>
          <option>Is empty</option>
        </Select>
      </div>
      <div style={{ marginTop: "10px" }}>
        <PopoverBody>
          <Input
            placeholder="Second Variable"
            value={activeVar2}
            onChange={(e) => setActiveVar2(e.target.value)}
          />
        </PopoverBody>
      </div>
      <PopoverFooter display="flex" justifyContent="flex-end">
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>

          <Button
            colorScheme="blue"
            onClick={() => {
              onChange(
                operator,
                selectedVariable,
                activeCondition,
                activeVar2,
                targetId
              );
              onClose();
              setSelectedVariable("");
              setActiveCondition("");
              setActiveVar2("");
            }}
          >
            Apply
          </Button>
        </ButtonGroup>
      </PopoverFooter>
    </PopoverContent>
  );
}

function RenderCondition({
  parent,
  condition,
  onChange,
  onDelete,
  onSelectFact,
  addNewCondition,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHovering, setIsHovering] = useState(false);
  const {
    isOpen: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose,
  } = useDisclosure();
  if (Object.keys(condition ?? {}).length == 0) return <div />;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <div
      key={condition.id}
      style={{
        border:
          !!parent && parent.operator == condition.operator
            ? null
            : !!condition.left && !!condition.right
            ? "1px solid gray"
            : null,
        borderRadius: 10,
        padding:
          !!parent && parent.operator == condition.operator
            ? null
            : !!condition.left && !!condition.right
            ? "10px"
            : null,
        margin: !condition.arguments ? "10px 0px" : "5px 0px",
        backgroundColor: !!condition.arguments ? "#e2f0fd" : null,
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {!!condition.left && (
        <RenderCondition
          condition={condition.left}
          parent={condition}
          onChange={(newCondition) => {
            onChange({
              ...condition,
              left: newCondition,
            });
          }}
          onDelete={onDelete}
          addNewCondition={addNewCondition}
          onSelectFact={onSelectFact}
        />
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          wordBreak: "break-all",
          padding: "10px",
        }}
      >
        <Popover size="xl" onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
          <PopoverTrigger>
            <div>
              <p>
                {(condition.arguments?.variable ?? "") +
                  " " +
                  condition.operator +
                  " " +
                  (condition.arguments?.value ?? "")}
              </p>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            {!condition.arguments ? (
              <OperatorSelector
                active={condition.operator}
                setActive={(newOperator) => {
                  onChange({
                    ...condition,
                    operator: newOperator,
                  });
                }}
              />
            ) : (
              <PopoverComponent
                onClose={onClose}
                intialValues={condition}
                onChange={(_, var1, newOperator, var2) =>
                  onChange({
                    ...condition,
                    operator: newOperator,
                    arguments: {
                      ...condition.arguments,
                      variable: var1,
                      value: var2,
                    },
                  })
                }
                onSelectFact={onSelectFact}
              />
            )}
          </PopoverContent>
        </Popover>

        {/* Add Condition Button */}
        {!!condition.arguments && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Popover
                size="xl"
                onClose={onNewClose}
                onOpen={onNewOpen}
                isOpen={isNewOpen}
              >
                <PopoverTrigger>
                  <SmallAddIcon
                    boxSize={5}
                    mr="5px"
                    cursor={"pointer"}
                    opacity={isHovering ? 1 : 0} // Show icon when hovering
                  />
                </PopoverTrigger>
                <PopoverComponent
                  showLogicOperator={true}
                  targetId={condition.id}
                  onChange={addNewCondition}
                  onSelectFact={onSelectFact}
                  onClose={onNewClose}
                />
              </Popover>
            </div>
            <div
              style={{
                opacity: isHovering ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
              }}
            >
              {" "}
              <DeleteIcon
                size="sm"
                cursor={"pointer"}
                color="red"
                opacity={isHovering ? 1 : 0} // Show icon when hovering
                onClick={() => onDelete(condition.id)}
              >
                Remove Condition
              </DeleteIcon>
            </div>
          </div>
        )}
      </div>

      {!!condition.right && (
        <RenderCondition
          condition={condition.right}
          parent={condition}
          onChange={(newCondition) => {
            onChange({
              ...condition,
              right: newCondition,
            });
          }}
          onDelete={onDelete}
          addNewCondition={addNewCondition}
          onSelectFact={onSelectFact}
        />
      )}
      {/*  Add condition button after every group
      {!condition.arguments && parent?.operator != condition.operator && (
        <div style={{ marginTop: "15px" }}>
          <Popover size="xl" onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
            <PopoverTrigger>
              <Button>Add conditional </Button>
            </PopoverTrigger>
            <PopoverComponent
              showLogicOperator={true}
              onChange={addNewCondition}
              onSelectFact={onSelectFact}
              onClose={onClose}
            />
          </Popover>
        </div>
      )} */}
    </div>
  );
}

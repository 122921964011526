import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CloseButton,
  FormLabel,
  Input,
  // Select,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  PopoverCloseButton,
  useDisclosure,
  ButtonGroup,
  Link,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { setTopicId } from "../../components/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import useNetworkingHook from "../hooks/useNetworkingHook";
import { GoToBlock } from "../images/CustomIcons/GoToBlock";
import { GoToNode } from "../images/CustomIcons/GoToNode";
import { GoToComponent } from "../images/CustomIcons/GoToComponent";
import axios from "axios";
import { getStorageAccessToken } from "../LocalStorage/mainDb";
import Select from "react-dropdown-select";

export default function ButtonComponent({
  text,
  setText,
  triggerIntent,
  setTriggerIntent,
  deleteNode,
  node,
  intents,
  action,
  setAction,
}) {
  const [selectedActionNode, setSelectedActionNode] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAction, setSelectedAction] = useState(null);
  const [nodeTopicsList, setNodeTopicsList] = useState([]);
  const [blockTopicsTotalList, setBlockTopicsTotalList] = useState([]);
  const dispatch = useDispatch();
  const { selectedAgent } = useSelector((state) => state.slice);

  const {
    getActionsType,
    getTopicsCanvas,
    getNodeTopics,
    getBlockTopics,
    addActions,
    getActionsList,
    deleteAction,
    getBrainRulesActionGroup,
    rulesActionGroupList,
    protocolList,
    actionTypeList,
    topicsCanvasList,
  } = useNetworkingHook();

  const [selectedRuleMethod, setSelectedRuleMethod] = useState(null);
  const filteredIntents = intents.filter((intent) =>
    intent.name?.toLowerCase().includes(searchText?.toLowerCase())
  );
  const inputRef = useRef(null);
  const [selectedRuleMethodGroupId, setSelectedRuleMethodGroupId] =
    useState("");
  const [ruleActionGroupById, setRuleActionGroupById] = useState([]);
  const [filteredRuleActionArguments, setFilteredRuleActionArguments] =
    useState([]);
  const [inputValues, setInputValues] = useState({});

  const handleIntentSelect = (intent) => {
    setTriggerIntent(intent);
    // if (!text) {
    //   setText(intent.name);
    // }
    setIsPopoverOpen(false); // Close the popover
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchText]);

  useEffect(() => {
    getActionsType();
    getTopicsCanvas();
    getNodeTopics();
    getBlockTopics();
    getActionsList();
    getBrainRulesActionGroup();
  }, []);

  useEffect(() => {
    console.log("nodeTopicsList", nodeTopicsList);
  }, [nodeTopicsList]);

  useEffect(() => {
    console.log("selectedTopic", selectedTopic);
    if (!selectedTopic) {
      setNodeTopicsList([]);
      return;
    }
    getNodesByTopic();
  }, [selectedTopic]);

  async function getNodesByTopic() {
    const response = await axios.get(
      "https://console.zana.ai/node/topics/" + selectedTopic.id,
      {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const nodeTopics = response.data;
      setNodeTopicsList(nodeTopics);
    }
  }

  useEffect(() => {
    console.log("selectedTopic", selectedTopic);
    if (!selectedTopic) {
      setNodeTopicsList([]);
      return;
    }
    getBlockByTopic();
  }, [selectedTopic]);

  useEffect(() => {
    if (!selectedRuleMethod) return;
    getRuleActionGroupById();
  }, [selectedRuleMethod]);

  useEffect(() => {
    if (!selectedRuleMethodGroupId) return;
    getRuleActionArguments();
  }, [selectedRuleMethodGroupId]);

  async function getBlockByTopic() {
    const response = await axios.get(
      "https://console.zana.ai/block/topics/" + selectedTopic.id,
      {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const blockTopics = response.data;
      setBlockTopicsTotalList(blockTopics);
    }
  }
  async function getRuleActionArguments() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/ruleActionArguments/ruleAction/" +
          selectedRuleMethodGroupId,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const filteredData = response.data.filter((rule) => rule.userInput);

        setFilteredRuleActionArguments(filteredData);
        console.log("getRuleActionArguments", filteredData);
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getRuleActionArguments error",
          response.data,
          response.status
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
      }
    }
  }

  async function getRuleActionGroupById() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/ruleAction/group/" + selectedRuleMethod.id,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("getRuleActionGroupById", response.data);
        const parsetData = response.data.map((rule) => ({
          id: rule.id,
          name: rule.name,
        }));
        console.log("ruleActionGroupById", parsetData);
        setRuleActionGroupById(parsetData);
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getRuleActionGroupById error",
          response.data,
          response.status
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
      }
    }
  }

  const handleCheckboxChange = (e, id) => {
    const { checked } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleInputChange = (e, ruleName) => {
    const { value } = e.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [ruleName]: value,
    }));
  };

  const handleMenuItemClick = (action) => {
    setSelectedAction(action);
    if (!!action) onOpen();
    else onClose();
  };

  return (
    <div
      style={{
        backgroundColor: "#F1F1F1",
        padding: "20px",
        borderRadius: "15px",
        marginBottom: "25px",
      }}
    >
      <div style={{ marginBottom: "30px" }}>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormLabel htmlFor="username">Button Node</FormLabel>
            <CloseButton onClick={(e) => deleteNode(node.id)} style={{}} />
          </div>
          <Input
            id={node?.id}
            placeholder="Enter button label"
            size="md"
            value={triggerIntent?.label ?? ""}
            onChange={(e) => setText(e.target.value)}
          />
        </Box>
      </div>

      <Accordion allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton
              display="flex"
              alignItems="center"
              style={{ padding: "10px 0" }}
            >
              <Box as="span" flex="1" textAlign="left">
                Attach Intent
              </Box>
              <AccordionIcon color="#62778a" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Popover
              isOpen={isPopoverOpen}
              onClose={() => setIsPopoverOpen(false)}
            >
              <PopoverTrigger>
                <Button
                  onClick={() => setIsPopoverOpen(true)}
                  rightIcon={<span>&#x25BE;</span>}
                  style={{ width: "100%" }}
                  variant="outline"
                >
                  {triggerIntent?.name ?? "Select option"}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />

                <PopoverHeader>
                  <Input
                    placeholder="Search intents"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </PopoverHeader>
                <PopoverBody>
                  {selectedAgent &&
                    filteredIntents
                      .filter((intent) => selectedAgent.id === intent.agent.id)
                      .map((intent) => (
                        <Button
                          key={intent.id}
                          onClick={() => handleIntentSelect(intent)}
                          size="sm"
                          variant="ghost"
                        >
                          {intent.name}
                        </Button>
                      ))}
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
            <AccordionButton
              display="flex"
              alignItems="center"
              style={{
                cursor: "default",
                backgroundColor: "#F1F1F1",
                padding: "10px 0",
              }}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                style={{ fontWeight: "500" }}
              >
                Actions
              </Box>

              <Menu isLazy>
                <MenuButton
                  as={Button}
                  style={{ margin: "-12px", padding: "0px" }}
                >
                  <Button variant="ghost">
                    <AddIcon fontSize={12} color="#62778a" />
                  </Button>
                </MenuButton>
                <MenuList>
                  {actionTypeList.map((actions, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleMenuItemClick(actions)}
                    >
                      {actions.type === "Go to Block" && <GoToBlock />}
                      {actions.type === "Go to Component" && <GoToComponent />}
                      {actions.type === "Go to Node" && <GoToNode />}
                      <span style={{ marginLeft: "8px" }}>{actions.type}</span>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </AccordionButton>
            <PopoverTrigger>
              <div></div>
            </PopoverTrigger>
            {!!selectedAction && (
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton onClick={() => handleMenuItemClick(null)} />
                <PopoverHeader>{selectedAction.type} </PopoverHeader>

                {selectedAction.type === "Go to Node" ? (
                  <PopoverBody>
                    <Select
                      placeholder="Select Topic"
                      options={topicsCanvasList.map((topic) => ({
                        label: topic.name,
                        value: topic.id,
                      }))}
                      onChange={(selectedOptions) => {
                        const selectedOption = selectedOptions[0];
                        const selectedTopic = topicsCanvasList.find(
                          (topic) => topic.id === selectedOption.value
                        );

                        console.log("selectedTopic", selectedTopic);

                        setSelectedTopic(selectedTopic);

                        if (selectedTopic) {
                          getNodeTopics(selectedTopic.id);
                        }
                      }}
                      style={{ borderRadius: "5px" }}
                    />
                    {!!selectedTopic && (
                      <Select
                        placeholder="Select Node"
                        options={nodeTopicsList.map((node) => ({
                          label: node.nodeName,
                          value: node.id,
                        }))}
                        onChange={(selectedOptions) => {
                          const selectedOption = selectedOptions[0];
                          setSelectedActionNode(
                            nodeTopicsList.find(
                              (node) => node.id === selectedOption.value
                            )
                          );
                        }}
                        style={{ marginTop: "15px", borderRadius: "5px" }}
                      />
                    )}
                    {!!selectedTopic && (
                      <div style={{ marginTop: "10px" }}>
                        <Select
                          placeholder="Select RuleMethod"
                          options={rulesActionGroupList.map((rule) => ({
                            label: rule.groupName,
                            value: rule.id,
                          }))}
                          onChange={(selectedOptions) => {
                            const selectedOption = selectedOptions[0];
                            setSelectedRuleMethod(
                              rulesActionGroupList.find(
                                (rule) => rule.id === selectedOption.value
                              )
                            );
                          }}
                          value={
                            selectedRuleMethod
                              ? [
                                  {
                                    label: selectedRuleMethod.groupName,
                                    value: selectedRuleMethod.id,
                                  },
                                ]
                              : []
                          }
                          style={{ borderRadius: "5px" }}
                        />
                      </div>
                    )}
                    {!!selectedRuleMethod ? (
                      <Select
                        placeholder="Select RuleMethod Group"
                        options={ruleActionGroupById.map((rule) => ({
                          label: rule.name,
                          value: rule.id,
                        }))}
                        onChange={(selectedOptions) => {
                          const selectedOption = selectedOptions[0];
                          setSelectedRuleMethodGroupId(selectedOption.value);
                        }}
                        value={
                          selectedRuleMethodGroupId
                            ? [
                                {
                                  label: selectedRuleMethodGroupId,
                                  value: selectedRuleMethodGroupId,
                                },
                              ]
                            : []
                        }
                        style={{ marginTop: "15px", borderRadius: "5px" }}
                      />
                    ) : (
                      ""
                    )}
                    {selectedRuleMethodGroupId &&
                      filteredRuleActionArguments.map((rule) => (
                        <div key={rule.id}>
                          <FormLabel>{rule.name} </FormLabel>
                          {rule.type === "Boolean" ? (
                            <Checkbox
                              defaultChecked={inputValues[rule.id]} // If rule.id is present in inputValues, checkbox will be checked
                              onChange={(e) => handleCheckboxChange(e, rule.id)}
                            >
                              {rule.name}
                            </Checkbox>
                          ) : (
                            <Input
                              placeholder={`Enter ${rule.name}`}
                              value={inputValues[rule.id] || ""}
                              onChange={(e) => handleInputChange(e, rule.id)}
                            />
                          )}
                        </div>
                      ))}
                    <ButtonGroup
                      display="flex"
                      justifyContent="flex-end"
                      mt="10px"
                    >
                      <Button variant="outline" onClick={onClose}>
                        Cancel
                      </Button>

                      <Button
                        colorScheme="blue"
                        onClick={async () => {
                          const ruleActionArgsObject = {};
                          filteredRuleActionArguments.forEach((rule) => {
                            ruleActionArgsObject[rule.name] =
                              inputValues[rule.id];
                          });
                          let id = await addActions(
                            node,
                            selectedActionNode,
                            selectedAction,
                            selectedRuleMethod,
                            selectedRuleMethodGroupId,
                            ruleActionArgsObject
                          );
                          console.log("im here", id);
                          if (!id) return;
                          setAction({
                            id: id,
                            action: selectedAction,
                            target: selectedActionNode,
                          });
                          setSelectedRuleMethod("");
                          setSelectedRuleMethodGroupId("");
                          setRuleActionGroupById([]);
                          setFilteredRuleActionArguments([]);

                          onClose();
                        }}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </PopoverBody>
                ) : selectedAction.type === "Go to Block" ? (
                  <PopoverBody>
                    <Select
                      variant="outline"
                      placeholder="Select Topic"
                      mb="10px"
                      borderRadius="5px"
                      size="sm"
                      value={selectedTopic?.name}
                      onChange={(e) => {
                        const selectedTopic = topicsCanvasList.find(
                          (topic) => topic.name === e.target.value
                        );
                        setSelectedTopic(selectedTopic);

                        if (selectedTopic) {
                          getBlockTopics(selectedTopic.id);
                        }
                      }}
                    >
                      {topicsCanvasList.map((topic) => (
                        <option key={topic.id} value={topic.name}>
                          {topic.name}
                        </option>
                      ))}
                    </Select>

                    {!!selectedTopic && (
                      <Select
                        variant="outline"
                        placeholder="Select Block"
                        borderRadius="5px"
                        size="sm"
                        value={selectedActionNode?.context}
                        onChange={(e) =>
                          setSelectedActionNode(
                            blockTopicsTotalList.find(
                              (block) => block.context == e.target.value
                            )
                          )
                        }
                      >
                        {blockTopicsTotalList.map((block) => (
                          <option key={block.id} value={block.context}>
                            {block.context}
                          </option>
                        ))}
                      </Select>
                    )}
                    <ButtonGroup
                      display="flex"
                      justifyContent="flex-end"
                      mt="10px"
                    >
                      <Button variant="outline" onClick={onClose}>
                        Cancel
                      </Button>

                      <Button
                        colorScheme="blue"
                        onClick={async () => {
                          let id = await addActions(
                            node,
                            selectedActionNode,
                            selectedAction
                          );
                          if (!id) return;
                          setAction({
                            id: id,
                            action: selectedAction,
                            target: selectedActionNode,
                          });
                          onClose();
                        }}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </PopoverBody>
                ) : (
                  <PopoverBody></PopoverBody>
                )}
                {/* {selectedAction.type === "Go to Node" ? (
                <PopoverFooter>This is the footer</PopoverFooter>
              ) : selectedAction.type === "Go to Block " ? (
                <PopoverFooter>This is the footer</PopoverFooter>
              ) : (
                <PopoverFooter>This is the footer</PopoverFooter>
              )} */}
              </PopoverContent>
            )}
          </Popover>
          {!!action ? (
            <Box as="span" flex="1" textAlign="left">
              <div
                style={{
                  display: "flex",
                  padding: "10px 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <GoToBlock />
                  <div style={{ marginLeft: "5px" }}>
                    <Link
                      onClick={async () => {
                        console.log(
                          "topicID",
                          action.target.block?.topics.id ??
                            action.target.node?.topics.id
                        );
                        dispatch(
                          setTopicId(
                            action.target.block?.topics.id ??
                              action.target.node?.topics.id
                          )
                        );
                      }}
                    >
                      {!!action.target.nodeName
                        ? action.target.nodeName
                        : action.target.blockName}
                    </Link>
                  </div>
                </div>
                <Button
                  variant="ghost"
                  style={{ padding: "0", margin: "-10px" }}
                  onClick={async () => {
                    await deleteAction(action.id);
                    setAction();
                  }}
                >
                  <MinusIcon fontSize={12} color="#62778a" />
                </Button>
              </div>
            </Box>
          ) : null}
        </AccordionItem>
      </Accordion>
    </div>
  );
}

import { Handle, Position } from "reactflow";

function ConditionalNode({ data, isConnectable }) {
  return (
    <div className="text-updater-node">
      <div>
        <label htmlFor="text"></label>
        <div className="text-input">
          {!!data.label ? data.label : "Add condition label"}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
}

export default ConditionalNode;

import React from "react";
import { Box, CloseButton, FormLabel, Input } from "@chakra-ui/react";

export default function AudioComponent({
  node,
  text,
  setText,
  audioName,
  setAudioName,
  instructions,
  setInstructions,
  url,
  setUrl,
  time,
  setTime,
  deleteNode,
}) {
  return (
    <div
      style={{
        backgroundColor: "#F1F1F1",
        padding: "20px",
        borderRadius: "15px",
        marginBottom: "25px",
      }}
    >
      {" "}
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div></div>
          <CloseButton onClick={(e) => deleteNode(node.id)} style={{}} />
        </div>
        <FormLabel htmlFor="audioname">Audio Name</FormLabel>
        <Input
          id={node.id}
          placeholder="Audio Name"
          size="md"
          mb="10px"
          value={audioName ?? ""}
          setAudioName={audioName}
          onChange={(e) => setAudioName(e.target.value)}
        />
        <FormLabel htmlFor="instructions">Instructions</FormLabel>
        <Input
          id={node.id}
          placeholder="Instructions"
          size="md"
          mb="10px"
          value={instructions ?? ""}
          setInstructions={instructions}
          onChange={(e) => setInstructions(e.target.value)}
        />
        <FormLabel htmlFor="url">URL</FormLabel>
        <Input
          id={node.id}
          placeholder="URL"
          size="md"
          mb="10px"
          value={url ?? ""}
          setUrl={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <FormLabel htmlFor="type">Type</FormLabel>
        <Input
          id={node.id}
          placeholder="Type"
          size="md"
          mb="10px"
          value={text ?? ""}
          setText={text}
          onChange={(e) => setText(e.target.value)}
        />
        <FormLabel htmlFor="type">Time</FormLabel>
        <Input
          id={node.id}
          placeholder="Time"
          size="md"
          mb="10px"
          value={time ?? ""}
          setTime={time}
          onChange={(e) => setTime(e.target.value)}
        />
      </Box>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Divider, Input, Text, useDisclosure } from "@chakra-ui/react";
import { getStorageAccessToken } from "../LocalStorage/mainDb";
import { MinusIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useSelector } from "react-redux";
import useNetworkingHook from "../hooks/useNetworkingHook";

export default function CreateIntentUtterances({
  updateIntents,
  buttonName = "Create Intent",
  editIntent,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [intentName, setIntentName] = useState("");
  const [intentLabel, setIntentLabel] = useState("");
  const [utterance, setUtterance] = useState("");
  const [agents, setAgents] = useState([]);
  const { selectedAgent, userRole, userName } = useSelector(
    (state) => state.slice
  );
  const [utteranceList, setUtteranceList] = useState([]);
  const toast = useToast();
  const { saveIntent, deleteIntent, fetchUtterances } = useNetworkingHook();
  const finalRef = React.useRef(null);

  useEffect(() => {
    getAgents();
  }, []);

  useEffect(() => {
    setIntentName(editIntent?.intent ?? "");
    setUtteranceList(editIntent?.utterances ?? []);
    setIntentLabel(editIntent?.label ?? "");
  }, [editIntent]);

  function getAgents() {
    axios
      .get(
        userRole === "admin"
          ? "https://console.zana.ai/agent"
          : "https://console.zana.ai/user/" + userName + "/agents",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        if (resp.status != 200) {
          console.log("error");
          return;
        }
        console.log("agent list", resp.data);
        setAgents(resp.data);
      });
  }

  async function createIntent() {
    if (!intentName && utteranceList.length == 0) {
      toast({
        position: "top-right",
        title: "Error",
        description:
          "Please provide an intent name and at least one utterance.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (utteranceList.length == 0) {
      toast({
        position: "top-right",
        title: "Error",
        description: "Please press enter to add the utterance.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!selectedAgent) {
      toast({
        position: "top-right",
        title: "Error",
        description: "Please select an Agent",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    await saveIntent({
      id: editIntent?.id ?? undefined,
      intent: intentName,
      label: intentLabel,
      utterances: utteranceList,
      agentId: selectedAgent.id,
    });

    !!updateIntents && updateIntents();
    setIntentName("");
    setUtterance("");
    setIntentLabel("");
    setUtteranceList([]);
    onClose();
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault();

      if (utterance.trim() === "") {
        return;
      }

      const utterances = utterance
        .split(",")
        .map((text) => text.trim())
        .filter((text) => text !== ""); // Remove empty strings

      setUtteranceList((prev) => [...prev, ...utterances]);
      setUtterance("");
    }
  }

  function handleDeleteUtterance(index) {
    setUtteranceList((prev) => prev.filter((_, i) => i !== index));
  }

  return (
    <>
      <Button colorScheme="gray" onClick={onOpen}>
        {buttonName}
      </Button>

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editIntent ? " Edit Intent " : buttonName}</ModalHeader>
          <Divider orientation="horizontal" /> <ModalCloseButton />
          <ModalBody>
            <Text mb="15px">Name</Text>
            <Input
              value={intentName}
              placeholder="Enter intent name"
              size="md"
              onChange={(e) => setIntentName(e.target.value)}
            />
            <div style={{ marginTop: "15px" }}>
              <Text mb="15px">Label</Text>
              <Input
                value={intentLabel}
                placeholder="Enter intent label"
                size="md"
                onChange={(e) => setIntentLabel(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "15px" }}>
              <Text mb="15px">Utterances</Text>
              <Input
                value={utterance}
                placeholder="Add sample phrases separated by commas"
                size="md"
                onChange={(e) => setUtterance(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <Divider style={{ marginTop: "15px" }} />
            </div>
            {utteranceList.map((utterance, index) => {
              return (
                <div style={{ marginTop: "20px" }} key={index}>
                  <Input defaultValue={utterance} />
                  <MinusIcon
                    onClick={() => handleDeleteUtterance(index)}
                    style={{
                      position: "absolute",
                      justifyContent: "space-around",
                      marginTop: "10px",
                      right: "35px",
                    }}
                  />
                </div>
              );
            })}
          </ModalBody>
          <ModalFooter
            style={{
              background: "#F6F6F6",
              marginTop: "15px",
              borderRadius: "10px",
            }}
          >
            {!!editIntent && (
              <Button
                colorScheme="red"
                mr={3}
                onClick={async () => {
                  await deleteIntent(editIntent.id);
                  !!updateIntents && updateIntents();
                  onClose();
                }}
              >
                Delete
              </Button>
            )}
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={createIntent}>
              {!!editIntent ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

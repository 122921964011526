import { Box, Checkbox, CloseButton, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

export default function TextUpdater({
  node,
  value,
  setValue,
  deleteNode,
  isLastNode,
  setIsLastNode,
}) {
  return (
    <div
      style={{
        backgroundColor: "#F1F1F1",
        padding: "20px",
        borderRadius: "15px",
        marginBottom: "25px",
      }}
    >
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormLabel htmlFor="username">Text Node</FormLabel>
          <CloseButton onClick={(e) => deleteNode(node.id)} style={{}} />
        </div>
        <Input
          id={node.id}
          placeholder="Enter text reply, to add variables"
          size="md"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Checkbox
          colorScheme="green"
          pt={10}
          isChecked={isLastNode}
          onChange={(e) => setIsLastNode(e.target.checked)}
        >
          Complete Flow
        </Checkbox>
      </Box>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Card,
  CardBody,
  Select,
} from "@chakra-ui/react";
import useNetworkingHook from "./hooks/useNetworkingHook";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import MenuDotsIcon from "./images/CustomIcons/MenuDotsIcon";
import { MultiSelect } from "chakra-multiselect";
import axios from "axios";
import { getStorageAccessToken } from "./LocalStorage/mainDb";

const UserPage = () => {
  const { createUser, getUserList, updateUser, deleteUser, userList } =
    useNetworkingHook();
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmationOpen,
    onOpen: onDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose,
  } = useDisclosure();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    companyProfile: "",
    enabled: true,
    agents: [],
  });
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [editUserId, setEditUserId] = useState(null);
  const [userDataBeforeUpdate, setUserDataBeforeUpdate] = useState(null);
  const [agentsList, setAgentsList] = useState([]);

  useEffect(() => {
    if (isEditModalOpen) return;
    setFormData({
      username: "",
      email: "",
      companyProfile: "",
      password: "",
      enabled: true,
      agents: [],
    });
  }, [isEditModalOpen]);

  const handleChange = (e) => {
    if (Array.isArray(e)) {
      setFormData((prevData) => ({
        ...prevData,
        agents: e,
      }));
      return;
    }
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, email, password, companyProfile, enabled } = formData;
    createUser(username, email, password, companyProfile, enabled);
    setFormData({
      username: "",
      email: "",
      companyProfile: "",
      password: "",
      enabled: true,
      agents: [],
    });
    onCreateModalClose();
  };

  const handleEdit = () => {
    updateUser({
      id: editUserId,
      username: formData.username,
      email: formData.email,
      companyProfile: formData.companyProfile,
      // password: "",
      accountLocked: userDataBeforeUpdate.accountLocked,
      accountExpired: userDataBeforeUpdate.accountExpired,
      passwordExpired: userDataBeforeUpdate.passwordExpired,
      enabled: formData.enabled,
      agentList: formData.agents.map((a) => ({
        id: a.value,
      })),
    });
    onEditModalClose();
  };

  const handleEditModalOpen = (userId) => {
    const userToEdit = userList.find((user) => user.id === userId);
    if (userToEdit) {
      setFormData({
        id: userId,
        username: userToEdit.username,
        email: userToEdit.email,
        companyProfile: userToEdit.companyProfile,
        // password: "",
        accountLocked: userToEdit.accountLocked,
        accountExpired: userToEdit.accountExpired,
        passwordExpired: userToEdit.passwordExpired,
        enabled: userToEdit.enabled,
      });
      setEditUserId(userId);
      setUserDataBeforeUpdate(userToEdit);
      onEditModalOpen();
    }
  };

  const handleDeleteConfirmationOpen = (userId) => {
    setDeleteUserId(userId);
    onDeleteConfirmationOpen();
  };

  const handleDelete = () => {
    deleteUser(deleteUserId);
    onDeleteConfirmationClose();
  };

  function getAgents() {
    axios
      .get("https://console.zana.ai/agent", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (resp.status !== 200) {
          console.log("error");
          return;
        }
        console.log("Get Agent List", resp.data);
        setAgentsList(resp.data);
      });
  }

  useEffect(() => {
    getUserList();
    getAgents();
  }, []);

  return (
    <>
      <Button onClick={onCreateModalOpen}>Create User</Button>
      <Modal isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create User</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody>
              <FormControl id="username" isRequired>
                <FormLabel>Username</FormLabel>
                <Input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="email" isRequired paddingTop={5}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="companyprofile" paddingTop={5}>
                <FormLabel>Company Profile</FormLabel>
                <Input
                  type="text"
                  name="companyProfile"
                  value={formData.companyProfile}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="password" isRequired paddingTop={5}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl id="enabled">
                <Checkbox
                  name="enabled"
                  isChecked={formData.enabled}
                  onChange={handleChange}
                >
                  Status
                </Checkbox>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button type="submit" colorScheme="blue" mr={3}>
                Create
              </Button>
              <Button onClick={onCreateModalClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="username" isRequired>
              <FormLabel>Username</FormLabel>
              <Input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="email" isRequired paddingTop={5}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="companyprofile" paddingTop={5}>
              <FormLabel>Company Profile</FormLabel>
              <Input
                type="text"
                name="companyProfile"
                value={formData.companyProfile}
                onChange={handleChange}
              />
            </FormControl>
            {/* <FormControl id="password" isRequired paddingTop={5}>
              <FormLabel>Password</FormLabel>
              <Input
                placeholder="Enter new password if you want to change it"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </FormControl> */}
            <FormControl id="agentList">
              <MultiSelect
                options={agentsList.map((agent) => ({
                  value: agent.id.toString(),
                  label: agent.name,
                }))}
                value={formData.agents}
                label="Choose agents"
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="enabled">
              <Checkbox
                name="enabled"
                isChecked={formData.enabled}
                onChange={handleChange}
              >
                Status
              </Checkbox>
            </FormControl>
            <FormControl id="accountLocked">
              <Checkbox
                name="accountLocked"
                isChecked={formData.accountLocked}
                onChange={handleChange}
              >
                Account Locked
              </Checkbox>
            </FormControl>
            <FormControl id="accountExpired">
              <Checkbox
                name="accountExpired"
                isChecked={formData.accountExpired}
                onChange={handleChange}
              >
                Account Expired
              </Checkbox>
            </FormControl>
            <FormControl id="passwordExpired">
              <Checkbox
                name="passwordExpired"
                isChecked={formData.passwordExpired}
                onChange={handleChange}
              >
                Password Expired
              </Checkbox>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleEdit} // Moved onSubmit to onClick
            >
              Save
            </Button>
            <Button onClick={onEditModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isDeleteConfirmationOpen}
        onClose={onDeleteConfirmationClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete User
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this user?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onDeleteConfirmationClose}>Cancel</Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Card style={{ marginTop: "15px" }}>
        <CardBody>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userList.map((user) => (
                <Tr key={user.id}>
                  <Td>
                    <Avatar
                      size="sm"
                      name={user.username}
                      src={user.avatarUrl}
                      marginRight={5}
                    />
                    {user.username}
                  </Td>
                  <Td>{user.email}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MenuDotsIcon />}
                        variant="none"
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={() => handleEditModalOpen(user.id)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon color="red" />}
                          onClick={() => handleDeleteConfirmationOpen(user.id)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};
export default UserPage;

import React from "react";
import "../Css/AudioNode.css";
import { Handle, Position } from "reactflow";

export const AudioNode = ({ data, isConnectable }) => {
  return (
    <div className="audio-node">
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div>
        <label htmlFor="audio"></label>
        <div className="audio-input">
          {!!data.value ? data.value : "Audio Content"}
        </div>
      </div>

      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
      />
    </div>
  );
};

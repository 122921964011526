import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./components/Login";
import Flow from "./components/Flow";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Register from "./components/Register";
import SettingsPage from "./components/SettingsPage";
import AgentPage from "./components/AgentPage";
import EditIntent from "./components//EditIntent";
import AgentIntentPage from "./components/AgentIntent";
import { Provider } from "react-redux";
import { store } from "./components/redux/store";
import ExternalFacts from "./components/ExternalFacts";
import AgentView from "./components/AgentView";
import { MultiSelectTheme } from "chakra-multiselect";

const theme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme,
  },
});

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/agentview",
      element: <AgentView />,
    },
    {
      path: "/flow",
      element: <Flow />,
    },
    {
      path: "/settings",
      element: <SettingsPage />,
    },
    {
      path: "/intents/edit",
      element: <EditIntent />,
    },
    {
      path: "/agents",
      element: <AgentPage />,
    },
    {
      path: "/agentintent",
      element: <AgentIntentPage />,
    },
    {
      path: "/external-facts",
      element: <ExternalFacts />,
    },
  ]);
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import "../components/Css/SideBar.css";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  IconButton,
  Box,
  Button,
  Divider,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  useDisclosure,
  Heading,
} from "@chakra-ui/react";
import "./Css/topics.css";
import { AddIcon, DeleteIcon, EditIcon, MinusIcon } from "@chakra-ui/icons";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import useNetworkingHook from "./hooks/useNetworkingHook";
import { setTopicId } from "./redux/slice";
import { useDispatch, useSelector } from "react-redux";
import MenuDotsIcon from "./images/CustomIcons/MenuDotsIcon";
import Select from "react-dropdown-select";

export default ({ nrOfBlocks = 0 }) => {
  const dispatch = useDispatch();
  const { topicId } = useSelector((state) => state.slice);
  const [topicName, setTopicName] = useState("");
  const {
    addTopics,
    updateTopics,
    deleteTopics,
    getTopicsCanvas,
    getBrainProtocol,
    topicsCanvasList,
    protocolList,
  } = useNetworkingHook();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose,
  } = useDisclosure();
  const [hoveredTopic, setHoveredTopic] = useState(null);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [topicToDelete, setTopicToDelete] = useState(null);
  const [topicNewName, setTopicNewName] = useState("");
  const [selectedProtocol, setSelectedProtocol] = useState("");

  useEffect(() => {
    console.log("topicId", topicId);
  }, [topicId]);

  useEffect(() => {
    getTopicsCanvas();
    getBrainProtocol();
  }, []);

  const handleCreateTopic = () => {
    console.log("Topic Name:", topicName);
    addTopics(topicName, selectedProtocol);
    setTopicName("");
    setSelectedProtocol("");
    onClose();
  };

  const handleToggle = (index, topicId) => {
    dispatch(setTopicId(topicId));
    setTopicToDelete(topicId);

    const currentTopic = topicsCanvasList.find((topic) => topic.id === topicId);
    setTopicNewName(currentTopic.name);

    const selectedProtocol = protocolList.find(
      (protocol) => protocol.id == currentTopic.protocolNo
    );
    setSelectedProtocol(selectedProtocol);
  };

  const deleteTopicsWithConfirmation = (topicId) => {
    const topicToDelete = topicsCanvasList.find(
      (topic) => topic.id === topicId
    );
    setIsDeleteAlertOpen(true);
    setTopicToDelete(topicToDelete);
  };

  const handleDeleteConfirm = () => {
    deleteTopics(topicToDelete.id);
    setIsDeleteAlertOpen(false);
  };

  return (
    <aside>
      <Box borderBottom={"1px solid #eee"}>
        <Text color="blue.500" fontWeight="bold" fontSize="2xl" align="center">
          Console Zana AI
        </Text>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15px 17px ",
        }}
      >
        <Text fontWeight="medium" fontSize="sm" mt="3px">
          Topics
        </Text>
        <Button variant="ghost" size="sm" onClick={onOpen}>
          <AddIcon color="#62778a" opacity="0.8" />
          <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Topic</ModalHeader>
              <Divider orientation="horizontal" />
              <ModalCloseButton />
              <ModalBody>
                <Text mb="15px">Topic Name</Text>
                <Input
                  placeholder="Enter topic name"
                  size="md"
                  onChange={(e) => setTopicName(e.target.value)}
                />
                <div style={{ marginTop: "10px" }}>
                  <Select
                    style={{ borderRadius: "5px" }}
                    placeholder={"Enter Protocol"}
                    options={[
                      { label: "None", value: 0 },
                      ...protocolList.map((protocol) => ({
                        label: protocol.name,
                        value: protocol.id,
                      })),
                    ]}
                    value={selectedProtocol}
                    onChange={(selected) => {
                      const selectedValue = selected[0]?.value;
                      setSelectedProtocol(
                        selectedValue ? selectedValue.toString() : null
                      ); // If the value is empty, set to null
                      console.log("selectedProtocolList", selectedValue);
                    }}
                    searchable={true}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button colorScheme="blue" onClick={handleCreateTopic}>
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Button>
      </div>

      {/* Accordion Section   */}

      <Accordion allowMultiple>
        {topicsCanvasList.map((topic, index) => (
          <AccordionItem key={index} border="none">
            <h2>
              <AccordionButton
                className={topicId == topic.id ? "selectedAccordionItem" : ""}
                onClick={() => handleToggle(index, topic.id)}
                style={{ height: "34px" }}
              >
                <Box as={ChevronRightIcon} mr="10px" color="#6E849A" />
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "85%",
                  }}
                  onMouseEnter={() => setHoveredTopic(topic.id)}
                  onMouseLeave={() => setHoveredTopic(null)}
                >
                  <Box flex="1" textAlign="left" fontSize="12px">
                    {topic.name}
                  </Box>
                  <Menu size="xs">
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<MenuDotsIcon color="#62778a" />}
                      variant="none"
                      size="sm"
                      style={{
                        visibility:
                          hoveredTopic === topic.id ? "visible" : "hidden",
                      }}
                    />
                    <MenuList>
                      <MenuItem
                        icon={<EditIcon />}
                        onClick={() => {
                          onNewOpen();
                          handleToggle(index, topic.id);
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        icon={<DeleteIcon color="red" />}
                        onClick={() => {
                          deleteTopicsWithConfirmation(topic.id);
                        }}
                      >
                        Delete Topic
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </AccordionButton>
            </h2>
          </AccordionItem>
        ))}
        <Modal size="sm" isOpen={isNewOpen} onClose={onNewClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Topic</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder={"Enter new name"}
                value={topicNewName}
                size="md"
                onChange={(e) => setTopicNewName(e.target.value)}
              />
              <div style={{ marginTop: "10px" }}>
                <Select
                  style={{ borderRadius: "6px" }}
                  placeholder={
                    !!selectedProtocol
                      ? selectedProtocol?.name
                      : "Enter Protocol"
                  }
                  value={selectedProtocol}
                  options={[
                    { label: "None", value: 0 },
                    ...protocolList.map((protocol) => ({
                      label: protocol.name,
                      value: protocol.id,
                    })),
                  ]}
                  onChange={(selected) => {
                    const selectedOption = selected[0];
                    const selectedProtocol = protocolList.find(
                      (protocol) => protocol.id === selectedOption.value
                    );
                    setSelectedProtocol(selectedProtocol.id);
                    console.log("selectedProtocolList", selectedProtocol);
                  }}
                  searchable={true}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <Button mr={3} onClick={onNewClose}>
                Close
              </Button>
              <Button
                colorScheme="blue"
                onClick={async () => {
                  await updateTopics(topicNewName, selectedProtocol);
                  setTopicNewName("");
                  setSelectedProtocol("");
                  onNewClose();
                }}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <AlertDialog
          isOpen={isDeleteAlertOpen}
          leastDestructiveRef={undefined}
          onClose={() => setIsDeleteAlertOpen(false)}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Topic
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this topic? <br />
              <Box paddingTop={5}>
                <Heading size="xs" textTransform="uppercase">
                  Topic Name:
                </Heading>
                <Text pt="2" fontSize="sm">
                  {
                    topicsCanvasList.find((topic) => topic.id === topicToDelete)
                      ?.name
                  }
                </Text>
              </Box>
              <Box paddingTop={5}>
                <Heading size="xs" textTransform="uppercase">
                  Number of Blocks:
                </Heading>
                <Text pt="2" fontSize="sm">
                  {nrOfBlocks}
                </Text>
              </Box>
              {topicToDelete &&
                topicToDelete.blocks &&
                topicToDelete.blocks.length}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => setIsDeleteAlertOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Accordion>
    </aside>
  );
};

import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Input,
  Text,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import {
  getDbEditIntentParams,
  getStorageAccessToken,
} from "./LocalStorage/mainDb";
import axios from "axios";
import { ArrowBackIcon, MinusIcon } from "@chakra-ui/icons";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import useNetworkingHook from "./hooks/useNetworkingHook";

const LinkItems = [{ name: "Back", icon: ArrowBackIcon }];

export default function SettingsPage({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedNavItem, setSelectedNavItem] = useState(LinkItems[0].name);
  const handleNavItemClick = (name) => {
    setSelectedNavItem(name);
  };
  return (
    <Box minH="100vh" bg={useColorModeValue("white")}>
      <div className="edit-intent-wrap">
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          bg={useColorModeValue("gray.100")}
          selectedNavItem={selectedNavItem}
          handleNavItemClick={handleNavItemClick}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />

        <EditIntent />
      </div>
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  selectedNavItem,
  handleNavItemClick,
  ...rest
}) => {
  return (
    <Box
      transition="3s ease"
      bg="white"
      borderRight="1px"
      borderRightColor="gray.200"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Settings
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          isSelected={selectedNavItem === link.name}
          onClick={() => handleNavItemClick(link.name)}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, isSelected, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onClick={() => {
        navigate("/settings");
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.100", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu color="#191f2b" />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Settings
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        {/* gjerat ne fund te cepit djathtas */}
      </HStack>
    </Flex>
  );
};

export function EditIntent() {
  const { editIntent, buttonName = "Create Intent" } = getDbEditIntentParams();
  // console.log("editIntent", editIntent);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [intentName, setIntentName] = useState(editIntent?.name ?? "");
  const [utterance, setUtterance] = useState("");
  const [intentLabel, setIntentLabel] = useState(editIntent?.label ?? "");
  const [importUtterance, setImportUtterance] = useState("");
  const [utteranceList, setUtteranceList] = useState(
    editIntent?.utterances ?? []
  );
  const { saveIntent, deleteIntent, deleteUtterances } = useNetworkingHook();

  useEffect(() => {
    fetchUtterances();
  }, []);

  const toast = useToast();
  const navigate = useNavigate();

  async function fetchUtterances() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/intent/utterances",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status !== 200) {
        console.log("Error fetching utterances");
        return [];
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching utterances:", error);
      return [];
    }
  }
  async function createIntent() {
    if (!intentName || utteranceList.length === 0) {
      return;
    }

    await saveIntent({
      id: editIntent?.id ?? undefined,
      intent: intentName,
      label: intentLabel,
      utterances: utteranceList,
      agentId: editIntent.agent.id,
      oldUtterances: editIntent?.utterances,
    });

    // Go back
    navigate("/settings");
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission or other default behavior

      if (utterance.trim() === "") {
        return;
      }
      const utterances = utterance.split("\n").map((text) => text.trim());
      setUtteranceList((prev) => [...prev, ...utterances]);
      setUtterance("");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (importUtterance.trim() === "") {
      return;
    }
    const utterances = importUtterance.split("\n").map((text) => text.trim());
    setUtteranceList((prev) => [...prev, ...utterances]);
    setImportUtterance("");
  }

  function handleDeleteUtterance(index) {
    setUtteranceList((prev) => prev.filter((_, i) => i !== index));
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          boxShadow: "0 1px 7px 1px rgba(0, 0, 0, 0.20)",
          borderRadius: 10,
          padding: "25px",
          margin: "10px 0px",
          width: "90%",
        }}
      >
        {editIntent ? " Edit Intent " : buttonName}
        <Divider orientation="horizontal" />
        <Text mt="10px" mb="15px">
          Name
        </Text>
        <Input
          value={intentName}
          placeholder="Enter intent name"
          size="md"
          onChange={(e) => setIntentName(e.target.value)}
        />
        <div style={{ marginTop: "15px" }}>
          <Text mb="15px">Label</Text>
          <Input
            value={intentLabel}
            placeholder="Enter intent label"
            size="md"
            onChange={(e) => setIntentLabel(e.target.value)}
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <Text mb="15px">Utterances</Text>
          <div style={{ marginBottom: "15px" }}>
            <Button onClick={onOpen}>Bulk import</Button>
            <Modal onClose={onClose} size={"xl"} isOpen={isOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Paste all utterances below</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Textarea
                    value={importUtterance}
                    placeholder="Add utterace list"
                    size="lg"
                    onChange={(e) => setImportUtterance(e.target.value)}
                  />
                </ModalBody>
                <ModalFooter
                  style={{
                    background: "#F6F6F6",
                    marginTop: "15px",
                    borderRadius: "10px",
                  }}
                >
                  <Button mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={(e) => {
                      handleSubmit(e);
                      onClose();
                      toast({
                        position: "top-right",
                        title: "Utterances Imported.",
                        //description: "We've deleted the intent",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      });
                    }}
                  >
                    Import
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>

          <Input
            value={utterance}
            placeholder="Add sample phrase"
            size="md"
            onChange={(e) => setUtterance(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Divider style={{ marginTop: "15px" }} />
        </div>
        {utteranceList.map((utterance, index) => {
          return (
            <div style={{ marginTop: "20px" }} key={utterance}>
              <Input defaultValue={utterance} />
              <MinusIcon
                onClick={async () => {
                  await deleteUtterances(editIntent.id, utterance);
                  toast({
                    position: "top-right",
                    title: "Utterance deleted.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  handleDeleteUtterance(index);
                }}
                style={{
                  position: "absolute",
                  marginTop: "10px",
                  transform: "translateX(-40px)",
                }}
              />
            </div>
          );
        })}

        <div style={{ marginTop: "20px" }}>
          {!!editIntent && (
            <Button
              colorScheme="red"
              mr={3}
              onClick={async () => {
                await deleteIntent(editIntent.id);

                toast({
                  position: "top-right",
                  title: "Intent deleted.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
                //go back
                navigate("/settings");
              }}
            >
              Delete
            </Button>
          )}

          <Button
            colorScheme="blue"
            onClick={() => {
              createIntent();
              toast({
                position: "top-right",
                title: "Succesfuly Saved.",
                //description: "",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
            }}
          >
            {!!editIntent ? "Save" : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
}

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedAgent: JSON.parse(localStorage.getItem("agent") ?? "null"),
  canvasId: localStorage.getItem("canvasId"),
  topicId: localStorage.getItem("topicId") ?? null,
  userRole: localStorage.getItem("userRole"),
  userName: localStorage.getItem("userName"),
};

const slice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    setSelectedAgent(state, action) {
      state.selectedAgent = action.payload;
      localStorage.setItem("agent", JSON.stringify(action.payload));
    },
    setCanvasId(state, action) {
      state.canvasId = action.payload;
      localStorage.setItem("canvasId", action.payload);
    },
    setTopicId(state, action) {
      state.topicId = action.payload;
      localStorage.setItem("topicId", action.payload);
    },
    setUserName(state, action) {
      state.userName = action.payload;
      localStorage.setItem("userName", action.payload);
    },
    setUserRole(state, action) {
      state.userRole = action.payload;
      localStorage.setItem("userRole", action.payload);
    },
  },
});

export const {
  setSelectedAgent,
  setCanvasId,
  setTopicId,
  setUserRole,
  setUserName,
} = slice.actions;
export default slice.reducer;

import { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import "../Css/text-updater-node.css";
import { GoToBlock } from "../images/CustomIcons/GoToBlock";
import { background } from "@chakra-ui/react";

function ButtonNode({ data, isConnectable }) {
  const [triggerIntent, setTriggerIntent] = useState(null);

  useEffect(() => {
    setTriggerIntent(data.intent);
    console.log("Button node", data);
  }, [data]);

  return (
    <div className="text-updater-node">
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div>
        <label htmlFor="text"></label>
        <div className="text-input">
          {!!triggerIntent?.label
            ? triggerIntent?.label
            : "Listening for an intent"}
        </div>
        <div className="intent-name">{triggerIntent?.name ?? ""}</div>
      </div>

      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
      />
      {!!data.action ? (
        <>
          <div
            tabIndex="-1"
            draggable="false"
            className="sc-ewnqHT sc-iitTBb knWnJm jnTBgS vf-canvas__node vf-canvas__node--actions"
            data-node-id="65a4fab8f824452669e9d994"
          >
            {" "}
            <div className="sc-jVOTke lcNYHT"></div>
            <div className="sc-jVOTke lcNYHW"></div>
            <div className="node-action">
              <div className="sc-eDOySX nAkYH" style={{ color: "#62778c" }}>
                <div className="sc-ldQtSd fIPLxU">
                  <GoToBlock />

                  <div className="sc-ispOId sc-kcuKUB sc-fnVmsx gvYlug Lmxui cjilib">
                    <div className="sc-fGwHKD ldQWrA">
                      {!!data.action ? data.action.target.nodeName : "action"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ButtonNode;

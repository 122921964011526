import React, { useEffect, useState } from "react";
import {
  Button,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Heading,
  CardFooter,
  Tooltip,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
} from "@chakra-ui/react";
import axios from "axios";
import { getStorageAccessToken } from "./LocalStorage/mainDb";
import { setSelectedAgent } from "../components/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MachineLearningIcon from "./images/CustomIcons/MachineLearning";

export default function AgentCard() {
  const { selectedAgent, userRole, userName } = useSelector(
    (state) => state.slice
  );
  const [agents, setAgents] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [pendingAgent, setPendingAgent] = useState(null);

  const onClose = () => {
    setIsOpen(false);
    setPendingAgent(null);
  };

  const openConfirmationDialog = (agent) => {
    setPendingAgent(agent);
    setIsOpen(true);
  };

  const switchAgent = (agent) => {
    if (selectedAgent && selectedAgent.id !== agent.id) {
      openConfirmationDialog(agent);
    } else {
      dispatch(setSelectedAgent(agent));
    }
  };

  const confirmSwitchAgent = () => {
    onClose();
    if (pendingAgent) {
      dispatch(setSelectedAgent(pendingAgent));
    }
  };

  useEffect(() => {
    getAgents();
  }, []);

  async function traingAgent(agent, languages) {
    try {
      const jsonBody = {
        agentName: agent.name,
        agentIp: agent.ip,
        agentLanguages: languages.toString(),
      };
      console.log("traingAgent", jsonBody);
      const response = await axios.post(
        "https://console.zana.ai/agent/configure/train",
        jsonBody,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Training Agent", response.status);

      if (response.status !== 200) {
        // Handle error
        console.error("Error training agent");
        return;
      }

      console.log("Training Agent Success", response.data);
    } catch (error) {
      console.error("Error training agent", error);
    }
  }
  function getAgents() {
    axios
      .get(
        userRole === "admin"
          ? "https://console.zana.ai/agent"
          : "https://console.zana.ai/user/" + userName + "/agents",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        if (resp.status !== 200) {
          console.log("error");
          return;
        }
        console.log("Get Agent List", resp.data);
        setAgents(resp.data);
      });
  }

  return (
    <>
      <SimpleGrid
        spacing={4}
        templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
      >
        {agents.map((agent) => (
          <Card
            key={agent.id}
            onClick={() => switchAgent(agent)}
            style={{
              border:
                selectedAgent && selectedAgent.id === agent.id
                  ? "2px solid #3182CE"
                  : "none",
              borderRadius:
                selectedAgent && selectedAgent.id === agent.id ? "6px" : "6px",
            }}
          >
            <CardHeader>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Heading size="md">{agent.name}</Heading>
                <Tooltip hasArrow label="Train Agent">
                  <IconButton
                    colorScheme="gray"
                    fontSize="30px"
                    icon={<MachineLearningIcon />}
                    onClick={() =>
                      traingAgent(
                        agent,
                        agent.languages.map((lang) => lang.code).join(", ")
                      )
                    }
                  />
                </Tooltip>
              </div>
            </CardHeader>
            <CardBody>
              <Text>Domain: {agent.domainName}</Text>
              <Text>
                Languages: {agent.languages.map((lang) => lang.name).join(", ")}
              </Text>
            </CardBody>
            <CardFooter>
              {selectedAgent && selectedAgent.id === agent.id && (
                <Button
                  colorScheme="blue"
                  onClick={() => navigate("/settings")}
                >
                  Go to Dashboard
                </Button>
              )}
            </CardFooter>
          </Card>
        ))}
      </SimpleGrid>

      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Agent Switch
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to switch agents?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" ml={3} onClick={confirmSwitchAgent}>
                Switch
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

import {
  CloseButton,
  Input,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  FormLabel,
} from "@chakra-ui/react";
import React, { useState } from "react";

export default function CarouselComponent({
  image,
  setImage,
  onButtonClick,
  link,
  setLink,
  articleLink,
  setArticleLink,
  title,
  setTitle,
  description,
  setDescription,
  deleteNode,
  node,
}) {
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      console.log("uploaded file", e.dataTransfer.files[0]);
      setImage(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      console.log("uploaded file", e.target.files[0]);
      setImage(e.target.files[0]);
    }
  };
  return (
    <div style={{ marginBottom: "25px" }}>
      <FormLabel>Carousel Node</FormLabel>
      <Tabs variant="soft-rounded" colorScheme="blue">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TabList>
            <Tab>Upload</Tab>
            <Tab>Link</Tab>
          </TabList>
          <CloseButton onClick={(e) => deleteNode(node?.id)} style={{}} />
        </div>
        <TabPanels>
          <TabPanel>
            <form
              id="form-file-upload"
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
            >
              {image ? (
                <Image
                  src={
                    typeof image === "string"
                      ? image
                      : URL.createObjectURL(image)
                  }
                  alt="Uploaded"
                  style={{ height: "100px", maxWidth: "auto" }}
                />
              ) : (
                <div>
                  {" "}
                  <input
                    type="file"
                    id="input-file-upload"
                    multiple={true}
                    onChange={handleChange}
                  />
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={dragActive ? "drag-active" : ""}
                  >
                    <div>
                      <p>Drag and drop your file here or</p>
                      <button className="upload-button" onClick={onButtonClick}>
                        Upload a file
                      </button>
                    </div>
                  </label>
                </div>
              )}
              {dragActive && (
                <div
                  id="drag-file-element"
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </TabPanel>
          <TabPanel>
            <Input
              id=""
              placeholder="Enter file URL"
              size="md"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
            />
          </TabPanel>
        </TabPanels>
        <Input
          id={node?.id}
          placeholder="Enter card title to add variable"
          size="md"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Input
          style={{ marginTop: "20px" }}
          placeholder="Enter Link Article"
          size="md"
          value={articleLink}
          onChange={(e) => {
            setArticleLink(e.target.value);
          }}
        />
        <Textarea
          style={{ marginTop: "20px" }}
          placeholder="Enter card description to add variable"
          size="sm"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Tabs>
    </div>
  );
}

import axios from "axios";

export const CHANGE_TOKEN = "CHANGE_TOKEN";
export const CHANGE_USERNAME = "CHANGE_USERNAME";

export const setNewAccessToken = (token) => ({
  type: CHANGE_TOKEN,
  payload: token,
});

export const setStorageAccessToken = (accessToken) => {
  localStorage.setItem("TOKEN", accessToken);
};
export const getStorageAccessToken = () => {
  return localStorage.getItem("TOKEN");
};

export const setStorageUsername = (username) => {
  localStorage.setItem("USERNAME", JSON.stringify(username));
};

export const getStorageUsername = () => {
  return JSON.parse(localStorage.getItem("USERNAME") ?? JSON.stringify(""));
};

export const setNewUsername = (username) => ({
  type: CHANGE_USERNAME,
  payload: username,
});

export const setDbEditIntentParams = (intent, buttonName) => {
  localStorage.setItem(
    "Edit Intent",
    JSON.stringify({ editIntent: intent, buttonName })
  );
};
export const getDbEditIntentParams = () => {
  return JSON.parse(localStorage.getItem("Edit Intent"));
};
export const setDbAgentId = (agentId) => {
  localStorage.setItem("Agent Id", agentId);
};
export const getDbAgentId = () => {
  return localStorage.getItem("Agent Id");
};

import { Handle, Position } from "reactflow";
import "../Css/text-updater-node.css";

function TextUpdaterNode({ data, isConnectable }) {
  return (
    <div className="text-updater-node">
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div>
        <label htmlFor="text"></label>
        <div className="text-input">
          {!!data.value ? data.value : "Enter text reply"}
        </div>
      </div>

      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
      />
    </div>
  );
}

export default TextUpdaterNode;

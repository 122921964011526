import React, { useEffect, useState } from "react";
import "../Css/DragDropFile.css";

function DragDropFile({ data, isConnectable }) {
  const [dragActive, setDragActive] = React.useState(false);
  const [image, setImage] = useState(null);

  const inputRef = React.useRef(null);

  useEffect(() => {
    setImage(data.value);
  }, [data]);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      console.log("uploaded file", e.dataTransfer.files[0]);
      setImage(e.dataTransfer.files[0]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      console.log("uploaded file", e.target.files[0]);
      setImage(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  console.log("image", image);
  return (
    // <div
    //   // id="form-file-upload"
    //   onDragEnter={handleDrag}
    //   style={{

    //     height:"100%",
    //     width:"max-content",
    //     backgroundColor:'green'}}
    // >
    <>
      {!!image ? (
        <img
          src={typeof image === "string" ? image : URL.createObjectURL(image)}
          alt="Uploaded"
          style={{ height: "100px", maxWidth: "auto", objectFit: "contain" }}
        />
      ) : (
        <div>Upload an image</div>
      )}
    </>
    //   {dragActive && (
    //     <div
    //       id="drag-file-element"
    //       onDragEnter={handleDrag}
    //       onDragLeave={handleDrag}
    //       onDragOver={handleDrag}
    //       onDrop={handleDrop}
    //     ></div>
    //   )}
    // </div>
  );
}

export default DragDropFile;

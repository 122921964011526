import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import QuillEditor from "./QuillEditor";
import useNetworkingHook from "./hooks/useNetworkingHook";

const ContentForm = ({ isOpen, onClose, onSubmit, initialData }) => {
  const { getEntitiesList, entitiesList } = useNetworkingHook();
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    intent: "",
    source: "",
    url: "",
    entityId: "",
  });

  useEffect(() => {
    getEntitiesList();
  }, []);

  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  useEffect(() => {
    if (isOpen && initialData) {
      console.log("Initial Data:", initialData);
      setFormData({ ...initialData, entityId: initialData.entities?.id });
      setSummary(initialData.summary);
      setContent(initialData.content);
    } else {
      console.log("No Initial Data");
      setFormData({
        title: "",
        intent: "",
        source: "",
        url: "",
        entityId: "",
      });
      setSummary("");
      setContent("");
    }
  }, [isOpen, initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const updatedFormData = {
      ...formData,
      entityId: initialData ? initialData.entities.id : formData.entityId,
    };
    onSubmit({ ...updatedFormData, summary, content });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {initialData ? "Edit Content" : "Add New Content"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Summary</FormLabel>
            <QuillEditor value={summary} onChange={setSummary} />
          </FormControl>
          <FormControl>
            <FormLabel>Content</FormLabel>
            <QuillEditor value={content} onChange={setContent} />
          </FormControl>
          <FormControl>
            <FormLabel>Intent</FormLabel>
            <Input
              name="intent"
              value={formData.intent}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Source</FormLabel>
            <Input
              name="source"
              value={formData.source}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl>
            <FormLabel>URL</FormLabel>
            <Input name="url" value={formData.url} onChange={handleChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Entity</FormLabel>
            <Select
              placeholder={"Select Entity"}
              value={formData.entityId}
              onChange={(e) =>
                setFormData({ ...formData, entityId: e.target.value })
              }
            >
              {entitiesList.map((entity) => (
                <option key={entity.id} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>
            {initialData ? "Update Content" : "Add Content"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContentForm;

import React, { useEffect, useState } from "react";
import {
  Button,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import axios from "axios";
import { getStorageAccessToken } from "./LocalStorage/mainDb";
import { ChevronDownIcon, SmallAddIcon } from "@chakra-ui/icons";
import { setSelectedAgent } from "../components/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import "./Css/AgentPage.css";
import useNetworkingHook from "./hooks/useNetworkingHook";

export default function AgentPage() {
  const { selectedAgent, userRole, userName } = useSelector(
    (state) => state.slice
  );
  const { sendToLogin } = useNetworkingHook();
  const [agents, setAgents] = useState([]);
  const [language, setLanguage] = useState([]);
  const [checkedLanguages, setCheckedLanguages] = useState([]);
  const [agentName, setAgentName] = useState("");
  const [domainName, setDomainName] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const addAgentModal = useDisclosure();
  const dispatch = useDispatch();
  const [pendingAgent, setPendingAgent] = useState(null);

  const openConfirmationDialog = (agent) => {
    setPendingAgent(agent);
    onOpen();
  };

  const switchAgent = (agent) => {
    if (selectedAgent && selectedAgent.id !== agent.id) {
      openConfirmationDialog(agent);
    } else {
      dispatch(setSelectedAgent(agent));
    }
  };

  const confirmSwitchAgent = () => {
    onClose();
    if (pendingAgent) {
      dispatch(setSelectedAgent(pendingAgent));
    }
  };

  useEffect(() => {
    // getLanguage();
    getAgents();
  }, []);

  function getAgents() {
    axios
      .get(
        userRole === "admin"
          ? "https://console.zana.ai/agent"
          : "https://console.zana.ai/user/" + userName + "/agents",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        if (resp.status !== 200) {
          console.log("error");
          return;
        }
        if (resp.status === 401) sendToLogin();
        console.log("Get Agent List", resp.data);
        setAgents(resp.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) sendToLogin();
          console.error(" error", error.response.data, error.response.status);
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  }

  function getLanguage() {
    axios
      .get("https://console.zana.ai/language", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (resp.status !== 200) {
          console.log("Get Language error", resp.status);
          return;
        }
        console.log("Get Languages List", resp.data);
        setLanguage(resp.data);
      });
  }

  async function handleAddAgent() {
    try {
      const languagesDTO = checkedLanguages.map((code) => ({ id: code }));
      const response = await axios.post(
        "https://console.zana.ai/agent/add",
        {
          name: agentName,
          domainName: domainName,
          ip: "127.0.0.1",
          languagesDTO: languagesDTO,
        },
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        console.log("Agent added successfully");
        getAgents();
      } else {
        console.log("Add Agent error", response.status, response.data);
      }
    } catch (error) {
      console.error("Error adding agent:", error.message);
    }
    addAgentModal.onClose();
  }

  return (
    <>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {selectedAgent ? selectedAgent.name : "Select Agent"}
        </MenuButton>
        <MenuList
          as="div"
          maxH="500px"
          overflowY="auto"
          borderRadius="md"
          boxShadow="md"
        >
          {agents.map((agent) => (
            <MenuItem
              key={agent.id}
              minH="48px"
              onClick={(e) => {
                e.preventDefault();
                switchAgent(agent);
              }}
            >
              <span>{agent.name}</span>
            </MenuItem>
          ))}
          <MenuItem onClick={addAgentModal.onOpen}>
            <Button size="sm" colorScheme="blue.500" variant="link">
              <SmallAddIcon />
              Add Agent
            </Button>
          </MenuItem>
        </MenuList>
      </Menu>

      <Modal
        isOpen={addAgentModal.isOpen}
        onClose={addAgentModal.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Agent</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Agent Name"
              value={agentName}
              onChange={(e) => setAgentName(e.target.value)}
              mb={3}
            />
            <Input
              placeholder="Domain Name"
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
              mb={3}
            />
            {language.map((lang) => (
              <Checkbox
                key={lang.id}
                value={lang.id}
                onChange={(e) =>
                  setCheckedLanguages([...checkedLanguages, e.target.value])
                }
              >
                {lang.name}
              </Checkbox>
            ))}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddAgent}>
              Add Agent
            </Button>
            <Button variant="ghost" onClick={addAgentModal.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Agent Switch
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to switch agents?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue" ml={3} onClick={confirmSwitchAgent}>
                Switch
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

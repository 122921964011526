import { Editable, EditableInput, EditablePreview } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import "../Css/Dashboard.css";

export default function ({ data, isConnectable }) {
  const [label, setLabel] = useState(data.label);

  useEffect(() => {
    data.label = label;
  }, [label]);

  useEffect(() => {
    setLabel(data.label);
  }, [data]);
  return (
    <>
      <div className="custom-node__header">
        {data.hasConditionalNode ? (
          <Handle
            type="target"
            position={Position.Left}
            isConnectable={isConnectable}
          />
        ) : null}{" "}
        <Editable value={label} onChange={setLabel}>
          <EditablePreview className="block-label" />
          <EditableInput />
        </Editable>
      </div>
    </>
  );
}

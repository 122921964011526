import React from "react";
import "./Css/NodeBar.css";

import flagregular from "./images/flagregular.svg";
import text from "./images/text.svg";
import image from "./images/image.svg";
import carousel from "./images/carousel.svg";
import card from "./images/card.svg";
import button from "./images/button.svg";
import conditional from "./images/conditional.svg";
import audio from "./images/audio-node.svg";

export const NodeBar = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div className="navwrapper">
      <div className="nav mainnav">
        <div
          className="navbutton selected"
          onDragStart={(event) => onDragStart(event, "start")}
          draggable
        >
          <div className="navicon">
            <img className="icons_sidebar" src={flagregular} />
          </div>
          <div className="navlabel">Start</div>
        </div>
        <div
          className="navbutton demoanimhover"
          onDragStart={(event) => onDragStart(event, "text")}
          draggable
        >
          <div className="navicon">
            <img className="icons_sidebar" src={text} />
          </div>
          <div className="navlabel">Text</div>
        </div>
        <div
          className="navbutton"
          onDragStart={(event) => onDragStart(event, "image")}
          draggable
        >
          <div className="navicon">
            <img className="icons_sidebar" src={image} />
          </div>
          <div className="navlabel">Image</div>
        </div>
        <div
          className="navbutton"
          onDragStart={(event) => onDragStart(event, "card")}
          draggable
        >
          <div className="navicon">
            <img className="icons_sidebar" src={card} />
          </div>
          <div className="navlabel">Card</div>
        </div>
        <div
          className="navbutton"
          onDragStart={(event) => onDragStart(event, "carousel")}
          draggable
        >
          <div className="navicon">
            <img className="icons_sidebar" src={carousel} />
          </div>
          <div className="navlabel">Carousel</div>
        </div>
        <div
          className="navbutton"
          onDragStart={(event) => onDragStart(event, "button")}
          draggable
        >
          <div className="navicon">
            <img className="icons_sidebar" alt={"button"} src={button} />
          </div>
          <div className="navlabel">Button</div>
        </div>
        <div
          className="navbutton"
          onDragStart={(event) => onDragStart(event, "conditional")}
          draggable
        >
          <div className="navicon">
            <img
              className="icons_sidebar"
              alt={"conditional"}
              src={conditional}
            />
          </div>
          <div className="navlabel">Conditional</div>
        </div>
        <div
          className="navbutton"
          onDragStart={(event) => onDragStart(event, "audio")}
          draggable
        >
          <div className="navicon">
            <img className="icons_sidebar" alt={"audo"} src={audio} />
          </div>
          <div className="navlabel">Audio</div>
        </div>
      </div>
    </div>
  );
};

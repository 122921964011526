import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useNetworkingHook from "./hooks/useNetworkingHook";
import { setCanvasId, setTopicId } from "./redux/slice";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import MenuDotsIcon from "./images/CustomIcons/MenuDotsIcon";
import ExportFlowIcon from "./images/CustomIcons/ExportFlowIcon";
import ImportFlowIcon from "./images/CustomIcons/ImportFlowIcon";

export const FlowCards = () => {
  const navigate = useNavigate();
  const { selectedAgent, canvasId } = useSelector((state) => state.slice);
  const {
    canvasFlowList,
    addCanvas,
    deleteCanvas,
    getCanvasFlow,
    getPathTopics,
    getTopicsCanvas,
    getBlockTopics,
    getNodeTopics,
    canvasImport,
    updateCanvas,
  } = useNetworkingHook();
  const dispatch = useDispatch();
  const flowPage = () => navigate("/flow");
  const [flowName, setFlowName] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteTargetCanvas, setDeleteTargetCanvas] = useState(null);
  const fileInputRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCardClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const deleteCanvasAlert = (canvas) => {
    setDeleteTargetCanvas(canvas);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    await deleteCanvas(deleteTargetCanvas.id);
    setDeleteConfirmationOpen(false);
    getCanvasFlow(selectedAgent.id);
  };

  useEffect(() => {
    if (!selectedAgent) return;
    getCanvasFlow(selectedAgent.id);
  }, [selectedAgent]);
  console.log(canvasFlowList.length);

  async function exportFlow(id) {
    const topicList = await getTopicsCanvas(id);

    const exportDataPromises = topicList.map(async (topic) => {
      const blocks = await getBlockTopics(topic.id);
      const nodes = await getNodeTopics(topic.id);
      const paths = await getPathTopics(topic.id);
      return { topic, blocks, nodes, paths };
    });

    const exportData = await Promise.all(exportDataPromises);

    const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify({
        canvas: id,
        data: exportData,
      })
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "flow " + id + ".json";

    link.click();
  }

  const handleFileUpload = (files) => {
    const file = files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result;
        handleJSONContent(fileContent);
      };

      reader.readAsText(file);
    }
  };

  const handleJSONContent = (jsonContent) => {
    try {
      const jsonData = JSON.parse(jsonContent);
      // const newNodes = createNodeFromJSON(jsonData);
      // setNodes((prevNodes) => [...prevNodes, ...newNodes]);
    } catch (error) {
      console.error("Error parsing JSON content:", error);
    }
  };

  const createNodeFromJSON = (jsonData) => {
    let parentNode = jsonData;
    const childrenList = jsonData.children;
    delete parentNode.children;
    return [parentNode, ...childrenList];
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload([file]);
      canvasImport(file).then(() => {
        getCanvasFlow(selectedAgent.id);
      });
    }
  };

  return (
    <Box
      background={""}
      backgroundSize="cover"
      backgroundPosition="center"
      borderRadius="md"
      p={8}
    >
      {selectedAgent ? (
        <SimpleGrid
          spacing={4}
          templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
          display={"flex"}
          alignItems={"center"}
        >
          {canvasFlowList?.map((canvas) => (
            <Card key={canvas.id} minH={220}>
              <CardHeader padding="0">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<MenuDotsIcon />}
                      variant="none"
                      size="sm"
                    />
                    <MenuList>
                      <MenuItem
                        icon={<EditIcon />}
                        onClick={() => {
                          dispatch(setCanvasId(canvas.id));
                          onOpen();
                        }}
                      >
                        Rename Flow
                      </MenuItem>
                      <MenuItem
                        icon={<ExportFlowIcon />}
                        onClick={() => exportFlow(canvas.id)}
                      >
                        Export Flow
                      </MenuItem>
                      <MenuItem
                        icon={<DeleteIcon color="red" />}
                        onClick={() => deleteCanvasAlert(canvas)}
                      >
                        Delete Flow
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "1.25em",
                  }}
                >
                  <Heading size="sm"> {canvas.flowName}</Heading>
                </div>
              </CardHeader>
              <CardBody></CardBody>
              <CardFooter>
                <Button
                  colorScheme="blue"
                  onClick={async () => {
                    dispatch(setTopicId());
                    dispatch(setCanvasId(canvas.id));
                    await getPathTopics(canvas.id);
                    flowPage();
                  }}
                >
                  View here
                </Button>
              </CardFooter>
            </Card>
          ))}
          <Modal size="sm" isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Rename Flow</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Enter new name"
                  size="md"
                  onChange={(e) => setFlowName(e.target.value)}
                />
              </ModalBody>

              <ModalFooter>
                <Button mr={3} onClick={onClose}>
                  Close
                </Button>
                <Button
                  colorScheme="blue"
                  onClick={async () => {
                    await updateCanvas(flowName);
                    setFlowName("");
                    getCanvasFlow(selectedAgent.id);
                    onClose();
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Card
            display="flex"
            justifyContent="center"
            align="center"
            variant="filled"
            cursor="pointer"
            style={{
              border: "1px dashed #c6c6c6",
              minWidthwidth: "180px",
              minHeight: "220px",
            }}
            onClick={handleCardClick}
          >
            <CardHeader></CardHeader>

            <CardBody textAlign="center">
              <Heading size="xl" color="#575757" paddingBottom={5}>
                Create Flow
              </Heading>
              <AddIcon color="#575757" fontSize="xl" mx="auto" />
            </CardBody>
          </Card>
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Flow</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Flow Name"
                  value={flowName}
                  onChange={(e) => setFlowName(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={async () => {
                    await addCanvas(flowName);
                    setFlowName("");
                    handleCloseModal();
                    getCanvasFlow(selectedAgent.id);
                  }}
                >
                  Save
                </Button>
                <Button colorScheme="gray" mr={3} onClick={handleCloseModal}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Card
            display="flex"
            justifyContent="center"
            align="center"
            variant="filled"
            cursor="pointer"
            style={{
              border: "1px dashed #c6c6c6",
              minWidthwidth: "180px",
              minHeight: "220px",
            }}
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            <CardHeader></CardHeader>
            <CardBody textAlign="center">
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileUpload(e.target.files);
                  handleFileInputChange(e); // Call the other file input change handler
                }}
              />
              <Heading size="xl" color="#575757" paddingBottom={5}>
                Upload Flow
              </Heading>
              <ImportFlowIcon fontSize="xl" mx="auto" />
            </CardBody>
          </Card>
        </SimpleGrid>
      ) : (
        ""
      )}
      <AlertDialog
        isOpen={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Delete Flow
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this flow?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="gray"
                onClick={() => setDeleteConfirmationOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                ml={3}
                onClick={handleDeleteConfirmation}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  useDisclosure,
  Icon,
  Text,
  VStack,
  HStack,
  Avatar,
} from "@chakra-ui/react";
import { FiMenu, FiStar } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { PiNotificationFill } from "react-icons/pi";
import { BiCategory } from "react-icons/bi";
import { BiObjectsHorizontalLeft } from "react-icons/bi";
import { BiBookContent } from "react-icons/bi";
import { BiLogOut } from "react-icons/bi";
import { RiGuideLine } from "react-icons/ri";
import AgentPage from "./AgentPage";
import { IntentTable } from "./IntentTable";
import { FlowCards } from "./FlowCards";
import { HomeIcon } from "./images/CustomIcons/HomeIcon";
import UserPage from "./UserPage";
import { NotificationRules } from "./NotificationRules";
import { Entities } from "./Entities";
import { EntityContent } from "./EntityContent";
import { useSelector } from "react-redux";
import useNetworkingHook from "./hooks/useNetworkingHook";
import { getStorageAccessToken } from "./LocalStorage/mainDb";
import { PatientGuideline } from "./PatientGuideline";

export default function SettingsPage({ children }) {
  const [LinkItems, setLinkItems] = useState([
    { name: "Dashboard", icon: HomeIcon },
    { name: "Intents", icon: FiStar },
    { name: "Notification Rule", icon: PiNotificationFill },
    {
      name: "Services",
      icon: BiCategory,
      subMenu: [
        { name: "Entities", icon: BiObjectsHorizontalLeft },
        { name: "Content", icon: BiBookContent },
      ],
    },
    ,
    { name: "Patient Guideline", icon: RiGuideLine },
    { name: "Logout", icon: BiLogOut },
  ]);
  const { userRole } = useSelector((state) => state.slice);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { logout } = useNetworkingHook();
  const [selectedNavItem, setSelectedNavItem] = useState("Dashboard");
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [selectedSubNavItem, setSelectedSubNavItem] = useState("");

  const handleNavItemClick = (name, subMenuName) => {
    if (name === "Logout") {
      logout();
    } else if (name === "Services" && !subMenuName) {
      setIsServicesOpen(!isServicesOpen);
    } else if (subMenuName) {
      setSelectedSubNavItem(subMenuName);
    } else {
      setSelectedNavItem(name);
      setIsServicesOpen(false);
      setSelectedSubNavItem("");
    }
  };

  useEffect(() => {
    console.log("userRole", userRole);
    if (!getStorageAccessToken()) {
      logout();
    }
    if (userRole === "admin")
      setLinkItems([
        { name: "Dashboard", icon: HomeIcon },
        { name: "Intents", icon: FiStar },
        { name: "Notification Rule", icon: PiNotificationFill },
        { name: "Users", icon: FaRegUser },
        {
          name: "Services",
          icon: BiCategory,
          subMenu: [
            { name: "Entities", icon: BiObjectsHorizontalLeft },
            { name: "Content", icon: BiBookContent },
          ],
        },
        { name: "Patient Guideline", icon: RiGuideLine },
        { name: "Logout", icon: BiLogOut },
      ]);
  }, []);

  return (
    <Flex minH="100vh" bg="gray.50">
      {/* Sidebar */}
      <Box
        w="250px"
        bg="white"
        boxShadow="lg"
        p={4}
        display={{ base: "none", md: "block" }}
      >
        <VStack spacing={4} align="stretch">
          <Box>
            <Text
              color="blue.500"
              fontWeight="bold"
              fontSize="2xl"
              align="center"
            >
              Console Zana AI
            </Text>
          </Box>
          <HStack align="center">
            <Avatar size="sm" />
            <AgentPage />
          </HStack>
        </VStack>
        <VStack spacing={4} mt={8} align="stretch">
          {LinkItems.map((item) => (
            <Box key={item.name}>
              <Box
                cursor="pointer"
                onClick={() => handleNavItemClick(item.name)}
                borderRadius="md"
                p={4}
                color={selectedNavItem === item.name ? "blue.500" : "gray.500"}
                bg={selectedNavItem === item.name ? "blue.50" : "inherit"}
              >
                <HStack align="center">
                  <Icon as={item.icon} fontSize="lg" mr={4} color="blue.500" />
                  <Text fontSize="0.9rem">{item.name}</Text>
                </HStack>
              </Box>
              {item.name === "Services" &&
                isServicesOpen &&
                item.subMenu &&
                item.subMenu.map((subMenuItem) => (
                  <Box
                    key={subMenuItem.name}
                    cursor="pointer"
                    onClick={() =>
                      handleNavItemClick(item.name, subMenuItem.name)
                    }
                    borderRadius="md"
                    p={4}
                    ml={8}
                    color={
                      selectedSubNavItem === subMenuItem.name
                        ? "blue.500"
                        : "gray.500"
                    }
                    bg={
                      selectedSubNavItem === subMenuItem.name
                        ? "blue.50"
                        : "inherit"
                    }
                  >
                    <HStack align="center">
                      <Icon
                        as={subMenuItem.icon}
                        fontSize="lg"
                        mr={4}
                        color="blue.500"
                      />
                      <Text fontSize="0.9rem">{subMenuItem.name}</Text>
                    </HStack>
                  </Box>
                ))}
            </Box>
          ))}
        </VStack>
      </Box>
      {/* Content Area */}
      <Box flex="1" p={8} bg="gray.50" boxShadow="md" borderRadius="md">
        {/* Add a button for toggling the sidebar on smaller screens */}
        <Box display={{ base: "block", md: "none" }} onClick={onOpen}>
          <FiMenu size={24} color="gray.500" />
        </Box>
        {selectedNavItem === "Dashboard" && !selectedSubNavItem && (
          <FlowCards />
        )}
        {selectedNavItem === "Intents" && !selectedSubNavItem && (
          <IntentTable />
        )}
        {selectedNavItem === "Notification Rule" && !selectedSubNavItem && (
          <NotificationRules />
        )}
        {selectedNavItem === "Users" && !selectedSubNavItem && <UserPage />}
        {selectedNavItem === "Patient Guideline" && !selectedSubNavItem && (
          <PatientGuideline />
        )}
        {selectedSubNavItem === "Entities" && <Entities />}
        {selectedSubNavItem === "Content" && <EntityContent />}
      </Box>
      {/* Sidebar Overlay for smaller screens */}
      <Box
        pos="fixed"
        top="0"
        left="0"
        w="100%"
        h="100%"
        bg="rgba(0, 0, 0, 0.3)"
        display={{ base: isOpen ? "block" : "none", md: "none" }}
        onClick={onClose}
      />
    </Flex>
  );
}

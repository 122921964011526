import { Box, HStack, useRadio, useRadioGroup } from "@chakra-ui/react";

// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "#3182CE",
          color: "white",
          borderColor: "#3182CE",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export default function OperatorSelector({ active, setActive }) {
  const options = ["AND", "OR"];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "react",
    onChange: console.log,
  });

  const group = getRootProps();

  return (
    <div
      style={{
        borderRadius: 10,
        padding: "10px",
        margin: "10px 0px",
      }}
    >
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard
              key={value}
              {...radio}
              isChecked={value == active}
              onChange={(e) => {
                setActive(value);
                radio.onChange(e);
              }}
            >
              {value}
            </RadioCard>
          );
        })}
      </HStack>
    </div>
  );
}

import React, { useState } from "react";
import zanalogo from "./images/zanalogo.svg";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import axios from "axios";
import {
  getStorageAccessToken,
  setStorageAccessToken,
} from "./LocalStorage/mainDb";
import { useDispatch, useSelector } from "react-redux";
import { setUserName, setUserRole } from "./redux/slice";

const Login = (props) => {
  const { userName } = useSelector((state) => state.slice);
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = {
      username: userName,
      password,
    };
    try {
      const response = await axios.post(
        "https://console.zana.ai/keycloak/login",
        userData
      );

      console.log(response.data);
      if (response.status === 200) {
        const token = response.data.token;
        setStorageAccessToken(token);
        getUserRole();
      }
      // Handle successful login
    } catch (error) {
      console.error(error);
      setLoginStatus("Invalid login credentials. Please try again.");
      // Handle error
      console.log("User login failed. Please try again.");
    }
  };

  async function getUserRole() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/api/admin/user/" + userName + "/roles",
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        dispatch(setUserRole(response.data[0].name));
        navigate("/agentview");
      }
      if (![200, 201].includes(response.status)) {
        console.error("admin/user/ error", response.data, response.status);
        console.log(response.status);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
      }
    }
  }
  // const registerSubmit = (e) => {
  //   e.preventDefault();
  //   navigate("/register");
  // };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src={zanalogo}
            style={{ width: "250px", height: "75px" }}
            alt="logo"
          />
          <Heading fontSize={"4xl"}>Login to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to enjoy all of our cool <Link color={"blue.400"}>features</Link> ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="userName">
              <FormLabel>Username</FormLabel>
              <Input
                value={userName}
                onChange={(e) => {
                  const newUsername = e.target.value;
                  dispatch(setUserName(newUsername));
                }}
                type="username"
                placeholder="username"
                id="usesrname"
                name="username"
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="********"
                id="password"
                name="password"
              />
            </FormControl>
            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack>

              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleSubmit}
              >
                Log in
              </Button>
              <Link color={"red"}>{loginStatus && loginStatus}</Link>
              {/* <Text
                fontSize={"md"}
                color={"gray.600"}
                style={{ display: "flex", justifyContent: "center" }}
              >
                Don't have an account?{" "}
                <Link color={"blue.400"} onClick={registerSubmit}>
                  Register here.
                </Link>
              </Text> */}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;

import { createIcon } from "@chakra-ui/icons";

const MenuDotsIcon = createIcon({
  displayName: "MyCustomIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none" stroke="currentColor" strokeWidth="2">
      <circle cx="12" cy="12" r="1" />
      <circle cx="12" cy="5" r="1" />
      <circle cx="12" cy="19" r="1" />
    </g>
  ),
});
export default MenuDotsIcon;

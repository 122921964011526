import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import useNetworkingHook from "./hooks/useNetworkingHook";

const PatientGuidelineForm = ({
  isOpen,
  onClose,
  onSubmit,
  initialData = {},
  entitiesList,
}) => {
  const { getContentFromEntities, contentFromEntities } = useNetworkingHook();
  const [module, setModule] = useState("");
  const [article, setArticle] = useState("");
  const [moduleNr, setModuleNr] = useState("");
  const [articleNr, setArticleNr] = useState("");
  const [relatedModule, setRelatedModule] = useState("");
  const [relatedArticle, setRelatedArticle] = useState("");

  useEffect(() => {
    if (!initialData || !initialData.module) return;
    console.log("initialData", initialData);
    setModule(initialData.entity.id);
    setArticle(initialData.title.id);
    setModuleNr(initialData.module);
    setArticleNr(initialData.submodule);
    setRelatedModule(
      initialData.relatedModule?.id ?? initialData.relatedEntity?.id
    );
    setRelatedArticle(
      initialData.relatedArticle?.id ?? initialData.relatedTitle?.id
    );
  }, [initialData]);

  useEffect(() => {
    if (isOpen) return;
    setModule("");
    setArticle("");
    setModuleNr("");
    setArticleNr("");
    setRelatedModule("");
    setRelatedArticle("");
  }, [isOpen]);

  useEffect(() => {
    if (!module) return;
    console.log("module entity", module);
    getContentFromEntities(module);
  }, [module]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {initialData.id ? "Edit" : "Add"} Patient Guideline
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Module</FormLabel>
            <Select
              placeholder="Select Module"
              value={module}
              onChange={(e) => setModule(e.target.value)}
            >
              {entitiesList.map((entity, index) => (
                <option key={index} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {module &&
            (contentFromEntities.length !== 0 ? (
              <FormControl>
                <FormLabel>Article</FormLabel>
                <Select
                  placeholder="Select Article"
                  value={article}
                  onChange={(e) => setArticle(e.target.value)}
                >
                  {contentFromEntities.map((content, index) => (
                    <option key={index} value={content.id}>
                      {content.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Alert status="info">
                <AlertIcon />
                No Article was found for this module.
              </Alert>
            ))}
          <FormControl>
            <FormLabel>Module Nr</FormLabel>
            <Input
              value={moduleNr}
              onChange={(e) => setModuleNr(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Article Nr</FormLabel>
            <Input
              value={articleNr}
              onChange={(e) => setArticleNr(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Related Module</FormLabel>
            <Select
              placeholder="Select Related Module"
              value={relatedModule}
              onChange={(e) => setRelatedModule(e.target.value)}
            >
              {entitiesList.map((entity, index) => (
                <option key={index} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {relatedModule &&
            (contentFromEntities.length !== 0 ? (
              <FormControl>
                <FormLabel>Related Article</FormLabel>
                <Select
                  placeholder="Select Related Article"
                  value={relatedArticle}
                  onChange={(e) => setRelatedArticle(e.target.value)}
                >
                  {contentFromEntities.map((content, index) => (
                    <option key={index} value={content.id}>
                      {content.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Alert status="info">
                <AlertIcon />
                No Related Article was found for this module.
              </Alert>
            ))}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await onSubmit({
                id: initialData.id,
                module: module,
                article: article,
                moduleNr: moduleNr,
                articleNr: articleNr,
                relatedEntity: relatedModule,
                relatedTitle: relatedArticle,
              });
              onClose();
            }}
          >
            {initialData.id ? "Save" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PatientGuidelineForm;

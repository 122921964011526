import { FormLabel, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CreateIntentUtterances from "./CreateIntentUtterances";
import { useSelector } from "react-redux";
import useNetworkingHook from "../hooks/useNetworkingHook";

export default function StartComponent({
  node,
  triggerIntent,
  setTriggerIntent,
}) {
  const { selectedAgent } = useSelector((state) => state.slice);
  const { fetchIntents, intents } = useNetworkingHook();
  const handleIntentSelect = (intent) => {
    console.log("start selected intent", intent);
    setTriggerIntent(intent);
  };

  useEffect(() => {
    fetchIntents();
  }, []);

  return (
    <div>
      <FormLabel>Start Node</FormLabel>
      <Select
        placeholder={!!triggerIntent ? triggerIntent.name : "Select an Intent"}
        id={node.id}
        value={triggerIntent?.name}
        onChange={(e) => {
          const selectedIntentName = e.target.value;
          handleIntentSelect(
            intents.find((intent) => intent?.name == selectedIntentName)
          );
        }}
      >
        {intents
          .filter(
            (intent) => selectedAgent && selectedAgent.id === intent.agent.id
          )
          .map((intent) => (
            <option key={intent?.id} value={intent.name}>
              {intent.name}
            </option>
          ))}
      </Select>
      <div style={{ marginTop: "15px" }}>
        <CreateIntentUtterances
          updateIntents={() => {
            fetchIntents();
          }}
        />
      </div>
    </div>
  );
}

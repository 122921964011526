import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Select,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  Input,
  InputRightElement,
  Box,
  InputLeftElement,
  Icon,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import useNetworkingHook from "./hooks/useNetworkingHook";
import EntityForm from "./EntityForm";
import MenuDotsIcon from "./images/CustomIcons/MenuDotsIcon";
import { useSelector } from "react-redux";
import { FaUpload } from "react-icons/fa";

export const Entities = () => {
  const { selectedAgent } = useSelector((state) => state.slice);
  const {
    getEntitiesList,
    entityAdd,
    entityUpdate,
    deleteEntity,
    entitiesList,
    entityImport,
  } = useNetworkingHook();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [importFile, setImportFile] = useState(null);
  const [importError, setImportError] = useState("");
  useEffect(() => {
    getEntitiesList();
  }, []);

  const handleEdit = (entity) => {
    setSelectedEntity(entity);
    onOpen();
  };

  const handleSubmit = async (data) => {
    if (selectedEntity) {
      await entityUpdate(data);
    } else {
      await entityAdd(data);
    }
    setSelectedEntity(null);
    onClose();
  };

  const handleImport = async () => {
    try {
      if (!importFile) {
        setImportError("Please select a file.");
        return;
      }

      await entityImport(importFile, selectedAgent.id);
      console.log("Imported successfully.");
      getEntitiesList();
      setImportFile(null);
      setImportError("");
    } catch (error) {
      console.error("Error during import:", error.message);
      setImportError("Error during import. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    setImportFile(e.target.files[0]);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          colorScheme="blue"
          onClick={() => {
            setSelectedEntity(null);
            onOpen();
          }}
          mb={10}
        >
          Add Entity
        </Button>
        <InputGroup width="auto">
          <InputLeftElement
            pointerEvents="none"
            children={<Icon as={FaUpload} color="gray.300" />}
          />
          <Input
            type="text"
            placeholder="Select a JSON file for import"
            value={importFile ? importFile.name : ""}
            cursor="pointer"
            isReadOnly
            htmlSize={30}
            onClick={() => document.getElementById("fileInput").click()}
          />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              colorScheme="blue"
              onClick={handleImport}
            >
              Import
            </Button>
          </InputRightElement>
          <input
            type="file"
            accept=".json"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="fileInput"
          />
        </InputGroup>
        {importError && (
          <Box color="red" mt={2}>
            {importError}
          </Box>
        )}
      </div>
      <Card>
        <CardHeader>
          <Heading size="md"> Entities List</Heading>
        </CardHeader>
        <CardBody>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th>Note</Th>
                <Th>Parent</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {entitiesList.map((entity, index) => (
                <Tr key={index}>
                  <Td>{entity.name}</Td>
                  <Td>{entity.type}</Td>
                  <Td>{entity.note}</Td>
                  <Td>{entity.parent?.name}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MenuDotsIcon />}
                        variant="none"
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={() => handleEdit(entity)}
                        >
                          Edit
                        </MenuItem>

                        <MenuItem
                          icon={<DeleteIcon color="red" />}
                          onClick={() => deleteEntity(entity.id)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <EntityForm
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        initialData={selectedEntity || {}}
        entitiesList={entitiesList}
      />
    </div>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Input,
  Text,
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  useDisclosure,
  InputGroup,
  InputRightElement,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  getStorageAccessToken,
  setDbEditIntentParams,
} from "./LocalStorage/mainDb";
import { getDbAgentId } from "./LocalStorage/mainDb";
import CreateIntentUtterances from "./SidebarComponents/CreateIntentUtterances";
import axios from "axios";
import { useSelector } from "react-redux";
const LinkItems = [{ name: "Back", icon: ArrowBackIcon }];

export default function AgentIntentPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedNavItem, setSelectedNavItem] = useState(LinkItems[0].name);
  const handleNavItemClick = (name) => {
    setSelectedNavItem(name);
  };

  return (
    <Box minH="100vh" bg={useColorModeValue("white")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        bg={useColorModeValue("gray.100")}
        selectedNavItem={selectedNavItem}
        handleNavItemClick={handleNavItemClick}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <AgentIntent />
      </Box>
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  selectedNavItem,
  handleNavItemClick,
  ...rest
}) => {
  return (
    <Box
      transition="3s ease"
      bg="white"
      borderRight="1px"
      borderRightColor="gray.200"
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Agents
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          isSelected={selectedNavItem === link.name}
          onClick={() => handleNavItemClick(link.name)}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, isSelected, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onClick={() => {
        navigate("/settings");
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.100", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Settings
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        {/* gjerat ne fund te cepit djathtas */}
      </HStack>
    </Flex>
  );
};

export const AgentIntent = () => {
  const { selectedAgent } = useSelector((state) => state.slice);
  const [intents, setIntents] = useState([]);

  useEffect(() => {
    console.log("selectedAgent", selectedAgent);
  }, [selectedAgent]);

  useEffect(() => {
    fetchIntents();
  }, []);

  useEffect(() => {
    if (
      !intents ||
      intents.length === 0 ||
      !!intents.find((intent) => intent.utterances)
    )
      return;
    fetchUtterances();
  }, [intents]);

  async function fetchIntents() {
    const resp = await axios.get("https://console.zana.ai/intent", {
      headers: {
        Authorization: "Bearer " + getStorageAccessToken(),
        "Content-Type": "application/json",
      },
    });

    if (resp.status !== 200) {
      console.log("error");
      return;
    }
    console.log("intent list", resp.data);
    setIntents(resp.data);
  }

  function fetchUtterances() {
    axios
      .get("https://console.zana.ai/intent/utterances", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (resp.status !== 200) {
          console.log("error");
          return;
        }
        console.log("utterances ", resp.data);
        // Map the fetched utterances to their respective intents
        const updatedIntents = intents.map((intent) => {
          const utterancesForIntent = resp.data.filter(
            (utterance) => utterance.intent.id === intent.id
          );
          return {
            ...intent,
            utterances: utterancesForIntent.map(
              (utterance) => utterance.utterances
            ),
          };
        });
        setIntents(updatedIntents);
        console.log("updated intents", updatedIntents);
      })
      .catch((error) => {
        console.log(error.resp.data.error);
      });
  }

  const agentId = getDbAgentId();
  console.log("agent id on agent intents", agentId);

  const [page, setPage] = useState(1);
  const perPage = 8; // Number of items to display per page
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const filteredIntents = useMemo(() => {
    if (intents && intents.length > 0) {
      if (searchTerm) {
        return intents.filter(
          (intent) => intent.name && intent.name.includes(searchTerm)
        );
      } else {
        return intents;
      }
    } else {
      return [];
    }
  }, [intents, searchTerm]);

  const totalPages = Math.ceil(filteredIntents.length / perPage);

  const handleNextPage = () => {
    if (page === totalPages) return;
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page === 1) return;
    setPage((prevPage) => prevPage - 1);
  };

  const paginatedIntents = filteredIntents.slice(
    (page - 1) * perPage,
    page * perPage
  );
  useEffect(() => {
    setPage(1);
  }, [searchTerm]);
  return (
    <>
      <div style={{ color: "#3182ce" }}>
        {" "}
        <CreateIntentUtterances buttonName="Add New" />
      </div>
      <InputGroup mt={4} mb={2}>
        <Input
          type="text"
          placeholder="Search Intents"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={() => setSearchTerm("")}>
            Clear
          </Button>
        </InputRightElement>
      </InputGroup>
      <TableContainer
        style={{
          marginTop: "20px",
          border: "1px solid #d8d8d8",
          borderRadius: "10px",
        }}
      >
        <Table variant="striped" colorScheme="blue">
          <TableCaption>
            Listing All Intents (Page {page} of {totalPages})
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Intents</Th>
              <Th>Utterances</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedIntents &&
              paginatedIntents
                .filter((intent) =>
                  !selectedAgent ? true : selectedAgent.id === intent.agent.id
                )
                .map((intentObj, index) => (
                  <Tr key={index}>
                    <Td>{intentObj.name}</Td>
                    <Td>
                      {intentObj.utterances &&
                        intentObj.utterances
                          .slice(0, 3) // Limit the number of utterances to display
                          .map((utterance, index) => {
                            return (
                              utterance +
                              (index === intentObj.utterances.length - 1
                                ? ""
                                : ", ")
                            );
                          })}
                    </Td>
                    <Td>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setDbEditIntentParams(intentObj, "Edit");
                          navigate("/intents/edit");
                        }}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
          <Tfoot>
            {/* <Tr>
                  <Th>To convert</Th>
                  <Th>into</Th>
                  <Th isNumeric>multiply by</Th>
                </Tr> */}
          </Tfoot>
        </Table>
        <Box mb={2} textAlign="center">
          <ChevronLeftIcon
            onClick={handlePreviousPage}
            disabled={page === 1}
            mr={2}
            boxSize={5}
          >
            Previous
          </ChevronLeftIcon>
          <ChevronRightIcon
            onClick={handleNextPage}
            disabled={page === totalPages}
            ml={2}
            boxSize={5}
          >
            Next
          </ChevronRightIcon>
        </Box>
      </TableContainer>
    </>
  );
};

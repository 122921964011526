import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Select,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Text,
  Editable,
  EditableInput,
  EditablePreview,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

const EntityForm = ({
  isOpen,
  onClose,
  onSubmit,
  initialData = {},
  entitiesList,
}) => {
  const [name, setName] = useState(initialData.name || "");
  const [note, setNote] = useState(initialData.note || "");
  const [url, setUrl] = useState(initialData.url || "");
  const [type, setType] = useState(initialData.type || "");
  const [description, setDescription] = useState(initialData.description || "");
  const [source, setSource] = useState(initialData.source || "");
  const [synonyms, setSynonyms] = useState(initialData.synonyms || []);
  const [synonymInput, setSynonymInput] = useState("");
  const [parentId, setParentId] = useState(initialData.parentId || "");

  useEffect(() => {
    setName(initialData.name || "");
    setNote(initialData.note || "");
    setUrl(initialData.url || "");
    setType(initialData.type || "");
    setDescription(initialData.description || "");
    setSource(initialData.source || "");
    setSynonyms(initialData.synonyms || []);
    setParentId(initialData.parentId || "");
  }, [initialData]);

  const addSynonym = () => {
    if (synonymInput.trim() !== "") {
      setSynonyms([...synonyms, synonymInput.trim()]);
      setSynonymInput("");
    }
  };

  const removeSynonym = (index) => {
    const updatedSynonyms = synonyms.filter((_, i) => i !== index);
    setSynonyms(updatedSynonyms);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{initialData.id ? "Edit" : "Add"} Entity</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Note</FormLabel>
            <Input value={note} onChange={(e) => setNote(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>URL</FormLabel>
            <Input value={url} onChange={(e) => setUrl(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Type</FormLabel>
            <Input value={type} onChange={(e) => setType(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Source</FormLabel>
            <Input value={source} onChange={(e) => setSource(e.target.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Synonyms</FormLabel>
            <VStack align="start" spacing={2}>
              {synonyms.map((synonym, index) => (
                <HStack key={index}>
                  <Text>{synonym}</Text>
                  <IconButton
                    icon={<DeleteIcon />}
                    onClick={() => removeSynonym(index)}
                  />
                </HStack>
              ))}
              <HStack>
                <Input
                  value={synonymInput}
                  onChange={(e) => setSynonymInput(e.target.value)}
                />
                <Button onClick={addSynonym}>
                  <AddIcon />
                </Button>
              </HStack>
            </VStack>
          </FormControl>
          <FormControl>
            <FormLabel>Parent</FormLabel>
            <Select
              placeholder="Select parent"
              value={parentId}
              onChange={(e) => setParentId(e.target.value)}
            >
              {entitiesList.map((entity, index) => (
                <option key={index} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={async () => {
              await onSubmit({
                id: initialData.id,
                name,
                note,
                url,
                type,
                description,
                source,
                synonyms,
                parentId,
              });
              onClose();
            }}
          >
            {initialData.id ? "Save" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EntityForm;

import React from "react";

export const GoToBlock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.5 9.257c0-.823-.004-1.387-.039-1.83-.036-.468-.1-.672-.16-.795a2 2 0 0 0-.933-.934c-.122-.059-.327-.123-.795-.16C10.088 5.502 9.456 5.5 8.5 5.5h-1c-.956 0-1.588.001-2.073.039-.468.036-.672.1-.795.16a2 2 0 0 0-.934.933.921.921 0 0 0-.051.141c-.116.4-.435.727-.85.727A.795.795 0 0 1 2 6.703c.006-1.923.062-3.312.535-4.065a3.5 3.5 0 0 1 1.102-1.102C4.492 1 5.66 1 8 1s3.508 0 4.362.536a3.5 3.5 0 0 1 1.101 1.102C14 3.492 14 4.66 14 7v2.5c0 1.866 0 2.799-.347 3.519a3.49 3.49 0 0 1-1.634 1.634C11.299 15 10.366 15 8.5 15h-1c-1.866 0-2.799 0-3.519-.347a3.49 3.49 0 0 1-1.704-1.792c-.173-.438.213-.861.684-.861h.054c.348 0 .607.27.792.564a2 2 0 0 0 .629.63c.148.092.392.19.97.246.603.058 1.395.06 2.594.06 1.2 0 1.99-.002 2.593-.06.58-.056.823-.154.971-.247a2 2 0 0 0 .63-.629c.092-.148.19-.392.246-.97.054-.559.06-1.28.06-2.337zm-.06-4.85.016.19a3.498 3.498 0 0 0-.437-.25C11.299 4 10.366 4 8.5 4h-1c-1.866 0-2.799 0-3.519.347a3.5 3.5 0 0 0-.437.25l.016-.19c.056-.58.154-.823.247-.971a2 2 0 0 1 .629-.63c.148-.092.392-.19.97-.246C6.01 2.502 6.802 2.5 8 2.5c1.2 0 1.99.002 2.593.06.58.056.823.154.971.247a2 2 0 0 1 .63.629c.092.148.19.392.246.97zM2 9.75A.75.75 0 0 1 2.75 9h3.899l-.475-.77a.75.75 0 1 1 1.152-.96l1.5 2a.75.75 0 0 1 0 .96l-1.5 2a.75.75 0 1 1-1.152-.96l.475-.77H2.75A.75.75 0 0 1 2 9.75z"
      ></path>
    </svg>
  );
};

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Table,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import useNetworkingHook from "./hooks/useNetworkingHook";
import axios from "axios";
import { getStorageAccessToken } from "./LocalStorage/mainDb";
import { DeleteIcon, EditIcon, SmallAddIcon } from "@chakra-ui/icons";
import ConditionNodeType from "../models/ConditionNodeType";
import OperatorSelector from "./SidebarComponents/ButtonRadio";
import MenuDotsIcon from "./images/CustomIcons/MenuDotsIcon";
import { useSelector } from "react-redux";

export const NotificationRules = () => {
  const {
    getNotificatonOptions,
    notificationRuleAdd,
    getRedirectOptions,
    getNotifyOptions,
    getNotificationRuleDetails,
    getEntitiesList,
    notificationRuleUpdate,
    deleteNotificationRule,
    parseLogicEquation,
    getBlockFromAgent,
    notificatonOptionsList,
    redirectOptionsList,
    notifyOptionsList,
    notificationRuleDetailsList,
    entitiesList,
    blockFromAgent,
  } = useNetworkingHook();
  const { selectedAgent } = useSelector((state) => state.slice);

  const [name, setName] = useState("");
  const [decriptionValue, setDecriptionValue] = useState("");
  const [message, setMessage] = useState("");
  const [selectedNotificationOption, setSelectedNotificationOption] =
    useState("");
  const [selectedRedirectOptions, setSelectedRedirectOptions] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [contentList, setContentList] = useState([]);
  const [selectedNotifyOptions, setSelectedNotifyOptions] = useState("");
  const [conditionFromNotification, setConditionFromNotification] = useState(
    []
  );
  const [totalCondition, setTotalCondition] = useState({});

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [currentRule, setCurrentRule] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedContent, setSelectedContent] = useState("");

  const handleEditClick = (rule) => {
    const ruleData = notificationRuleDetailsList.find((r) => r.id === rule.id);
    console.log("ruleData:", ruleData);
    const conditionNode = parseLogicEquation(ruleData.condition);
    console.log("conditionNode:", conditionNode);

    setCurrentRule({
      ...ruleData,
      condition: conditionNode,
    });

    // setSelectedEntity(ruleData.selectedEntity);
    // setSelectedContent(ruleData.selectedContent);
    // setRedirectUrl(ruleData.redirectUrl);

    // Open the modal
    onOpenModal();
  };

  useEffect(() => {
    if (isOpenModal) return;
    setCurrentRule(null);
  }, [isOpenModal]);

  function addNewCondition(
    operator,
    selectedVariable,
    activeCondition,
    activeVar2,
    targetId
  ) {
    if (!selectedVariable || !activeVar2 || !activeCondition) {
      return;
    }

    const newCondition = new ConditionNodeType(activeCondition, {
      variable: selectedVariable,
      value: activeVar2,
    });

    const newRoot = addConditionRecursive(
      operator,
      targetId,
      newCondition,
      totalCondition
    );

    setTotalCondition(newRoot);
  }

  useEffect(() => {
    getNotificatonOptions();
    getRedirectOptions();
    getNotifyOptions();
  }, []);

  useEffect(() => {
    if (!selectedAgent) return;
    getNotificationRuleDetails(selectedAgent.id);
  }, [selectedAgent]);

  useEffect(() => {
    if (!selectedNotificationOption && !currentRule) return;
    getChooseconditonFromNotification();
  }, [currentRule, selectedNotificationOption]);

  useEffect(() => {
    getEntitiesList();
  }, [selectedRedirectOptions]);

  useEffect(() => {
    if (!selectedEntity) return;
    getContentListByEntity();
    console.log("getContentListByEntity", getContentListByEntity());
  }, [selectedEntity]);

  async function getChooseconditonFromNotification() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/notificationRuleDetails/condition/" +
          (currentRule?.notificationOptions ?? selectedNotificationOption),
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setConditionFromNotification(response.data);
        console.log("getChooseconditonFromNotification:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error(
          "getChooseconditonFromNotification error",
          response.data,
          response.status
        );
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
      }
    }
  }

  async function getContentListByEntity() {
    try {
      const response = await axios.get(
        "https://console.zana.ai/content/entities/" + selectedEntity,
        {
          headers: {
            Authorization: "Bearer " + getStorageAccessToken(),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setContentList(response.data);
        console.log("getContentList:", response.data);
      }
      if (![200, 201].includes(response.status)) {
        console.error("getContentList error", response.data, response.status);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.status);
      } else {
        // Handle other errors
        console.error("An error occurred:", error);
      }
    }
  }

  function addNewCondition(
    operator,
    selectedVariable,
    activeCondition,
    activeVar2,
    targetId
  ) {
    if (!selectedVariable || !activeVar2 || !activeCondition) {
      return;
    }

    const newCondition = new ConditionNodeType(activeCondition, {
      variable: selectedVariable,
      value: activeVar2,
    });

    const newRoot = addConditionRecursive(
      operator,
      targetId,
      newCondition,
      totalCondition
    );

    setTotalCondition(newRoot);
  }

  function addConditionRecursive(
    operator,
    targetId,
    newCondition,
    currentNode // gjithmon lere bosh kete
  ) {
    console.log("addConditionRecursive currentNode", currentNode);

    if (!totalCondition?.operator) {
      console.log("Empty start Condition");
      return newCondition;
    }

    if (!targetId) {
      console.log("no target Id");
      const andCondition = new ConditionNodeType(operator);
      andCondition.left = totalCondition;
      andCondition.right = newCondition;

      console.log("AND condition", andCondition);
      return andCondition;
    }

    console.log("targetId", targetId);

    if (currentNode.id == targetId) {
      console.log("e gjeta vangjel");
      const andCondition = new ConditionNodeType(operator);
      andCondition.left = currentNode;
      andCondition.right = newCondition;
      return andCondition;
    }

    let left, right;

    if (!!currentNode.left) {
      console.log("left");
      left = addConditionRecursive(
        operator,
        targetId,
        newCondition,
        currentNode.left
      );
    }

    if (!!currentNode.right) {
      console.log("right");
      right = addConditionRecursive(
        operator,
        targetId,
        newCondition,
        currentNode.right
      );
    }

    return {
      ...currentNode,
      left,
      right,
    };
  }

  /* Remove Condition */
  function removeCondition(targetId) {
    if (!targetId) return;
    const newRoot = removeConditionRecursive(
      targetId,

      totalCondition
    );

    setTotalCondition(newRoot);
  }

  function removeConditionRecursive(
    targetId,
    currentNode // gjithmon lere bosh kete
  ) {
    console.log("removeConditionRecursive currentNode", currentNode);

    console.log("targetId", targetId);

    if (currentNode.left?.id == targetId) {
      currentNode = currentNode.right;
    }

    if (currentNode.right?.id == targetId) {
      currentNode = currentNode.left;
    }

    let left, right;

    if (!!currentNode.left) {
      console.log("left");

      left = removeConditionRecursive(targetId, currentNode.left);
    }

    if (!!currentNode.right) {
      console.log("right");

      right = removeConditionRecursive(targetId, currentNode.right);
    }

    return {
      ...currentNode,
      left,
      right,
    };
  }

  return (
    <>
      <div>
        <Card style={{ marginTop: "15px" }}>
          <CardBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder={!!name ? name : "Name"}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FormControl>
            <div style={{ marginTop: "8px" }}>
              <FormControl isRequired>
                <FormLabel mb="8px">Description:</FormLabel>
                <Textarea
                  value={decriptionValue}
                  onChange={(e) => setDecriptionValue(e.target.value)}
                  placeholder="Description"
                  size="sm"
                />
              </FormControl>
            </div>
            <div style={{ marginTop: "8px" }}>
              <FormLabel mb="8px">Notification Options:</FormLabel>
              <Select
                placeholder="Choose Notification Option"
                size="md"
                value={selectedNotificationOption}
                onChange={(e) => setSelectedNotificationOption(e.target.value)}
              >
                {notificatonOptionsList.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>{" "}
            </div>
            <div style={{ marginTop: "8px" }}>
              <RenderCondition
                condition={totalCondition}
                onChange={(newCondition) => setTotalCondition(newCondition)}
                onDelete={removeCondition}
                addNewCondition={addNewCondition}
                variableList={conditionFromNotification}
              />
              <div style={{ marginTop: "15px" }}>
                {!!selectedNotificationOption ? (
                  <Popover
                    size="xl"
                    onClose={onClose}
                    onOpen={onOpen}
                    isOpen={isOpen}
                  >
                    <PopoverTrigger>
                      <Button>Add conditional group</Button>
                    </PopoverTrigger>
                    <PopoverComponent
                      isOpen={isOpen}
                      showLogicOperator={true}
                      onChange={addNewCondition}
                      onClose={onClose}
                      variableList={conditionFromNotification}
                    />
                  </Popover>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={{ marginTop: "8px" }}>
              <FormControl isRequired>
                <FormLabel mb="8px">Message:</FormLabel>
                <Textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Description"
                  size="sm"
                  isRequired
                />
              </FormControl>
            </div>
            <div style={{ marginTop: "15px" }}>
              <FormLabel mb="8px">Redirect Options</FormLabel>
              <Select
                placeholder="Choose Redirect Options"
                size="md"
                value={selectedRedirectOptions}
                onChange={(e) => setSelectedRedirectOptions(e.target.value)}
              >
                {redirectOptionsList.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
              {selectedRedirectOptions &&
                (selectedRedirectOptions === redirectOptionsList[0] ? (
                  <div style={{ marginTop: "15px" }}>
                    <FormLabel mb="8px">URL</FormLabel>
                    <Input
                      value={redirectUrl}
                      onChange={(e) => setRedirectUrl(e.target.value)}
                      placeholder="Url"
                      size="sm"
                      borderRadius={5}
                      isRequired
                    />
                  </div>
                ) : (
                  <div>
                    <FormLabel mb="8px">Select Entity</FormLabel>
                    <Select
                      placeholder="Select Entity"
                      value={selectedEntity}
                      onChange={(e) => setSelectedEntity(e.target.value)}
                    >
                      {entitiesList.map((entity) => (
                        <option key={entity.id} value={entity.id}>
                          {entity.name}
                        </option>
                      ))}
                    </Select>
                    <FormLabel margin="8px 0px 8px">Select Content</FormLabel>
                    <Select
                      placeholder="Select Content"
                      value={selectedContent}
                      onChange={(e) => setSelectedContent(e.target.value)}
                    >
                      {contentList.map((content) => (
                        <option key={content.id} value={content.id}>
                          {content.title}
                        </option>
                      ))}
                    </Select>
                  </div>
                ))}

              <FormLabel mt="8px">Notify Options</FormLabel>
              <Select
                placeholder="Choose Notify Options"
                size="md"
                value={selectedNotifyOptions}
                onChange={(e) => {
                  setSelectedNotifyOptions(e.target.value);
                  console.log("selectedNotifyOptions", e.target.value);
                }}
              >
                {notifyOptionsList.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            </div>
          </CardBody>
          <div style={{ margin: "10px", padding: "10px" }}>
            <Button
              colorScheme="blue"
              onClick={async () => {
                await notificationRuleAdd({
                  name,
                  description: decriptionValue,
                  notificationOptions: selectedNotificationOption,
                  condition: totalCondition,
                  message,
                  redirectOptions: selectedRedirectOptions,
                  redirectUrl: redirectUrl,
                  content: selectedContent,
                  notifyOptions: selectedNotifyOptions,
                });
                setName("");
                setDecriptionValue("");
                setSelectedNotificationOption("");
                setTotalCondition({});
                setMessage("");
                setRedirectUrl("");
                setSelectedRedirectOptions("");
                setSelectedEntity("");
                setSelectedContent("");
                setSelectedNotifyOptions("");
                getNotificationRuleDetails(selectedAgent.id);
              }}
            >
              Create Rule{" "}
            </Button>
          </div>
        </Card>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Card>
          <CardHeader>
            <Heading size="md"> Notification Rule List</Heading>
          </CardHeader>
          <CardBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Name</Th>
                  <Th>Description</Th>
                  <Th>Notification Options</Th>
                  <Th>Condition</Th>
                  <Th>Message</Th>
                  <Th>Redirect Options</Th>
                  <Th>Notify Options</Th>
                  <Th>Agent Name</Th>
                  <Th>Agent Domain Name</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {notificationRuleDetailsList.map((rule) => (
                  <Tr key={rule.id}>
                    <Td>{rule.id}</Td>
                    <Td>{rule.name}</Td>
                    <Td>{rule.description}</Td>
                    <Td>{rule.notificationOptions}</Td>
                    <Td>{rule.condition}</Td>
                    <Td>{rule.message}</Td>
                    <Td>{rule.redirectOptions}</Td>
                    <Td>{rule.notifyOptions}</Td>
                    <Td>{rule.agent.name}</Td>
                    <Td>{rule.agent.domainName}</Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<MenuDotsIcon />}
                          variant="none"
                          size="sm"
                        />
                        <MenuList>
                          <MenuItem
                            icon={<EditIcon />}
                            onClick={() => handleEditClick(rule)}
                          >
                            Edit
                          </MenuItem>

                          <MenuItem
                            icon={<DeleteIcon color="red" />}
                            onClick={() => deleteNotificationRule(rule.id)}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </CardBody>
        </Card>
        <Modal isOpen={isOpenModal} size="xl" onClose={onCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Notification Rule</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Card style={{ marginTop: "15px" }}>
                <CardBody>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      placeholder={currentRule?.name}
                      onChange={(e) =>
                        setCurrentRule((prev) => {
                          return {
                            ...prev,
                            name: e.target.value,
                          };
                        })
                      }
                      required
                    />
                  </FormControl>
                  <div style={{ marginTop: "8px" }}>
                    <FormLabel mb="8px">Description:</FormLabel>
                    <Textarea
                      value={currentRule?.description}
                      onChange={(e) =>
                        setCurrentRule((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      placeholder="Description"
                      size="sm"
                      isRequired
                    />
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <FormLabel mb="8px">Notification Options:</FormLabel>
                    <Select
                      placeholder="Select Notification Options"
                      value={currentRule?.notificationOptions}
                      size="md"
                      onChange={(e) =>
                        setCurrentRule((prev) => ({
                          ...prev,
                          notificationOptions: e.target.value,
                        }))
                      }
                    >
                      {notificatonOptionsList.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <div style={{ marginTop: "8px" }}>
                      <RenderCondition
                        condition={currentRule?.condition}
                        onChange={(newCondition) =>
                          setCurrentRule((prev) => {
                            return {
                              ...prev,
                              condition: newCondition,
                            };
                          })
                        }
                        onDelete={removeCondition}
                        addNewCondition={addNewCondition}
                        variableList={conditionFromNotification}
                      />
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      {!!selectedNotificationOption ? (
                        <Popover
                          size="xl"
                          onClose={onClose}
                          onOpen={onOpen}
                          isOpen={isOpen}
                        >
                          <PopoverTrigger>
                            <Button>Add conditional group</Button>
                          </PopoverTrigger>
                          <PopoverComponent
                            isOpen={isOpen}
                            showLogicOperator={true}
                            onChange={addNewCondition}
                            onClose={onClose}
                            variableList={conditionFromNotification}
                          />
                        </Popover>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Message */}
                  <div style={{ marginTop: "8px" }}>
                    <FormLabel mb="8px">Message:</FormLabel>
                    <Textarea
                      value={currentRule?.message}
                      onChange={(e) =>
                        setCurrentRule((prev) => {
                          return {
                            ...prev,
                            message: e.target.value,
                          };
                        })
                      }
                      placeholder="Message"
                      size="sm"
                      isRequired
                    />
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <FormLabel mb="8px">Redirect Options</FormLabel>
                    <Select
                      placeholder="Select Redirect Options"
                      size="md"
                      value={currentRule?.redirectOptions}
                      onChange={(e) =>
                        setCurrentRule((prev) => {
                          return {
                            ...prev,
                            redirectOptions: e.target.value,
                          };
                        })
                      }
                    >
                      {redirectOptionsList.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                    {currentRule && currentRule.redirectOptions && (
                      <>
                        {currentRule.redirectOptions ===
                        redirectOptionsList[0] ? (
                          <>
                            <FormLabel mb="8px">URL</FormLabel>
                            <Input
                              value={currentRule.redirectUrl}
                              onChange={(e) =>
                                setCurrentRule((prev) => ({
                                  ...prev,
                                  redirectUrl: e.target.value,
                                }))
                              }
                              placeholder="Url"
                              size="sm"
                              borderRadius={5}
                              isRequired
                            />
                          </>
                        ) : (
                          <>
                            <FormLabel mb="8px">Select Entity</FormLabel>
                            <Select
                              placeholder="Select Entity"
                              value={currentRule.entities?.id}
                              onChange={(e) =>
                                setCurrentRule((prev) => ({
                                  ...prev,
                                  entities: entitiesList.find(
                                    (entity) => e.target.value == entity.id
                                  ),
                                }))
                              }
                            >
                              {entitiesList.map((entity) => (
                                <option key={entity.id} value={entity.id}>
                                  {entity.name}
                                </option>
                              ))}
                            </Select>
                            <FormLabel margin="8px 0px 8px">
                              Select Content
                            </FormLabel>
                            <Select
                              placeholder="Select Content"
                              value={currentRule.content?.id}
                              onChange={(e) =>
                                setCurrentRule((prev) => ({
                                  ...prev,
                                  content: contentList.find(
                                    (content) => e.target.value == content.id
                                  ),
                                }))
                              }
                            >
                              {contentList.map((content) => (
                                <option key={content.id} value={content.id}>
                                  {content.title}
                                </option>
                              ))}
                            </Select>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  {/* Notify Options */}
                  <div style={{ marginTop: "8px" }}>
                    <FormLabel mt="8px">Notify Options</FormLabel>
                    <Select
                      placeholder="Select Notify Options"
                      value={currentRule?.notifyOptions}
                      size="md"
                      onChange={(e) =>
                        setCurrentRule((prev) => {
                          return {
                            ...prev,
                            notifyOptions: e.target.value,
                          };
                        })
                      }
                    >
                      {notifyOptionsList.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ margin: "10px", padding: "10px" }}>
                    <Button
                      colorScheme="blue"
                      onClick={async () => {
                        await notificationRuleUpdate(currentRule);
                        onCloseModal();
                      }}
                    >
                      Update Rule
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

function PopoverComponent({
  isOpen,
  showLogicOperator,
  targetId,
  intialValues,
  onChange,
  onClose,
  variableList,
}) {
  const {
    getBlockFromAgent,
    getIntentFromAgent,
    blockFromAgent,
    intentFromAgent,
  } = useNetworkingHook();
  const [activeCondition, setActiveCondition] = useState(
    intialValues?.operator ?? ""
  );
  const [selectedVariable, setSelectedVariable] = useState(
    intialValues?.arguments?.variable ?? ""
  );
  const [activeVar2, setActiveVar2] = useState(
    intialValues?.arguments?.value ?? ""
  );
  const [operator, setOperator] = useState("AND");

  useEffect(() => {
    if (!selectedVariable) return;
    if (selectedVariable == "symptomName") getBlockFromAgent();
    if (selectedVariable == "symptomValue") getIntentFromAgent();
  }, [isOpen, selectedVariable]);

  return (
    <PopoverContent>
      <PopoverArrow />
      <PopoverHeader />
      <div style={{ maxHeight: "50%" }}>
        {" "}
        {showLogicOperator && (
          <OperatorSelector active={operator} setActive={setOperator} />
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Select
          placeholder="Select Variable"
          value={selectedVariable}
          onChange={(e) => {
            setSelectedVariable(e.target.value);
          }}
        >
          {variableList?.map((key, index) => (
            <option key={index} value={key}>
              {key}
            </option>
          ))}
        </Select>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Select
          placeholder="Condition"
          value={activeCondition}
          onChange={(e) => setActiveCondition(e.target.value)}
        >
          <option>Is</option>
          <option>Is not</option>
          <option>Is greater than</option>
          <option>Is greater than or equal</option>
          <option>Is less than</option>
          <option>Is less than or equal</option>
          <option>Contains</option>
          <option>Does not contain</option>
          <option>Starts with</option>
          <option>Ends with</option>
          <option>Has any value</option>
          <option>Is empty</option>
        </Select>
      </div>
      <div style={{ marginTop: "10px" }}>
        <PopoverBody>
          {!!selectedVariable && selectedVariable === "symptomName" ? (
            <Select
              placeholder="Select Variable"
              value={activeVar2}
              onChange={(e) => setActiveVar2(e.target.value)}
            >
              {blockFromAgent?.map((context, index) => (
                <option key={index} value={context.context}>
                  {context.context}
                </option>
              ))}
            </Select>
          ) : !!selectedVariable && selectedVariable === "symptomValue" ? (
            <Select
              placeholder="Select Variable"
              value={activeVar2}
              onChange={(e) => setActiveVar2(e.target.value)}
            >
              {intentFromAgent?.map((name, index) => (
                <option key={index} value={name.name}>
                  {name.name}
                </option>
              ))}
            </Select>
          ) : (
            <Input
              placeholder="Second Variable"
              value={activeVar2}
              onChange={(e) => setActiveVar2(e.target.value)}
            />
          )}
        </PopoverBody>
      </div>
      <PopoverFooter display="flex" justifyContent="flex-end">
        <ButtonGroup display="flex" justifyContent="flex-end">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>

          <Button
            colorScheme="blue"
            onClick={() => {
              onChange(
                operator,
                selectedVariable,
                activeCondition,
                activeVar2,
                targetId
              );
              onClose();
              setSelectedVariable("");
              setActiveCondition("");
              setActiveVar2("");
            }}
          >
            Apply
          </Button>
        </ButtonGroup>
      </PopoverFooter>
    </PopoverContent>
  );
}

function RenderCondition({
  parent,
  condition,
  onChange,
  onDelete,
  addNewCondition,
  variableList,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHovering, setIsHovering] = useState(false);
  const {
    isOpen: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose,
  } = useDisclosure();
  if (Object.keys(condition ?? {}).length == 0) return <div />;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <div
      key={condition.id}
      style={{
        border:
          !!condition.arguments || parent?.operator == condition.operator
            ? null
            : "1px solid gray",
        borderRadius: 10,
        padding:
          // !!condition.arguments ||
          parent?.operator == condition.operator ? null : "10px",
        margin: !condition.arguments ? "10px 0px" : "5px 0px",
        backgroundColor: !!condition.arguments ? "#e2f0fd" : null,
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {!!condition.left && (
        <RenderCondition
          condition={condition.left}
          parent={condition}
          onChange={(newCondition) => {
            onChange({
              ...condition,
              left: newCondition,
            });
          }}
          onDelete={onDelete}
          addNewCondition={addNewCondition}
          variableList={variableList}
        />
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          wordBreak: "break-all",
        }}
      >
        <Popover size="xl" onClose={onClose} onOpen={onOpen} isOpen={isOpen}>
          <PopoverTrigger>
            <div>
              <p>
                {(condition.arguments?.variable ?? "") +
                  " " +
                  condition.operator +
                  " " +
                  (condition.arguments?.value ?? "")}
              </p>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            {!condition.arguments ? (
              <OperatorSelector
                active={condition.operator}
                setActive={(newOperator) => {
                  onChange({
                    ...condition,
                    operator: newOperator,
                  });
                }}
              />
            ) : (
              <PopoverComponent
                isOpen={isOpen}
                onClose={onClose}
                intialValues={condition}
                onChange={(_, var1, newOperator, var2) =>
                  onChange({
                    ...condition,
                    operator: newOperator,
                    arguments: {
                      ...condition.arguments,
                      variable: var1,
                      value: var2,
                    },
                  })
                }
                variableList={variableList}
              />
            )}
          </PopoverContent>
        </Popover>

        {/* Add Condition Button */}
        {!!condition.arguments && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Popover
                size="xl"
                onClose={onNewClose}
                onOpen={onNewOpen}
                isOpen={isNewOpen}
              >
                <PopoverTrigger>
                  <SmallAddIcon
                    boxSize={5}
                    mr="5px"
                    cursor={"pointer"}
                    opacity={isHovering ? 1 : 0} // Show icon when hovering
                  />
                </PopoverTrigger>
                <PopoverComponent
                  isOpen={isNewOpen}
                  showLogicOperator={true}
                  targetId={condition.id}
                  onChange={addNewCondition}
                  onClose={onClose}
                  variableList={variableList}
                />
              </Popover>
            </div>
            <div
              style={{
                opacity: isHovering ? 1 : 0,
                transition: "opacity 0.3s ease-in-out",
              }}
            >
              {" "}
              <DeleteIcon
                size="sm"
                cursor={"pointer"}
                color="red"
                opacity={isHovering ? 1 : 0} // Show icon when hovering
                onClick={() => onDelete(condition.id)}
              >
                Remove Condition
              </DeleteIcon>
            </div>
          </div>
        )}
      </div>

      {!!condition.right && (
        <RenderCondition
          condition={condition.right}
          parent={condition}
          onChange={(newCondition) => {
            onChange({
              ...condition,
              right: newCondition,
            });
          }}
          onDelete={onDelete}
          addNewCondition={addNewCondition}
          variableList={variableList}
        />
      )}
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Button,
  InputGroup,
  InputRightElement,
  Input,
  Box,
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  IconButton,
  FormLabel,
  Heading,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from "@chakra-ui/icons";

import CreateIntentUtterances from "./SidebarComponents/CreateIntentUtterances";
import {
  getStorageAccessToken,
  setDbEditIntentParams,
} from "./LocalStorage/mainDb";
import axios from "axios";
import { useSelector } from "react-redux";
import useNetworkingHook from "./hooks/useNetworkingHook";
export const IntentTable = () => {
  const { selectedAgent } = useSelector((state) => state.slice);
  const [intents, setIntents] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [valueToPredict, setValueToPredict] = useState("");
  const { intentPredict, intentImport, exportIntentList } = useNetworkingHook();
  const [intentPredictName, setIntentPredictName] = useState("");
  const [importFile, setImportFile] = useState(null); // State to store uploaded file
  const [importError, setImportError] = useState(""); // State to store import error message

  useEffect(() => {
    fetchIntents();
  }, []);

  function fetchIntents() {
    axios
      .get("https://console.zana.ai/intent", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (resp.status != 200) {
          console.log("error");
          return;
        }
        console.log("intent list", resp.data);
        // setIntents(resp.data);
        fetchUtterances(resp.data);
      });
  }

  function fetchUtterances(intents) {
    axios
      .get("https://console.zana.ai/intent/utterances", {
        headers: {
          Authorization: "Bearer " + getStorageAccessToken(),
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        if (resp.status != 200) {
          console.log("error");
          return;
        }
        console.log("utterances ", resp.data);
        const updatedIntents = intents.map((intent) => {
          const utterancesForIntent = resp.data.filter(
            (utterance) => utterance.intent.id === intent.id
          );
          return {
            ...intent,
            utterances: utterancesForIntent.map(
              (utterance) => utterance.utterances
            ),
          };
        });
        console.log("updated intents", updatedIntents);
        setIntents(updatedIntents);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [page, setPage] = useState(1);
  const perPage = 8; // Number of items to display per page
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const filteredIntents = useMemo(() => {
    if (intents && intents.length > 0) {
      if (searchTerm) {
        return intents.filter(
          (intent) => intent.name && intent.name.includes(searchTerm)
        );
      } else {
        return intents;
      }
    } else {
      return [];
    }
  }, [intents, searchTerm]);

  const totalPages = Math.max(
    Math.ceil(
      filteredIntents.filter(
        (intent) => !selectedAgent || intent.agent.id === selectedAgent.id
      ).length / perPage
    ),
    1
  );

  const handleNextPage = () => {
    if (page === totalPages) return;
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page === 1) return;
    setPage((prevPage) => prevPage - 1);
  };

  const paginatedIntents = filteredIntents
    .filter((intent) => !selectedAgent || intent.agent.id === selectedAgent.id)
    .slice((page - 1) * perPage, page * perPage);

  const handleFileChange = (e) => {
    setImportFile(e.target.files[0]);
  };

  async function exportIntents() {
    try {
      const exportData = await exportIntentList();

      const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify({
          data: exportData,
        })
      )}`;

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = "intents" + selectedAgent.id + ".json";
      link.click();
    } catch (error) {
      console.error("Error exporting intents:", error);
    }
  }

  const handleImport = async () => {
    try {
      if (!importFile) {
        setImportError("Please select a file.");
        return;
      }

      await intentImport(importFile, selectedAgent.id);
      console.log("Imported successfully.");
      fetchIntents();
      setImportFile(null);
      setImportError("");
    } catch (error) {
      console.error("Error during import:", error.message);
      setImportError("Error during import. Please try again.");
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  return (
    <Box maxW="7xl" mx={"auto"} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <div
        style={{
          boxShadow: "0 1px 7px 1px rgba(0, 0, 0, 0.20)",
          borderRadius: 10,
          padding: "25px",
          marginTop: "30px",
        }}
      >
        {selectedAgent ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CreateIntentUtterances
                buttonName="Add New Intent"
                updateIntents={fetchIntents}
              />
              <Button onClick={onOpen} colorScheme="gray">
                Predict Intent
              </Button>
              <Button onClick={() => exportIntents()} colorScheme="gray">
                Export Intent
              </Button>
            </div>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Intent Predict</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormLabel>User Says</FormLabel>
                  <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type="text"
                      placeholder="Enter Utterance"
                      onChange={(e) => setValueToPredict(e.target.value)}
                    />
                    <InputRightElement width="4.5rem">
                      <IconButton
                        h="1.75rem"
                        size="sm"
                        aria-label="Search Intent Prediction"
                        icon={<SearchIcon />}
                        onClick={async () => {
                          const name = await intentPredict(valueToPredict);

                          setIntentPredictName(name ?? "");
                        }}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <FormLabel mt={4}>Intent Predicted</FormLabel>
                  <Box>
                    <Heading size="xs" textTransform="uppercase">
                      {intentPredictName}
                    </Heading>
                  </Box>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      onClose();
                      setIntentPredictName("");
                    }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <InputGroup mt={4} mb={2}>
              <Input
                type="text"
                placeholder="Search Intents"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setSearchTerm("")}>
                  Clear
                </Button>
              </InputRightElement>
            </InputGroup>
            <InputGroup mt={4}>
              <input
                type="file"
                accept=".json"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="fileInput"
              />
              <Input
                type="text"
                placeholder="Select a JSON file for import"
                value={importFile ? importFile.name : ""}
                cursor="pointer"
                isReadOnly
                onClick={() => document.getElementById("fileInput").click()}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleImport}>
                  Import
                </Button>
              </InputRightElement>
            </InputGroup>
            {importError && (
              <Box color="red" mt={2}>
                {importError}
              </Box>
            )}
            <TableContainer
              style={{
                marginTop: "20px",
                border: "1px solid #d8d8d8",
                borderRadius: "10px",
              }}
            >
              <Table variant="striped" colorScheme="blue">
                <TableCaption>
                  Listing All Intents (Page {page} of {totalPages})
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Utterances</Th>
                    <Th>Label</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedIntents &&
                    paginatedIntents
                      .filter((intent) =>
                        !selectedAgent
                          ? true
                          : selectedAgent.id === intent.agent.id
                      )
                      .map((intentObj, index) => (
                        <Tr key={index}>
                          <Td>{intentObj.name}</Td>
                          <Td>
                            {intentObj.utterances &&
                              intentObj.utterances
                                .filter((_, index) => index < 3)
                                .map((utterance, index) => {
                                  const listLength =
                                    intentObj.utterances.length;
                                  return (
                                    utterance +
                                    (listLength - 1 === index ? "" : ", ")
                                  );
                                })}
                          </Td>
                          <Td>{intentObj.label}</Td>
                          <Td>
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setDbEditIntentParams(intentObj, "Edit");
                                navigate("/intents/edit");
                              }}
                            >
                              Edit
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
              <Box mb={2} textAlign="center">
                <ChevronLeftIcon
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                  mr={2}
                  boxSize={5}
                />
                <ChevronRightIcon
                  onClick={handleNextPage}
                  disabled={page === totalPages}
                  ml={2}
                  boxSize={5}
                />
              </Box>
            </TableContainer>
          </div>
        ) : (
          <div>No agent selected.</div>
        )}
      </div>
    </Box>
  );
};

import { Handle, Position } from "reactflow";
import "../Css/StartNode.css";

function StartNode({ data, isConnectable }) {
  return (
    <div className="button-39">
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
      <div>{!!data.intent?.name ? data.intent.name + " Start" : "Start"}</div>
    </div>
  );
}

export default StartNode;

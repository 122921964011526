import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import axios from "axios";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import {
  getStorageAccessToken,
  getStorageUsername,
  setNewAccessToken,
  setNewUsername,
} from "./LocalStorage/mainDb";

const Register = (props) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [status, setStatus] = useState(true);
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    if (!accessToken) {
      setAccessToken(getStorageAccessToken());
      return;
    }
  }, [accessToken, username]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username,
      email,
      password,
      firstName,
      lastName,
      status,
    };

    try {
      const response = await axios.post(
        "https://console.zana.ai/keycloak/create/user",
        payload,
        {
          headers: {
            Authorization: "Bearer" + accessToken,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      // Handle successful registration
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    navigate("/login");
  };
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"} textAlign={"center"}>
            Sign up
          </Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <HStack>
                <Box>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      value={firstName}
                      name="name"
                      onChange={(e) => setFirstName(e.target.value)}
                      id="name"
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      value={lastName}
                      name="name"
                      onChange={(e) => setLastName(e.target.value)}
                      id="name"
                    />
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="userName" isRequired>
                <FormLabel>User Name</FormLabel>
                <Input
                  type="text"
                  value={username}
                  name="name"
                  onChange={(e) => setUsername(e.target.value)}
                  id="name"
                  placeholder=" username"
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="youremail@gmail.com"
                  id="email"
                  name="email"
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input type={password ? "text" : "password"} />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() => setPassword((password) => !password)}
                    >
                      {password ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  onClick={handleSubmit}
                >
                  Sign up
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"}>
                  Already a user?{" "}
                  <Link color={"blue.400"} onClick={loginSubmit}>
                    Login
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

//       <form className="register-form" onSubmit={handleSubmit}>
//         <label htmlFor="name">Full name</label>
//         <input
//           value={username}
//           name="name"
//           onChange={(e) => setUsername(e.target.value)}
//           id="name"
//           placeholder="full Name"
//         />
//         <label htmlFor="email">email</label>
//         <input
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           type="email"
//           placeholder="youremail@gmail.com"
//           id="email"
//           name="email"
//         />
//         <label htmlFor="password">password</label>
//         <input
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           type="password"
//           placeholder="********"
//           id="password"
//           name="password"
//         />
//         <input
//           value={firstName}
//           name="name"
//           onChange={(e) => setFirstName(e.target.value)}
//           id="name"
//           placeholder="full Name"
//         />
//         <input
//           value={lastName}
//           name="name"
//           onChange={(e) => setLastName(e.target.value)}
//           id="name"
//           placeholder="full Name"
//         />
//         <button type="submit">Log In</button>
//       </form>
//       <button className="link-btn" onClick={() => props.onFormSwitch("login")}>
//         Already have an account? Login here.
//       </button>
//     </div>
//   );
// };

export default Register;

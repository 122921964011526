import React, { useState } from "react";
import {
  Box,
  Flex,
  useDisclosure,
  Icon,
  Text,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { FiMenu, FiStar } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import AgentCard from "./AgentCard";

const LinkItems = [{ name: "Agents Info", icon: FiStar }];

export default function AgentView({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedNavItem, setSelectedNavItem] = useState(LinkItems[0].name);
  const handleNavItemClick = (name) => {
    setSelectedNavItem(name);
  };

  return (
    <Flex minH="100vh" bg="gray.50">
      <Box
        w="250px"
        bg="white"
        boxShadow="lg"
        p={4}
        display={{ base: "none", md: "block" }}
      >
        <VStack spacing={4} align="stretch">
          <Box>
            <Text
              color="blue.500"
              fontWeight="bold"
              fontSize="2xl"
              align="center"
            >
              All Agents
            </Text>
          </Box>
        </VStack>
        <VStack spacing={4} mt={8} align="stretch">
          {LinkItems.map((item) => (
            <Box
              key={item.name}
              cursor="pointer"
              onClick={() => handleNavItemClick(item.name)}
              borderRadius="md"
              p={4}
              color={selectedNavItem === item.name ? "blue.500" : "gray.500"}
              bg={selectedNavItem === item.name ? "blue.50" : "inherit"}
            >
              <HStack align="center">
                <Icon as={item.icon} fontSize="lg" mr={4} color="blue.500" />
                <Text fontSize="0.9rem">{item.name}</Text>
              </HStack>
            </Box>
          ))}
        </VStack>
      </Box>
      {/* Content Area */}
      <Box flex="1" p={8} bg="gray.50" boxShadow="md" borderRadius="md">
        {/* Add a button for toggling the sidebar on smaller screens */}
        <Box display={{ base: "block", md: "none" }} onClick={onOpen}>
          <FiMenu size={24} color="gray.500" />
        </Box>

        {selectedNavItem === "Agents Info" && <AgentCard />}
      </Box>
      {/* Sidebar Overlay for smaller screens */}
      <Box
        pos="fixed"
        top="0"
        left="0"
        w="100%"
        h="100%"
        bg="rgba(0, 0, 0, 0.3)"
        display={{ base: isOpen ? "block" : "none", md: "none" }}
        onClick={onClose}
      />
    </Flex>
  );
}

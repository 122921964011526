import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import useNetworkingHook from "./hooks/useNetworkingHook";
import { DeleteIcon, SmallAddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FiMenu } from "react-icons/fi";
import { useToast } from "@chakra-ui/react";

const LinkItems = [{ name: "Back", icon: ArrowBackIcon }];

export default function GoBackSidebar({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedNavItem, setSelectedNavItem] = useState(LinkItems[0].name);
  const handleNavItemClick = (name) => {
    setSelectedNavItem(name);
  };
  return (
    <Box minH="100vh" bg={useColorModeValue("white")}>
      <div className="edit-intent-wrap">
        <SidebarContent
          onClose={() => onClose}
          display={{ base: "none", md: "block" }}
          bg={useColorModeValue("gray.100")}
          selectedNavItem={selectedNavItem}
          handleNavItemClick={handleNavItemClick}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />

        <ExternalFacts />
      </div>
    </Box>
  );
}

const SidebarContent = ({
  onClose,
  selectedNavItem,
  handleNavItemClick,
  ...rest
}) => {
  return (
    <Box
      transition="3s ease"
      bg="white"
      borderRight="1px"
      borderRightColor="gray.200"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Navigate
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          isSelected={selectedNavItem === link.name}
          onClick={() => handleNavItemClick(link.name)}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, isSelected, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onClick={() => {
        navigate("/flow");
      }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      height="20"
      alignItems="center"
      bg={useColorModeValue("gray.100", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu color="#191f2b" />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Settings
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        {/* gjerat ne fund te cepit djathtas */}
      </HStack>
    </Flex>
  );
};

export function ExternalFacts() {
  const {
    addFacts,
    getBrainFactsExternal,
    deleteFacts,
    deleteFactsList,
    factList,
  } = useNetworkingHook();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [variableName, setVariableName] = useState("");
  const [variableType, setVariableType] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const toast = useToast();

  useEffect(() => {
    getBrainFactsExternal();
  }, []);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // If "Select All" is checked, add all items to the selectedItems array
      setSelectedItems(factList.map((fact) => fact.originalId));
    } else {
      // If "Select All" is unchecked, clear the selectedItems array
      setSelectedItems([]);
    }
  };

  const toggleItem = (factId) => {
    if (selectedItems.includes(factId)) {
      // If the item is in the selectedItems array, remove it
      setSelectedItems(selectedItems.filter((id) => id !== factId));
    } else {
      // If the item is not in the selectedItems array, add it
      setSelectedItems([...selectedItems, factId]);
    }
  };

  const deleteSelected = async () => {
    await deleteFactsList({ ids: selectedItems });
    setSelectedItems([]);
  };

  return (
    <>
      <div
        style={{
          //   maxWidth: "900px",
          //   display: "block",
          margin: "50px auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Button
            onClick={onOpen}
            leftIcon={<SmallAddIcon />}
            colorScheme="gray"
            variant="outline"
          >
            Create new variable
          </Button>

          <Modal
            initialFocusRef={initialRef}
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create new variable</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Variable Name</FormLabel>
                  <Input
                    ref={initialRef}
                    placeholder="Variable Name"
                    value={variableName}
                    onChange={(e) => setVariableName(e.target.value)}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Variable Type</FormLabel>
                  <Select
                    placeholder="Select option"
                    onChange={(e) => setVariableType(e.target.value)}
                    value={variableType} // Make sure to set the value of the Select component
                  >
                    <option value="String">String</option>
                    <option value="Boolean">Boolean</option>
                    <option value="Long">Long</option>
                  </Select>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={async () => {
                    if (!variableType) {
                      toast({
                        position: "top-right",
                        title:
                          "Please select a variable type to save the new variable.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                      return; // Stop execution if variable type is not selected
                    }
                    const existingFact = factList.find(
                      (facts) => facts.variableName === variableName
                    );

                    if (existingFact) {
                      toast({
                        position: "top-right",
                        title:
                          "An external fact with this name already exists. Please choose a different name.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    } else {
                      await addFacts(variableName, variableType);
                    }
                    setVariableName("");
                    setVariableType("");
                    onClose();
                  }}
                >
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Button
            leftIcon={<DeleteIcon />}
            colorScheme="gray"
            variant="outline"
            onClick={deleteSelected}
          >
            Delete selected
          </Button>
        </div>

        <TableContainer
          style={{
            marginTop: "20px",
            border: "1px solid #d8d8d8",
            borderRadius: "10px",
          }}
        >
          <Table variant="simple" colorScheme="gray">
            <TableCaption>Listing External Facts</TableCaption>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    colorScheme="green"
                    size="md"
                    isChecked={selectAll}
                    onChange={toggleSelectAll}
                  />
                </Th>
                <Th>Variable Name</Th>
                <Th>Variable Type</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {factList.map((facts, index) => (
                <Tr key={index}>
                  <Td>
                    <Checkbox
                      colorScheme="green"
                      size="md"
                      isChecked={selectedItems.includes(facts.originalId)}
                      onChange={() => toggleItem(facts.originalId)}
                    />
                  </Td>
                  <Td>{facts.variableName}</Td>
                  <Td>{facts.variableType}</Td>
                  <Td>
                    <Button
                      colorScheme="red"
                      onClick={async () => {
                        await deleteFacts(facts.originalId);
                      }}
                    >
                      Delete
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot></Tfoot>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

import { createIcon } from "@chakra-ui/icons";

const MachineLearningIcon = createIcon({
  displayName: "MachineLearningIcon",
  viewBox: "0 0 32 32",
  path: (
    <g id="SVGRepo_iconCarrier">
      <path
        id="machine--learning--04_1_"
        d="M23,30.36H9c-2.404,0-4.36-1.956-4.36-4.36V15c0-2.404,1.956-4.36,4.36-4.36h3.659 c0.167-1.566,1.415-2.813,2.981-2.981V5.333c-1.131-0.174-2-1.154-2-2.333c0-1.301,1.059-2.36,2.36-2.36 c1.302,0,2.36,1.059,2.36,2.36c0,1.179-0.869,2.159-2,2.333V7.66c1.566,0.167,2.814,1.415,2.981,2.981H23 c2.404,0,4.36,1.956,4.36,4.36v11C27.36,28.404,25.404,30.36,23,30.36z M9,11.36c-2.007,0-3.64,1.633-3.64,3.64v11 c0,2.007,1.633,3.64,3.64,3.64h14c2.007,0,3.64-1.633,3.64-3.64V15c0-2.007-1.633-3.64-3.64-3.64H9z M13.384,10.64h5.231 C18.439,9.354,17.334,8.36,16,8.36C14.667,8.36,13.561,9.354,13.384,10.64z M16,1.36c-0.904,0-1.64,0.736-1.64,1.64 S15.096,4.64,16,4.64c0.904,0,1.64-0.736,1.64-1.64S16.904,1.36,16,1.36z M20,27.36h-8c-1.301,0-2.36-1.059-2.36-2.36 s1.059-2.36,2.36-2.36h8c1.302,0,2.36,1.059,2.36,2.36S21.302,27.36,20,27.36z M12,23.36c-0.904,0-1.64,0.735-1.64,1.64 s0.736,1.64,1.64,1.64h8c0.904,0,1.64-0.735,1.64-1.64s-0.735-1.64-1.64-1.64H12z M31,23.86h-2c-0.199,0-0.36-0.161-0.36-0.36V15 c0-0.199,0.161-0.36,0.36-0.36h2c0.199,0,0.36,0.161,0.36,0.36v8.5C31.36,23.699,31.199,23.86,31,23.86z M29.36,23.14h1.279v-7.78 H29.36V23.14z M3,23.86H1c-0.199,0-0.36-0.161-0.36-0.36V15c0-0.199,0.161-0.36,0.36-0.36h2c0.199,0,0.36,0.161,0.36,0.36v8.5 C3.36,23.699,3.199,23.86,3,23.86z M1.36,23.14h1.28v-7.78H1.36V23.14z M20,20.36c-1.302,0-2.36-1.059-2.36-2.36 s1.059-2.36,2.36-2.36s2.36,1.059,2.36,2.36C22.36,19.302,21.302,20.36,20,20.36z M20,16.36c-0.904,0-1.64,0.736-1.64,1.64 s0.735,1.64,1.64,1.64s1.64-0.735,1.64-1.64S20.904,16.36,20,16.36z M12,20.36c-1.301,0-2.36-1.059-2.36-2.36s1.059-2.36,2.36-2.36 s2.36,1.059,2.36,2.36C14.36,19.302,13.301,20.36,12,20.36z M12,16.36c-0.904,0-1.64,0.736-1.64,1.64s0.736,1.64,1.64,1.64 s1.64-0.735,1.64-1.64S12.904,16.36,12,16.36z"
      />
      <rect
        id="_Transparent_Rectangle"
        style={{ fill: "none" }}
        width="32"
        height="32"
      />
    </g>
  ),
});

export default MachineLearningIcon;

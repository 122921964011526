class ConditionNodeType {
  constructor(operator, args = null, left = null, right = null) {
    this.id = Math.random();
    this.operator = operator;
    this.arguments = args;
    this.left = left;
    this.right = right;
  }
}

export default ConditionNodeType;

// EntityContent.js
import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Card,
  CardHeader,
  Heading,
  CardBody,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import MenuDotsIcon from "./images/CustomIcons/MenuDotsIcon";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import useNetworkingHook from "./hooks/useNetworkingHook";
import ContentForm from "./ContentForm";

export const EntityContent = () => {
  const {
    getContentList,
    contentList,
    entityContentAdd,
    entityContentUpdate,
    deleteEntityContent,
  } = useNetworkingHook();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedContent, setSelectedContent] = useState(null);

  useEffect(() => {
    getContentList();
  }, []);

  const handleEditContent = (content) => {
    setSelectedContent(content);
    onOpen();
  };

  const handleAddContent = (formData) => {
    if (selectedContent) {
      entityContentUpdate(formData);
    } else {
      entityContentAdd(formData);
    }
    onClose();
  };
  const handleClose = () => {
    setSelectedContent(null);
    onClose();
  };
  return (
    <div>
      <Button colorScheme="blue" onClick={onOpen} mb={4}>
        Add Content
      </Button>
      <Card>
        <CardHeader>
          <Heading size="md"> Content Entity List</Heading>
        </CardHeader>
        <CardBody>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Summary</Th>
                <Th>Content</Th>
                <Th>Intent</Th>
                <Th>Source</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contentList.map((content, index) => (
                <Tr key={index}>
                  <Td>{content.title}</Td>
                  <Td>{content.summary}</Td>
                  <Td>{content.content}</Td>
                  <Td>{content.intent}</Td>
                  <Td>{content.source}</Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MenuDotsIcon />}
                        variant="none"
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem
                          icon={<EditIcon />}
                          onClick={() => handleEditContent(content)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          icon={<DeleteIcon color="red" />}
                          onClick={() => deleteEntityContent(content.id)}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <ContentForm
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleAddContent}
        initialData={selectedContent}
      />
    </div>
  );
};

export default EntityContent;
